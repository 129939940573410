import { prop, replace, startsWith } from 'ramda';
import { useState } from 'react';

const usePhoneLoginForm = (
  firebaseClient,
  onPhoneSignup,
  onExistingUserLogin
) => {
  const [stepIndex, setStepIndex] = useState(0);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [phoneSent, setPhoneSent] = useState('');
  const [user, setUser] = useState(null);
  const [recaptchaVerifier, setRecapchaVerifier] = useState(null);
  const [resendLoading, setResendLoading] = useState(false);
  const [resendError, setResendError] = useState(false);
  const [isRegisteredUser, setIsRegisteredUser] = useState(false);

  const normalizePhone = (phone) =>
    startsWith('05', phone) ? replace('05', '+9725', phone) : phone;

  const sendPhoneVerificationCode = (phone) =>
    firebaseClient
      .auth()
      .signInWithPhoneNumber(normalizePhone(phone), recaptchaVerifier);

  const resetPhone = () => {
    setStepIndex(0);
    setPhoneSent('');
    setConfirmationResult(null);
  };

  const submitPhone = ({ firebaseConfirmationResult, phone }) => {
    setConfirmationResult(firebaseConfirmationResult);
    setPhoneSent(phone);
    setStepIndex(stepIndex + 1);
  };

  const submitVerificationCode = ({ user: resultUser }) => {
    setUser(resultUser);
    if (prop('email', resultUser)) {
      setStepIndex(stepIndex + 3);

      if (onExistingUserLogin) {
        onExistingUserLogin(resultUser);
      }
    } else {
      setStepIndex(stepIndex + 1);
    }
  };

  const resendCode = async () => {
    try {
      setResendLoading(true);
      const result = await sendPhoneVerificationCode(phoneSent);
      setConfirmationResult(result);
      setResendLoading(false);
    } catch (e) {
      setResendLoading(false);
      setResendError(true);
    }
  };

  const submitUserDetails = async ({ name, email }, setLoading, setError) => {
    try {
      if (setLoading) {
        setLoading(true);
      }
      await user.updateEmail(email);
      await user.updateProfile({ displayName: name });
      if (setLoading) {
        setLoading(false);
      }
      setStepIndex(stepIndex + 1);
    } catch (e) {
      if (setLoading) {
        setLoading(false);
      }
      if (setError) {
        setError(e);
      }
    }
  };

  const submitUserAvatar = async (selectedAvatarUrl, setLoading) => {
    if (setLoading) {
      setLoading(true);
    }
    await user.updateProfile({
      photoURL: selectedAvatarUrl
    });
    onPhoneSignup(user);
    if (setLoading) {
      setLoading(false);
    }
    setStepIndex(stepIndex + 1);
  };

  const toggleIsRegisteredUser = () => {
    setIsRegisteredUser(!isRegisteredUser);
  };

  return {
    submitUserDetails,
    submitUserAvatar,
    resendCode,
    submitVerificationCode,
    submitPhone,
    resendLoading,
    resendError,
    resetPhone,
    confirmationResult,
    setStepIndex,
    sendPhoneVerificationCode,
    phoneSent,
    user,
    stepIndex,
    setRecapchaVerifier,
    isRegisteredUser,
    toggleIsRegisteredUser
  };
};

export default usePhoneLoginForm;
