import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { useField } from 'react-final-form';
import './types';
import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import HoursTable from './HoursTable';
import HoursEditDialog from './HoursEditDialog';
import { hoursInputsAreEqual, normalizeHoursInput } from './helpers';

const HoursFormInput = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const {
    input: { onChange, value }
  } = useField('hours');

  const closeAndSaveHours = (allDays) => {
    const newHours = normalizeHoursInput(allDays);
    const hasChanges = !hoursInputsAreEqual(newHours, value);
    if (hasChanges) {
      onChange(newHours);
    }
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Box justifyContent="end" display="flex" marginBottom="20px">
        <IconButton onClick={() => setIsModalOpen(true)}>
          <EditIcon />
        </IconButton>
      </Box>
      <HoursTable value={value} />

      <HoursEditDialog
        value={value}
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onSubmit={closeAndSaveHours}
      />
    </Box>
  );
};

export default HoursFormInput;
