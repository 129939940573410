import React from 'react';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Error from '@material-ui/icons/Error';
import { prop, propOr } from 'ramda';
import messages from './messages';

const styles = () => ({
  errorIcon: {
    color: 'red',
    verticalAlign: 'middle',
    margin: 4,
  },
  firebaseErrorMessage: {
    fontWeight: 'bold',
  },
});

const SubmitErrorMessage = ({ errorMessage, classes }) => (
  <React.Fragment>
    {propOr(false, 'code', errorMessage) ? (
      <Typography className={classes.firebaseErrorMessage}>
        <Error className={classes.errorIcon} />
        {propOr(
          prop('message', errorMessage),
          prop('code', errorMessage),
          messages.firebaseErrors,
        )}
      </Typography>
    ) : null}
  </React.Fragment>
);

export default withStyles(styles)(SubmitErrorMessage);
