import messages from './messages';

export const PHONE_REGEX = /^(05[0-9]{8})$/i;

export default {
  phone: (value) =>
    value && !PHONE_REGEX.test(value)
      ? messages.errorMessages.phoneInvalid
      : undefined
};
