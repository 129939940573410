import React from 'react';
import { AutocompleteInput, Filter } from 'react-admin';
import useAgenciesForAutoComplete from '../../utils/hooks/useAgenciesForAutoComplete';

const AgencyFilter = (props) => {
  const { agencies } = useAgenciesForAutoComplete();

  return (
    <Filter {...props}>
      <AutocompleteInput choices={agencies} source="id" label="name" />
      {/* <TextInput source="name" /> */}
      {/* <TextInput source="displayName" /> */}
      {/* <TextInput source="website" /> */}
    </Filter>
  );
};

export default AgencyFilter;
