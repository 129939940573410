import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useUserCitiesForAutoComplete = (localVersion) => {
  const [userCities, setUserCities] = useState([]);
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchCities = async () => {
    const { data } = await dataProvider(GET_LIST, 'City', {
      filter: {
        specificFields: [
          'nodeId',
          'id',
          'cityCode',
          'city',
          'district',
          'districtCode',
          'coordinateX',
          'coordinateY'
        ]
      },
      sort: { field: 'city', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedCities = pipe(
      map((city) => ({
        id: prop('cityCode', city),
        name: prop('city', city)
      })),
      filter(prop('id'))
    )(data);

    setUserCities(mappedCities);
  };

  useEffect(() => {
    fetchCities();
  }, [version, localVersion]);

  return {
    userCities
  };
};

export default useUserCitiesForAutoComplete;
