import React, { useCallback, useEffect, useRef, useState } from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import TextField from '@material-ui/core/TextField';
import FormHelperText from '@material-ui/core/FormHelperText';
import Button from '@material-ui/core/Button';

function isValid(value) {
  if (typeof value === 'undefined' || value === null || value === '')
    return 'שדה חובה';
  const v = +value;
  if (Number.isNaN(v) || v < 0 || v > 100) {
    return 'ערך לא חוקי';
  }
  return null;
}

/**
 * @typedef {object} Props
 * @property {boolean} loading
 * @property {number} value
 * @property {Promise<void>} onUpdate
 */

/** @type {React.FunctionComponent<Props>} */
const FundingPercentageLimitForm = ({ loading, value, onUpdate }) => {
  const inputRef = useRef();
  const [error, setError] = useState('');

  const validate = useCallback(() => {
    const err = isValid(inputRef.current.value);
    setError(err);
    return !err;
  }, []);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.addEventListener('change', validate);
    }

    return () => {
      inputRef.current.removeEventListener('change', validate);
    };
  }, [validate]);

  useEffect(() => {
    inputRef.current.value = value;
  }, [value]);

  const handleUpdate = useCallback(() => {
    if (validate()) {
      onUpdate(inputRef.current.value);
    }
  }, [onUpdate, error, validate]);

  return (
    <Card>
      <Box sx={{ display: 'flex', gap: '10px', padding: '20px' }}>
        <Box width={200}>
          <TextField
            type="number"
            inputRef={inputRef}
            label="אחוז מימון מקסימילי"
            fullWidth
            inputProps={{
              min: 0,
              max: 100,
              required: true
            }}
            InputLabelProps={{
              shrink: true
            }}
          />
          <FormHelperText error>{error}</FormHelperText>
        </Box>
        <Box paddingTop="10px">
          <Button
            type="button"
            onClick={handleUpdate}
            disabled={loading || error}
            variant="contained"
            color="primary"
          >
            עדכן
          </Button>
        </Box>
      </Box>
    </Card>
  );
};

export default FundingPercentageLimitForm;
