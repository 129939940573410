import React, { useState } from 'react';
import { SaveButton } from 'react-admin';

const TeamsSaveButton = ({ handler }) => {
  const [loading, setLoading] = useState(false);

  const handleClick = async () => {
    try {
      setLoading(true);

      await handler();

      setLoading(false);
    } catch (err) {
      setLoading(false);
      throw err;
    }
  };

  return <SaveButton saving={loading} handleSubmitWithRedirect={handleClick} />;
};

export default TeamsSaveButton;
