import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import StepperActions from './StepperActions';
import StepperHeader from './StepperHeader';
import StepperContent from './StepperContent';
import StepperFormLoading from './StepperFormLoading';

const styles = (theme) => ({
  root: {
    width: '100%',
    padding: theme.spacing(2)
  }
});

const StepperForm = ({
  classes,
  loading,
  steps,
  formPreview,
  stepIndex,
  handleNext,
  handleBack,
  handleReset,
  disableBack,
  disableNext,
  withoutReset = false,
  withoutStepsHeader = false,
  children,
  rootClassName
}) => (
  <>
    {loading ? (
      <StepperFormLoading />
    ) : (
      <div className={rootClassName || classes.root}>
        {withoutStepsHeader ? (
          ''
        ) : (
          <StepperHeader steps={steps} stepIndex={stepIndex} />
        )}
        <StepperContent
          steps={steps}
          stepIndex={stepIndex}
          formPreview={formPreview}
        />
        <StepperActions
          steps={steps}
          stepIndex={stepIndex}
          handleNext={handleNext}
          handleBack={handleBack}
          disableBack={disableBack}
          disableNext={disableNext}
          handleReset={handleReset}
          withoutReset={withoutReset}
        >
          {children}
        </StepperActions>
      </div>
    )}
  </>
);

export default withStyles(styles)(StepperForm);
