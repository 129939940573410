import React from 'react';
import { Create } from 'react-admin';
import ShortLinksMutation from './ShortLinksMutation';

const ShortLinksCreate = (props) => (
  <Create {...props}>
    <ShortLinksMutation {...props} />
  </Create>
);

export default ShortLinksCreate;
