import React, { useState, useEffect } from 'react';
import {
  Create,
  Datagrid,
  EditButton,
  Filter,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
  useCreate,
  useGetOne,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  useUpdate,
  FunctionField,
  BooleanInput,
  Toolbar,
  SaveButton,
  AutocompleteArrayInput // Import the AutocompleteArrayInput component
} from 'react-admin';
import { has, prop } from 'ramda';

// eslint-disable-next-line import/no-extraneous-dependencies
import { useFormState } from 'react-final-form';

import CircularProgress from '@material-ui/core/CircularProgress';
import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import useInteractionTypesForAutoComplete from '../../utils/hooks/useInteractionTypesForAutoComplete';

const resource = 'InteractionTypeGroup';
const fields = ['nodeId', 'id', 'types', 'name', 'active'];

const CustomToolbar = (props) => {
  const formState = useFormState();

  return (
    <Toolbar>
      <SaveButton disabled={!formState.valid || !formState.dirty} {...props} />
    </Toolbar>
  );
};

const InteractionTypeGroupFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        source="name"
        format={(value) => {
          return value ? value.value : null;
        }}
        parse={(value) =>
          value
            ? {
                filter: 'includes',
                value
              }
            : null
        }
        label={translate('ra.resources.interaction_type_group.name')}
      />
    </Filter>
  );
};

export const InteractionTypeGroup = (props) => {
  const translate = useTranslate();
  const [types, setTypes] = useState([]);
  const { types: loadedTypes, loading } = useInteractionTypesForAutoComplete();

  useEffect(() => {
    if (loadedTypes) {
      setTypes(loadedTypes);
    }
  }, [loadedTypes]);

  if (loading) {
    return <CircularProgress />; // or any loading indicator
  }

  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={<InteractionTypeGroupFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      perPage={25}
      filter={{
        specificFields: fields
      }}
    >
      <Datagrid>
        {/* <TextField source="originalId" label="מזהה" /> */}
        <TextField
          source="name"
          label={translate('ra.resources.interaction_type_group.name')}
        />

        {types.length && (
          <FunctionField
            label={translate('ra.resources.interaction_type_group.types')}
            source="types"
            render={(r) => {
              if (!types || !types.length) return null;

              const filtered = types.filter((t) => r.types.includes(t.id));
              const names = filtered.map((item) => item.label || item.name);

              if (names.length > 15) {
                const sliced = names.slice(0, 15);

                return `${sliced.join(', ')}...`;
              }

              return names.join(', ');
            }}
          />
        )}
        <FunctionField
          label={translate('ra.resources.interaction_type_group.status')}
          source="active"
          render={(r) => {
            return r.active
              ? translate('ra.general.active')
              : translate('ra.general.inactive');
          }}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const InteractionTypeGroupMutation = (props) => {
  const { types = [] } = useInteractionTypesForAutoComplete();
  const { isEdit } = props;
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();
  const [update] = useUpdate();

  const { data: previousData } = useGetOne(resource, props.id);

  const handleSave = async (data) => {
    try {
      if (!data.types || !data.types.length) {
        notify(
          translate('ra.resources.interaction_type_group.errors.noStatus'),
          'error'
        );

        return;
      }

      if (isEdit) {
        await update(resource, data.id, data, previousData, {
          returnPromise: true,
          onSuccess: () => {},
          onFailure: () => {}
        });
      } else {
        await create(resource, data, {
          returnPromise: true,
          onSuccess: () => {},
          onFailure: () => {}
        });
      }

      notify(translate(`ra.notification.${isEdit ? 'updated' : 'created'}`));
      refresh();
      redirect(`/${resource}`);
    } catch (e) {
      if (e.message.includes('duplicate key')) {
        notify(
          translate('ra.resources.interaction_type_group.errors.nameExists'),
          'error'
        );
      } else notify(translate('ra.general.technical_error'), 'error');
    }
  };

  return (
    <SimpleForm {...props} save={handleSave} toolbar={<CustomToolbar />}>
      {isEdit && (
        <TextInput
          disabled
          label={translate('ra.general.id')}
          source="originalId"
        />
      )}
      <TextInput
        source="name"
        validate={required()}
        label={translate('ra.resources.interaction_type_group.name')}
      />
      {/* Replace the SelectInput with AutocompleteArrayInput */}
      <AutocompleteArrayInput
        source="types"
        label={translate('ra.resources.interaction_type_group.types')}
        choices={types || []}
        optionText="label"
        optionValue="id"
        // fullWidth
        allowEmpty
        limitChoicesToValue={15} // Limit the number of selections to 15
        transformValue={(values) =>
          values.slice(0, 5).map((value) => ({
            id: value.id,
            name: value.name
          }))
        } // Display only the first 5 selected items
      />

      <BooleanInput
        source="active"
        defaultValue
        label={translate('ra.resources.interaction_type_group.status')}
      />
    </SimpleForm>
  );
};

export const InteractionTypeGroupEdit = (props) => {
  return (
    <SpecificFieldsEdit
      {...props}
      disableId
      validate={required()}
      specificFields={fields}
    >
      <InteractionTypeGroupMutation isEdit {...props} />
    </SpecificFieldsEdit>
  );
};

export const InteractionTypeGroupCreate = (props) => {
  return (
    <Create {...props}>
      <InteractionTypeGroupMutation {...props} />
    </Create>
  );
};
