import React, { useCallback, useMemo } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { Box, CircularProgress } from '@material-ui/core';
import CAR_COLUMNS from '../columns';
import CustomToolbar from './CustomToolbar';

const CarsDataList = ({
  newCars,
  loading,
  isSyncLoading,
  currentPage,
  total,
  onPageChange,
  handleSaveUpdatedCars,
  handleToolbarChange,
  syncMakesToSite,
  pageSize
}) => {
  const handleCellEditCommit = useCallback(
    (params) => {
      const carToUpdate = newCars.find((car) => car.id === params.id);

      if (carToUpdate) {
        const updatedCar = { ...carToUpdate, [params.field]: params.value };

        handleSaveUpdatedCars(updatedCar, params);
      }
    },
    [newCars, handleSaveUpdatedCars]
  );

  const handleCellBlur = useCallback(
    (params) => {
      if (params.hasFocus) return;

      handleCellEditCommit(params);
    },
    [newCars, handleSaveUpdatedCars]
  );

  const fieldOptions = useMemo(
    () =>
      CAR_COLUMNS.filter(({ field }) => field !== 'id').map(
        ({ field, headerName }) => ({
          id: field,
          name: headerName
        })
      ),
    [CAR_COLUMNS]
  );
  const LoadingOverlay = () => (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100%',
        backgroundColor: 'white',
        marginTop: '100px'
      }}
    >
      <CircularProgress />
    </Box>
  );

  return (
    <div style={{ width: '100%' }}>
      <CustomToolbar
        isSyncLoading={isSyncLoading}
        onSync={syncMakesToSite}
        fieldOptions={fieldOptions}
        handleToolbarChange={handleToolbarChange}
      />
      <DataGrid
        rows={loading ? [] : newCars}
        columns={CAR_COLUMNS}
        pageSize={pageSize}
        paginationMode="server"
        rowsPerPageOptions={['25']}
        disableSelectionOnClick
        pagination
        style={{
          minHeight: '200px',
          height: loading ? 'auto' : '100vh'
        }}
        page={currentPage}
        rowCount={total}
        loading={loading}
        onCellEditCommit={handleCellEditCommit}
        onCellBlur={handleCellBlur}
        isCellEditable={() => !loading}
        onPageChange={onPageChange}
        components={{
          LoadingOverlay
        }}
      />
    </div>
  );
};

export default CarsDataList;
