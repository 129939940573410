import React, { useCallback, useState } from 'react';
import Pager from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import { Box, TextField } from '@material-ui/core';
import { Button } from 'react-admin';

const ManageView = ({ revalidateUrls }) => {
  const [loading, setLoading] = useState(false);
  const [revalidateUrl, setRevalidateUrl] = useState(null);

  const handleOnRevalidate = useCallback(async () => {
    if (!revalidateUrl || loading) return;
    try {
      setLoading(true);
      await revalidateUrls([revalidateUrl]);
    } catch (error) {
      console.error('failed to revalidate', error);
    } finally {
      setLoading(false);
      setRevalidateUrl(null);
    }
  }, [revalidateUrl, loading]);

  return (
    <Pager>
      <Card style={{ padding: 16 }}>
        <Box>
          <TextField
            value={revalidateUrl}
            onChange={(e) => setRevalidateUrl(e.target.value)}
            placeholder="הכנס את הכתובת "
            style={{ direction: 'ltr', width: 200, marginLeft: 20 }}
          />

          <Button
            label="צור מחדש"
            onClick={handleOnRevalidate}
            color="primary"
            variant="contained"
            disabled={loading}
          />
        </Box>
      </Card>
    </Pager>
  );
};

export default ManageView;
