import axios from 'axios';

export async function sendSms(phone, recaptchaToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/data/otp/sms`,
    JSON.stringify({ phone, recaptchaToken }),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.data;
}

export async function verifyCode(transactionId, code, recaptchaToken) {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/data/otp/verify`,
    JSON.stringify({ transactionId, code, recaptchaToken }),
    {
      headers: {
        'Content-Type': 'application/json'
      }
    }
  );

  return res.data;
}
