import { useCallback } from 'react';
import { useNotify } from 'react-admin';

const useNotifications = () => {
  const notify = useNotify();

  const getFail = useCallback(() => {
    notify('קרתה שגיאה בטעינת הערך', 'error');
  }, [notify]);

  const updateSuccess = useCallback(() => {
    notify('הערך עודכן בהצלחה', 'success');
  }, [notify]);

  const updateFail = useCallback(() => {
    notify('קרתה שגיאה בשמירת הערך', 'error');
  }, [notify]);

  return {
    getFail,
    updateSuccess,
    updateFail
  };
};

export default useNotifications;
