import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AuditLogsView from './components/AuditLogsView';

export default {
  name: 'AuditLogs',
  list: AuditLogsView,
  icon: PlaylistAddCheckIcon,

  options: {
    label: 'לוג שינויים'
  }
};
