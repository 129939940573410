import React from 'react';
import { Edit } from 'react-admin';
import UploadAttemptsMutation from './UploadAttemptsMutation';

const UploadAttemptsEdit = (props) => (
  <Edit {...props}>
    <UploadAttemptsMutation {...props} />
  </Edit>
);

export default UploadAttemptsEdit;
