import React, { useCallback } from 'react';
import { Title } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import labels from '../../labels';
import { fetchNewCarsBulk } from './api';
import ManageView from './components/ManageView';

const Manage = () => {
  const getNewCars = useCallback(
    (pageSize, page, orderByField, orderDesc, filters) =>
      fetchNewCarsBulk(pageSize, page, orderByField, orderDesc, filters),
    []
  );

  return (
    <>
      <Title title={labels.carNameManagement}>ניהול שמות רכב</Title>
      <ManageView getNewCars={getNewCars} />
    </>
  );
};

export default Manage;
