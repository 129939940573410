import { default as phoneFormatter } from 'phone';
import { path, prop } from 'ramda';
import React, { useCallback, useState } from 'react';
import {
  SaveButton,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from 'react-admin';
import { useFormState } from 'react-final-form';

import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import { mapError } from '../../utils/utils';

const buildUpdateUserQuery = (
  uid,
  email,
  name,
  phone,
  role,
  agencyBranchId,
  teamId,
  virtualPhone,
  isMainVirtualPhone,
  secondaryRoleId,
  cityCode
) =>
  `mutation {
        updateUser(
        input: {
            uid: "${uid}"
            email: ${email ? `"${email}"` : null}
            name:"${name}"
            phone:"${phone}"
            role:${role}
            ${agencyBranchId ? `agencyBranchId:${agencyBranchId}` : ''}
            ${teamId ? `teamId:${teamId}` : ''}
            ${secondaryRoleId ? `secondaryRoleId:${secondaryRoleId}` : ''}
            ${virtualPhone ? `virtualPhone: "${virtualPhone}"` : ''}
            isMainVirtualPhone: ${isMainVirtualPhone || false}
            cityCode: ${cityCode ? `${cityCode}` : null}
        }){
            user {
              nodeId
            }
        }
    }`;

const UpdateUserButton = ({ handleSubmitWithRedirect, ...props }) => {
  const [loading, setLoading] = useState();
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { token } = useFirebaseToken();

  const handleClick = useCallback(async () => {
    if (!formState.valid) {
      return;
    }
    try {
      const {
        email,
        name,
        role,
        agencyBranchId,
        teamId,
        uid,
        virtualPhone,
        isMainVirtualPhone,
        secondaryRoleId,
        cityCode
      } = formState.values;

      const phone = prop(
        'phoneNumber',
        phoneFormatter(prop('phone', formState.values), { country: 'IL' })
      );

      setLoading(true);

      const isAdminOrAgent = role === 'ADMIN' || role === 'CARWIZ_AGENT';
      const isAgent = role === 'CARWIZ_AGENT';
      const teamIdToUpdate =
        teamId === undefined || !teamId || !isAdminOrAgent ? -1 : teamId;
      const secondaryRoleIdToUpdate =
        secondaryRoleId === undefined || !secondaryRoleId || !isAgent
          ? -1
          : secondaryRoleId;

      const result = await graphqlServiceRequest(
        token,
        buildUpdateUserQuery(
          uid,
          email,
          name,
          phone,
          role,
          agencyBranchId === undefined ? -1 : agencyBranchId,
          teamIdToUpdate, // -1 in the backend will save empty value in the user team_id field
          virtualPhone,
          isMainVirtualPhone,
          secondaryRoleIdToUpdate,
          cityCode
        ),
        API_URL
      );

      const userId = path(['data', 'updateUser', 'user', 'nodeId'], result);

      if (userId) {
        setLoading(false);
        refresh();
        notify(translate('ra.notification.updated'));
        redirect(`/User/${userId}`);
      } else {
        const errors = path(['errors'], result);
        const errMessage = mapError(errors);

        if (errMessage.includes('failed to update user email')) {
          notify(translate('ra.resources.users.mailExists'), 'warning');
        } else {
          notify(translate('ra.page.error'), 'warning');
        }
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
      notify(translate('ra.page.error'), 'warning');
    }
  }, [formState.values]);

  return (
    <SaveButton
      disabled={!formState.valid}
      handleSubmitWithRedirect={handleClick}
      saving={loading}
      {...props}
    />
  );
};

export default UpdateUserButton;
