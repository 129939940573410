import axios from 'axios';
import { default as phoneFormatter } from 'phone';
import { map, path, prop } from 'ramda';
import React, { useCallback, useState } from 'react';
import {
  SaveButton,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from 'react-admin';
import { useFormState } from 'react-final-form';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

import { API_URL } from '../../constants/constants';
import { mapError } from '../../utils/utils';

const buildCreateUserQuery = (
  uid,
  email,
  name,
  phone,
  role,
  agencyBranchId,
  teamId,
  secondaryRoleId,
  cityCode
) =>
  `mutation {
        createUser(
        input: {
            uid: "${uid}"
            email: ${email ? `"${email}"` : null}
            name:"${name}"
            phone:"${phone}"
            role:${role}
            teamId:${teamId}
            secondaryRoleId:${secondaryRoleId}
            secondaryStatus: null
            ${agencyBranchId ? `agencyBranchId:${agencyBranchId}` : ''}
            cityCode: ${cityCode ? `${cityCode}` : null}
        }){
            user {
              nodeId
            }
        }
    }`;

const deleteUserFromFirebase = async (token, uid) => {
  try {
    const res = await axios.delete(`${API_URL}/data/auth/user/${uid}`, {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json'
      }
    });

    return res.data;
  } catch (err) {
    console.error(err);
    // throw Error(JSON.stringify(err.response.data));
  }
};

const createUserInFirebase = async ({ phone, email, name, token }) => {
  try {
    const res = await axios.post(
      `${API_URL}/data/auth/user`,
      JSON.stringify({
        phone,
        email,
        name
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );
    return res.data.uid;
  } catch (err) {
    if (
      err.response &&
      err.response.data &&
      err.response.data.existingUserUid
    ) {
      console.log('existing user UID', err.response.data.existingUserUid);
      if (err.response.data.existsOnCarwiz === false) {
        console.log(
          'user exists on firebase but not on carwiz...procceeding with creating user'
        );
        return err.response.data.existingUserUid;
      }
    }
    console.log(err.response);
    throw Error(JSON.stringify(err.response.data));
  }
};
const CreateUserButton = ({ handleSubmitWithRedirect, ...props }) => {
  const [loading, setLoading] = useState();
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { token } = useFirebaseToken();

  const handleClick = useCallback(async () => {
    let firebaseUid;

    if (!formState.valid) {
      return;
    }

    try {
      const {
        email,
        name,
        role,
        agencyBranchId,
        teamId,
        secondaryRoleId,
        cityCode
      } = formState.values;
      const phone = prop(
        'phoneNumber',
        phoneFormatter(prop('phone', formState.values), { country: 'IL' })
      );

      setLoading(true);

      const isAdminOrAgent = role === 'ADMIN' || role === 'CARWIZ_AGENT';
      const isAgent = role === 'CARWIZ_AGENT';
      const transformedTeamId =
        teamId === undefined || !teamId || !isAdminOrAgent ? null : teamId;
      const transformedSecondaryRoleId =
        secondaryRoleId === undefined || !secondaryRoleId || !isAgent
          ? null
          : secondaryRoleId;

      try {
        firebaseUid = await createUserInFirebase({ phone, email, name, token });
      } catch (err) {
        notify(err.message, 'warning');
        return;
      }

      const result = await graphqlServiceRequest(
        token,
        buildCreateUserQuery(
          firebaseUid,
          email,
          name,
          phone,
          role,
          agencyBranchId,
          transformedTeamId,
          transformedSecondaryRoleId,
          cityCode
        ),
        API_URL
      );

      const userId = path(['data', 'createUser', 'user', 'nodeId'], result);
      const errors = path(['errors'], result);

      if (userId) {
        setLoading(false);
        refresh();
        notify(translate('ra.notification.created'));
        redirect(`/User/${userId}`);
      }
      if (errors) {
        await deleteUserFromFirebase(token, firebaseUid);

        setLoading(false);
        notify(mapError(errors), 'warning');
      }
    } catch (e) {
      if (firebaseUid) await deleteUserFromFirebase(token, firebaseUid);

      setLoading(false);
      notify(translate('ra.page.error'), 'warning');
    }
  }, [formState.values]);

  return (
    <SaveButton
      disabled={!formState.valid}
      handleSubmitWithRedirect={handleClick}
      saving={loading}
      {...props}
    />
  );
};

export default CreateUserButton;
