import React, { useMemo } from 'react';
import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';
import { mapHoursToRows } from './helpers';

const HoursTable = ({ value }) => {
  const classes = useStyles();
  const rows = useMemo(() => mapHoursToRows(value), [value]);
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            {headers.map((header, i) => (
              <TableCell key={i}>
                <Box className={classes.tableHeader}>{header}</Box>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, i) => (
            <TableRow key={i}>
              {row.map((cell, j) => (
                <TableCell key={j} className={classes.tableCell}>
                  {cell && `${cell.start} - ${cell.end}`}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const headers = [
  'יום ראשון',
  'יום שני',
  'יום שלישי',
  'יום רביעי',
  'יום חמישי',
  'יום שישי',
  'שבת'
];

const useStyles = makeStyles({
  tableHeader: {
    fontWeight: 'bold',
    fontSize: '1.2em'
  },
  tableCell: {
    width: '14.28%' // set the width for all table cells to 14.28%
  }
});

export default HoursTable;
