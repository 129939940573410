import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useProPermissions = (textOnly = false) => {
  const [proPermissions, setProPermissions] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchProPermissions = async () => {
    const { data } = await dataProvider(GET_LIST, 'ProPermission', {
      filter: {
        specificFields: ['nodeId', 'id', 'secondaryRole']
      },
      sort: { field: 'secondaryRole', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedProPermissions = pipe(
      map((p) => ({
        id: prop(textOnly ? 'secondaryRole' : 'originalId', p),
        name: `${prop('secondaryRole', p)}`,
        proPermissionId: prop('originalId', p)
      })),
      filter(prop('id'))
    )(data);

    setProPermissions([...mappedProPermissions]);
  };

  useEffect(() => {
    fetchProPermissions();
  }, [version]);

  return {
    proPermissions
  };
};

export default useProPermissions;
