import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button
} from '@material-ui/core';

const RemoveDialog = ({ open, onConfirm, onCancel }) => {
  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>הסרה</DialogTitle>
      <DialogContent>
        <p>האם למחוק את כל הערכים הקיימים במסך?</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancel} color="primary" variant="contained">
          ביטול
        </Button>
        <Button onClick={onConfirm} color="primary" variant="contained">
          אישור
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RemoveDialog;
