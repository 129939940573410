import React from 'react';
import { has, prop } from 'ramda';
import { Filter, TextInput, useTranslate, BooleanInput } from 'react-admin';

const UploadAttemptsFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        source="createdBy"
        label={translate('ra.resources.upload_attempts.created_by')}
      />
      <TextInput
        source="makeName"
        label={translate('ra.resources.upload_attempts.make_name')}
      />
      <TextInput
        source="modelName"
        label={translate('ra.resources.upload_attempts.model_name')}
      />
      <TextInput source="agencyName" label={translate('ra.general.agency')} />
      <TextInput
        source="branchNameFull"
        label={translate('ra.general.agency_branch')}
      />
      <TextInput source="error" label={translate('ra.general.error')} />
      <BooleanInput
        source="hasUserRemark"
        alwaysOn={false}
        label={translate('ra.resources.upload_attempts.remark')}
      />
      <TextInput
        source="createdAt"
        type="date"
        variant="filled"
        margin="dense"
        InputLabelProps={{ shrink: true }}
        parse={(value) => ({
          filter: 'greaterThanOrEqualTo',
          value
        })}
        format={(value) =>
          typeof value === 'object' &&
          has('filter', value) &&
          has('value', value)
            ? prop('value', value)
            : value
        }
        label={translate('ra.action.from_date')}
      />
    </Filter>
  );
};

export default UploadAttemptsFilter;
