import React from 'react';
import { path } from 'ramda';

const renderContent = (steps, stepIndex) => {
  const getContent = path([stepIndex, 'getContent'], steps);
  if (getContent) {
    return getContent();
  }

  return null;
};
const StepperContent = ({ steps, stepIndex, formPreview }) => (
  <>
    {steps.length === stepIndex
      ? formPreview || <div />
      : renderContent(steps, stepIndex)}
  </>
);

StepperContent.defaultProps = {
  stepIndex: 0,
  steps: []
};

export default StepperContent;
