/* eslint-disable import/prefer-default-export */
import { makeStyles } from '@material-ui/core/styles';

export const useMenuGroupStyles = makeStyles(() => ({
  list: {
    paddingLeft: '40px'
  },
  listItem: {
    padding: 0
  },
  icon: {
    minWidth: 40
  }
}));
