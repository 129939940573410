import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useCitiesForAutoComplete = (localVersion) => {
  const [cities, setCities] = useState([]);
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchCities = async () => {
    const { data } = await dataProvider(GET_LIST, 'CityLocation', {
      filter: {
        specificFields: [
          'nodeId',
          'id',
          'city',
          'district',
          'latitude',
          'longitude'
        ]
      },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedCities = pipe(
      map((city) => ({
        id: prop('city', city),
        name: prop('city', city),
        district: prop('district', city)
      })),
      filter(prop('id'))
    )(data);

    setCities(mappedCities);
  };

  useEffect(() => {
    fetchCities();
  }, [version, localVersion]);

  return {
    cities
  };
};

export default useCitiesForAutoComplete;
