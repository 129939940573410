export default {
  successText: 'הפעולה הצליחה',
  firebaseErrors: {
    'auth/email-already-in-use': 'המייל שאיתו ניסית להירשם קיים כבר במערכת ',
    'auth/invalid-email': 'המייל שלך אינו תקין ',
    'auth/weak-password': 'הסיסמה שבחרת חלשה מדי',
    'auth/wrong-password': 'אופס, קיימת שגיאה באחד מפרטי ההתחברות שלך',
    'auth/user-not-found': 'אופס, קיימת שגיאה באחד מפרטי ההתחברות שלך',
  },
};
