import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
  FormControl
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useNotify, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';
import { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import { fetchDistricts } from '../Districts/api';

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: theme.spacing(2)
  }
}));

const CityAdding = ({
  open,
  handleClose,
  handleCancel,
  onAfterSubmit = () => {}
}) => {
  const [cityLat, setCityLat] = useState('');
  const [cityLon, setCityLon] = useState('');
  const [addingCityLoading, setAddingCityLoading] = useState(false);
  const { token } = useFirebaseToken();
  const classes = useStyles();
  const notify = useNotify();
  const translate = useTranslate();

  const [districts, setDistricts] = useState([]);
  const [cityDistrict, setCityDistrict] = useState('');

  useEffect(() => {
    fetchDistricts(token).then(setDistricts);
  }, [token]);

  const {
    input: { value: city, onChange: changeCity }
  } = useField('city');

  const {
    input: { onChange: changeDistrict }
  } = useField('district');

  const buildAddCityQuery = `mutation {
    createCityLocation(input: { 
        cityLocation: { 
            city: "${city}", 
            district: "${cityDistrict}",
            latitude:${cityLat},
            longitude: ${cityLon} 
        } 
    }) {
      cityLocation {
        city
      }
    }
  }`;

  const onDistrictChange = (event) => setCityDistrict(event.target.value);

  const handleSubmit = useCallback(async () => {
    try {
      setAddingCityLoading(true);
      const result = await graphqlServiceRequest(
        token,
        buildAddCityQuery,
        API_URL
      );
      setAddingCityLoading(false);
      handleClose();
      changeDistrict(cityDistrict);
      onAfterSubmit();
      if (!result) {
        return;
      }
    } catch (e) {
      setAddingCityLoading(false);
      notify(translate('ra.page.error'), 'warning');
    }
  });

  const handleCityAddingCancel = () => {
    changeCity(null);
    handleCancel();
  };
  const fetchCityLatLonDistrict = async () => {
    try {
      // eslint-disable-next-line camelcase
      const geocodedAddresses = await geocodeByAddress(city);
      const relevantGeocodedAddress = geocodedAddresses[0];
      const { lat, lng } = await getLatLng(relevantGeocodedAddress);

      setCityLon(lng);
      setCityLat(lat);
    } catch (e) {
      console.log('error ', e);
    }
  };

  useEffect(() => {
    if (open && city) {
      fetchCityLatLonDistrict();
    }
  }, [city, open]);

  return (
    <FormControl className={classes.formControl}>
      <Dialog open={open} maxWidth="xs" fullWidth onClose={handleClose}>
        <DialogTitle id="alert-dialog-title">הוספת עיר חדשה</DialogTitle>
        <DialogContent>
          <Typography>
            כתובת הסניף שייכת לעיר שאינה קיימת ברשימת הערים. האם להוסיף אותה
            לרשימה?
          </Typography>
          <TextField
            className={classes.textField}
            fullWidth
            disabled
            label="עיר"
            value={city}
          />
          <TextField
            className={classes.textField}
            fullWidth
            disabled
            label="Longitude"
            value={cityLon}
          />
          <TextField
            className={classes.textField}
            fullWidth
            disabled
            label="Latitude"
            value={cityLat}
          />
          <TextField
            select
            className={classes.textField}
            fullWidth
            label="מחוז"
            onChange={onDistrictChange}
            value={cityDistrict}
            id="add-new-city-district-select"
            required
          >
            {districts.map((district) => (
              <MenuItem value={district.id}>{district.name}</MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleSubmit}
            color="primary"
            variant="contained"
            disabled={addingCityLoading || !cityDistrict}
          >
            {addingCityLoading ? 'טוען...' : 'הוספת עיר חדשה'}
          </Button>
          <Button onClick={handleCityAddingCancel} color="primary">
            ביטול
          </Button>
        </DialogActions>
      </Dialog>
    </FormControl>
  );
};

export default CityAdding;
