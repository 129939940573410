/* eslint-disable import/no-extraneous-dependencies */
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import { isEmpty, isNil, prop } from 'ramda';
import React, { useMemo, useState } from 'react';
import { BooleanInput, required, TextInput, useTranslate } from 'react-admin';
import { useField } from 'react-final-form';

import WorkingHours, {
  convertDaysToText
} from '../../bitComponents/working-hours/WorkingHours';

const useStyles = makeStyles(() => ({
  openingHours: {
    width: '30%'
  },
  dialog: {
    '&.hide-defaults > form > div:first-child': {
      display: 'none'
    },
    '&.hide-cb > form > div > div > div:first-child': {
      visibility: 'hidden'
    }
  }
}));

function getClassNames(classObject) {
  return Object.keys(classObject)
    .filter((className) => classObject[className])
    .join(' ');
}

const OpenHours = ({
  record = {},
  srcName,
  label,
  hideDefaults = false,
  hideCheckBox = false,
  onMokedUpdate = () => {}
}) => {
  const { isMoked = false } = record;
  const classes = useStyles();
  const translate = useTranslate();
  const {
    input: { onChange, value }
  } = useField(srcName || 'openingHours');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const formatDays = (allDays) => {
    try {
      // const allDaysJson = JSON.parse(allDaysStr);
      return convertDaysToText(allDays);
    } catch (error) {
      return allDays;
    }
  };

  const hasNoWorkingHours = (days) => {
    return Object.values(days).every(
      (day) => isEmpty(day.workingHours) || isNil(day.workingHours)
    );
  };

  const closeAndSaveHours = (allDays) => {
    const hasChanges = formatDays(allDays) !== formatDays(value);

    if (hasChanges) {
      onChange(allDays);
    }

    setIsModalOpen(false);
  };

  const dialogWrapperClassName = useMemo(() => {
    return `${classes.dialog} ${getClassNames({
      'hide-defaults': hideDefaults,
      'hide-cb': hideCheckBox
    })}`;
  }, []);

  const validateWorkingHours = (workingDays) => {
    // we use a selector to get the moked value because for some reason
    // the validation runs before the checkbox is updateing the value,
    // hence the validator runs with the previous moked value
    const moked = document.querySelector('#isMoked').checked || false;

    onMokedUpdate(moked);

    if (moked) return undefined; // no need to check for moked

    if (isNil(workingDays) || isEmpty(workingDays)) {
      return translate('ra.validation.required');
    }

    if (workingDays && hasNoWorkingHours(workingDays)) {
      return translate('ra.resources.branches.no_working_hours');
    }

    return undefined;
  };

  const [validator, setValidator] = useState(
    isMoked ? [validateWorkingHours] : [required(), validateWorkingHours]
  );

  return (
    <>
      <BooleanInput
        label="סניף מוקד"
        source="isMoked"
        defaultChecked={isMoked}
        onChange={(v) => {
          onMokedUpdate(v);

          if (v) setValidator([validateWorkingHours]);
          else setValidator([validateWorkingHours, required()]);
        }}
      />

      <TextInput
        multiline
        className={classes.openingHours}
        label={label || 'Opening Hours'}
        source={srcName || 'openingHours'}
        format={formatDays}
        disabled
        validate={validator}
      />
      <IconButton onClick={() => setIsModalOpen(true)}>
        <EditIcon />
      </IconButton>
      <Dialog
        open={isModalOpen}
        maxWidth="md"
        fullWidth
        onClose={() => setIsModalOpen(false)}
      >
        <DialogContent>
          <div className={dialogWrapperClassName}>
            <WorkingHours
              days={
                prop(srcName || 'openingHours', record)
                  ? prop(srcName || 'openingHours', record)
                  : undefined
              }
              onSubmit={closeAndSaveHours}
            />
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default OpenHours;
