import React from 'react';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SettingsIcon from '@material-ui/icons/Settings';

/** @type {import('react').FunctionComponent<GroupIconProps>} */
const GroupIcon = ({ name }) => {
  switch (name) {
    case 'parameters':
      return <SettingsIcon />;

    default:
      return <InboxIcon />;
  }
};

export default GroupIcon;

/**
 * @typedef GroupIconProps
 * @property {import('./constants').MenuGroupName} name
 */
