import validations from './validations';
import messages from './messages';
import generalValidations from '../validations';

export const PHONE_FIELD = {
  name: 'phone',
  label: messages.phone,
  validate: [generalValidations.required, validations.phone],
  type: 'tel',
  required: true
};

export const SUBMIT_BUTTON_ID = 'submit-phone-number';
