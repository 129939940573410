import React, { useEffect } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  TextField,
  TextInput,
  SimpleForm,
  Create,
  ArrayInput,
  SimpleFormIterator,
  SingleFieldList,
  ArrayField,
  ChipField,
  SelectInput,
  SelectField,
  required,
  BooleanInput,
  BooleanField
} from 'react-admin';
import SpecificFieldsEdit from '../../components/SpecificFieldsEdit'; // Import SpecificFieldsEdit component

export const StringToLabelObject = ({ record, children, ...rest }) =>
  React.cloneElement(children, {
    record: { label: record },
    ...rest
  });

export const InteractionTypesList = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    filters={<InteractionTypesFilter />}
    sort={{ field: 'id', order: 'ASC' }}
    perPage={25}
    filter={{
      specificFields: ['nodeId', 'id', 'label', 'status', 'tags', 'active']
    }}
  >
    <Datagrid>
      <TextField source="originalId" label="מזהה" />
      <TextField source="label" label="שם סטטוס" />
      <SelectField
        source="status"
        choices={[
          { id: 'WAITING', name: 'ממתינים לטיפול' },
          { id: 'DONE', name: 'טופלו' }
        ]}
        label="עמודה"
      />
      <BooleanField source="active" label="פעיל" />
      <ArrayField source="tags" label="תגיות">
        <SingleFieldList>
          <StringToLabelObject>
            <ChipField source="label" />
          </StringToLabelObject>
        </SingleFieldList>
      </ArrayField>
      <EditButton />
    </Datagrid>
  </List>
);

const CustomActiveFilter = ({ parse, ...props }) => {
  const handleParse = (value) => {
    return value ? { active: true } : {};
  };

  useEffect(() => {
    // make sure the filter is active
    const element = document.querySelector(`input[name="active"]`);

    if (element && !element.checked) element.click();
  }, []);

  return (
    <BooleanInput
      label="הצג רק פעילים"
      source="active"
      parse={handleParse}
      {...props}
    />
  );
};

const InteractionTypesFilter = ({ ...props }) => (
  <Filter {...props}>
    <TextInput source="originalId" label="מזהה" />
    <TextInput source="label" label="שם סטטוס" />
    <CustomActiveFilter alwaysOn />
  </Filter>
);

const InteractionTypesMutation = (props) => (
  <SimpleForm {...props}>
    {props.disableId ? (
      <TextInput disabled label="מזהה" source="originalId" />
    ) : (
      <TextInput label="מזהה" source="id" validate={required()} />
    )}
    <TextInput source="label" />
    <SelectInput
      source="status"
      choices={[
        { id: 'WAITING', name: 'ממתינים לטיפול' },
        { id: 'DONE', name: 'טופלו' }
      ]}
      label="עמודה"
    />
    <BooleanInput source="active" label="הצגה במסכים ניהול וטיפול" />
    <ArrayInput source="tags">
      <SimpleFormIterator>
        <TextInput />
      </SimpleFormIterator>
    </ArrayInput>
  </SimpleForm>
);

export const InteractionTypesEdit = (props) => {
  return (
    <SpecificFieldsEdit
      {...props}
      disableId
      validate={required()}
      specificFields={['nodeId', 'id', 'label', 'status', 'tags', 'active']}
    >
      <InteractionTypesMutation {...props} />
    </SpecificFieldsEdit>
  );
};

export const InteractionTypesCreate = (props) => {
  return (
    <Create {...props}>
      <InteractionTypesMutation {...props} />
    </Create>
  );
};
