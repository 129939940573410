import React from 'react';
import { pipe, keys, map } from 'ramda';

const DayList = ({ defaultDayItem, days, renderItem }) => (
  <>
    {defaultDayItem}
    {pipe(keys, map(renderItem))(days)}
  </>
);
export default DayList;
