import Box from '@material-ui/core/Box';
import LinearProgress from '@material-ui/core/LinearProgress';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { length } from 'ramda';
import React from 'react';
import messages from './messages';

const styles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  label: {
    color: theme.palette.text.primary,
    fontSize: 16,
    padding: theme.spacing(1, 0, 0, 0)
  }
});

const calculateProgress = (stepIndex, stepsCount) => {
  const value = Math.ceil((stepIndex / stepsCount) * 100);

  if (value > 100) {
    return 100;
  }

  return value;
};

const StepperHeader = ({ steps, stepIndex, classes, withoutText }) => {
  const stepsCount = length(steps);

  return stepIndex + 1 <= stepsCount ? (
    <Box className={classes.root}>
      <LinearProgress
        variant="determinate"
        value={calculateProgress(stepIndex + 1, stepsCount)}
        color="secondary"
      />
      {withoutText || (
        <Typography
          className={classes.label}
          variant="body2"
          align="center"
        >{`${stepIndex + 1} ${messages.of} ${stepsCount}`}</Typography>
      )}
    </Box>
  ) : null;
};

export default withStyles(styles)(StepperHeader);
