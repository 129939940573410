import { Badge, Button } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FsLightbox from 'fslightbox-react';
import { concat, prop } from 'ramda';
import React, { useState } from 'react';

import VideoIndicatior from './VideoIndicatior';

const styles = {};

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  modalRoot: {
    display: 'flex',
    height: '200vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  imageRoot: {
    display: 'flex',
    height: '100vh',
    alignItems: 'center',
    justifyContent: 'center'
  },
  image: {
    height: 'auto',
    maxHeight: '50%',
    maxWidth: '50%'
  }
}));

/* eslint-disable react/prefer-stateless-function */
const ImageLightboxV2 = ({ images, video }) => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const classes = useStyles();
  const data = video ? concat(images, [video]) : images;
  const isOnlyVideo = data && data.length === 1 && video;

  function toggleLightBox(val) {
    setIsLightboxOpen(val);
  }

  return (
    <div className={classes.root}>
      <Button
        onClick={() => {
          toggleLightBox(!isLightboxOpen);
        }}
        variant="contained"
        color="default"
      >
        <Badge badgeContent={data.length} color="primary">
          {isOnlyVideo ? (
            <VideoIndicatior size="large" />
          ) : (
            <img
              src={data[0].url}
              alt="Preview"
              style={{ width: 128, height: 128 }}
            />
          )}
        </Badge>
      </Button>

      {/* <style>{`
        .fslightbox-slide-number-container {
          'display': 'flex'
          'justify-content': 'center'
          'align-items': 'center'
          'position': 'relative'
          'height': '100%'
          'font-size': '15px'
          'color': '#d7d7d7'
          'z-index': '0'
          'max-width': '55px'
          'text-align': 'left'
          'direction': 'ltr !important'
          'float': 'left !important'
      }
      `}</style> */}

      <FsLightbox
        toggler={isLightboxOpen}
        exitFullscreenOnClose
        sources={data.map((obj) => {
          return prop('url', obj);
        })}
      />
    </div>
  );
};

export default withStyles(styles)(ImageLightboxV2);
