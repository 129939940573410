function openDatabase() {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open('admin', 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;
      if (!db.objectStoreNames.contains('graphql')) {
        db.createObjectStore('graphql', { keyPath: 'id' });
      }
    };

    request.onsuccess = (event) => {
      resolve(event.target.result);
    };

    request.onerror = (event) => {
      console.error('Database error:', event.target.error);
      reject(event.target.error);
    };
  });
}

export async function storeIntrospection(obj, backendVersion) {
  try {
    if (!backendVersion) return;
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['graphql'], 'readwrite');
      const objectStore = transaction.objectStore('graphql');

      // Delete any existing data
      const deleteRequest = objectStore.delete('introspection');
      deleteRequest.onsuccess = () => {
        // Store the new data
        const putRequest = objectStore.put({
          id: 'introspection',
          data: obj,
          version: backendVersion
        });
        putRequest.onsuccess = () => {
          resolve();
        };
        putRequest.onerror = (event) => {
          console.error('Store error:', event.target.error);
          reject(event.target.error);
        };
      };
      deleteRequest.onerror = (event) => {
        console.error('Delete error:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Error storing introspection:', error);
    throw error;
  }
}

export async function getIntrospection(expectedVersion) {
  try {
    const db = await openDatabase();
    return new Promise((resolve, reject) => {
      const transaction = db.transaction(['graphql'], 'readonly');
      const objectStore = transaction.objectStore('graphql');
      const request = objectStore.get('introspection');

      request.onsuccess = (event) => {
        const result = event.target.result;
        if (result && result.version === expectedVersion) {
          resolve(result.data);
        } else {
          resolve(null);
        }
      };

      request.onerror = (event) => {
        console.error('Get error:', event.target.error);
        reject(event.target.error);
      };
    });
  } catch (error) {
    console.error('Error retrieving introspection:', error);
    throw error;
  }
}
