import React, { useCallback } from 'react';
import { Title } from 'react-admin';
// eslint-disable-next-line import/no-extraneous-dependencies
import { useSelector } from 'react-redux';
import labels from '../../labels';
import { cacheUrls, clearCache, revalidateUrls } from './api';
import ManageView from './components/ManageView';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const Manage = () => {
  const { token } = useFirebaseToken();
  const getCachedUrls = useCallback(
    (pageSize, page, filter) => cacheUrls(token, pageSize, page, filter),
    [token]
  );
  const onClearCache = useCallback(() => clearCache(token), [token]);
  const onRevalidateUrls = useCallback(
    (urls) => revalidateUrls(token, urls),
    [token]
  );
  const viewVersion = useSelector((state) => state.admin.ui.viewVersion);
  if (!token) return <></>;
  return (
    <>
      <Title title={labels.uiCache}>ניהול דפים סטאטים</Title>
      <ManageView revalidateUrls={onRevalidateUrls} />
    </>
  );
};

export default Manage;
