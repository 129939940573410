import ShortLinksIcon from '@material-ui/icons/Link';
import ShortLinksCreate from './ShortLinksCreate';
import ShortLinksEdit from './ShortLinksEdit';
import ShortLinksList from './ShortLinksList';

export default {
  list: ShortLinksList,
  icon: ShortLinksIcon,
  edit: ShortLinksEdit,
  create: ShortLinksCreate
};
