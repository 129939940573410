import React, { useMemo, useState } from 'react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import { useMenuGroupStyles } from './styles';
import MenuItem from './MenuItem';
import GroupIcon from './GroupIcon';
import { Groups } from './constants';
import './types';

/** @type {React.FunctionComponent<MenuGroupProps>} */
const MenuGroup = ({
  name,
  resources,
  onMenuClick,
  sidebarIsOpen,
  urlpath
}) => {
  const classes = useMenuGroupStyles();

  const [open, setOpen] = useState(() => isGroupOpen(resources, urlpath));
  const groupLabel = useMemo(() => Groups[name]?.label, [name]);

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <List component="nav" disablePadding>
      <ListItem button onClick={handleClick}>
        <ListItemIcon className={classes.icon}>
          <GroupIcon name={name} />
        </ListItemIcon>
        <ListItemText primary={groupLabel} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding className={classes.list}>
          {resources.map((resource) => (
            <MenuItem
              key={resource.name}
              resource={resource}
              open={sidebarIsOpen}
              onMenuClick={onMenuClick}
            />
          ))}
        </List>
      </Collapse>
    </List>
  );
};

/** @type {(resources: ResourceDefinition[], path: string) => boolean} */
function isGroupOpen(resources, path) {
  const resourceName = getRsourceName(path);
  return resources.some((resource) => resource.name === resourceName);
}

function getRsourceName(path = '') {
  if (typeof path !== 'string') return null;
  if (path.startsWith('/')) return path.substring(1);
  return path;
}

export default MenuGroup;

/**
 * @typedef MenuGroupProps
 * @property {string} name
 * @property {ResourceDefinition[]} resources
 * @property {Function} onMenuClick
 * @property {boolean} sidebarIsOpen
 * @property {string} urlpath
 */
