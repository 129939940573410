import * as React from 'react';
import { useMemo } from 'react';
import { Box } from '@material-ui/core';
import MenuGroup from './MenuGroup';
import MenuItem from './MenuItem';
import { getMenuItems } from './helpers';

/** @type {import('react').FunctionComponent<MenuProps>} */
const Menu = ({ onMenuClick, open, resources, urlpath }) => {
  const items = useMemo(() => getMenuItems(resources), [resources]);

  return (
    <Box paddingTop="1.5em">
      {items.map((item) => {
        if (item.type === 'single') {
          const { resource } = item;
          return (
            <MenuItem
              key={resource.name}
              onMenuClick={onMenuClick}
              open={open}
              resource={resource}
            />
          );
        }
        if (item.type === 'group') {
          return (
            <MenuGroup
              key={item.name}
              name={item.name}
              resources={item.resources}
              onMenuClick={onMenuClick}
              sidebarIsOpen={open}
              urlpath={urlpath}
            />
          );
        }

        return null;
      })}
    </Box>
  );
};

export default Menu;

/**
 * @typedef MenuProps
 * @property {boolean} open
 * @property {string} urlpath
 * @property {ResourceDefinition[]} resources
 * @property {Function} onMenuClick
 */
