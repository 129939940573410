import React from 'react';
import {
  Create,
  Datagrid,
  EditButton,
  Filter,
  List,
  maxLength,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useCreate,
  useGetOne,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate,
  useUpdate
} from 'react-admin';

import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import useInteractionTypesForAutoComplete from '../../utils/hooks/useInteractionTypesForAutoComplete';

const resource = 'InteractionTypeSecondaryStatus';
const fields = ['nodeId', 'id', 'type', 'secondaryStatus', 'description'];

export const InteractionTypesSecondaryStatusList = (props) => {
  const translate = useTranslate();

  return (
    <List
      bulkActionButtons={false}
      {...props}
      filters={<InteractionTypesSecondaryStatusFilter />}
      sort={{ field: 'type', order: 'ASC' }}
      perPage={25}
      filter={{
        specificFields: fields
      }}
    >
      <Datagrid>
        {/* <TextField source="originalId" label="מזהה" /> */}
        <TextField
          source="type"
          label={translate('ra.resources.interaction_secondary_status.type')}
        />
        <TextField
          source="secondaryStatus"
          label={translate(
            'ra.resources.interaction_secondary_status.seconday_status'
          )}
        />
        <TextField
          source="description"
          label={translate('ra.general.description')}
        />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const InteractionTypesSecondaryStatusFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        source="type"
        label={translate('ra.resources.interaction_secondary_status.type')}
      />
      <TextInput
        source="secondaryStatus"
        label={translate(
          'ra.resources.interaction_secondary_status.seconday_status'
        )}
      />
      <TextInput
        source="description"
        label={translate('ra.general.description')}
      />
    </Filter>
  );
};

const InteractionTypesSecondaryStatusMutation = (props) => {
  const { types = [] } = useInteractionTypesForAutoComplete([
    'REGISTER',
    'CALL',
    'CALL_MOKED',
    'CALL_AGENCY'
  ]);
  const { isEdit } = props;
  const translate = useTranslate();
  const redirect = useRedirect();
  const notify = useNotify();
  const refresh = useRefresh();
  const [create] = useCreate();
  const [update] = useUpdate();

  const { data: previousData } = useGetOne(resource, props.id);

  const handleSave = async (data) => {
    try {
      if (isEdit) {
        await update(resource, data.id, data, previousData, {
          returnPromise: true,
          onSuccess: () => {},
          onFailure: () => {}
        });
      } else {
        await create(resource, data, {
          returnPromise: true,
          onSuccess: () => {},
          onFailure: () => {}
        });
      }

      notify(translate(`ra.notification.${isEdit ? 'updated' : 'created'}`));
      refresh();
      redirect(`/${resource}`);
    } catch (e) {
      if (e.message.includes('duplicate key')) {
        notify(translate('ra.notification.duplicate_item'), 'error');
      } else notify(translate('ra.page.error'), 'error');
    }
  };

  return (
    <SimpleForm {...props} save={handleSave}>
      {isEdit && (
        <TextInput
          disabled
          label={translate('ra.general.id')}
          source="originalId"
        />
      )}
      <SelectInput
        label={translate('ra.resources.interaction_secondary_status.type')}
        source="type"
        choices={types}
        disabled={isEdit === true}
        validate={required()}
      />
      <TextInput
        source="secondaryStatus"
        validate={required()}
        label={translate(
          'ra.resources.interaction_secondary_status.seconday_status'
        )}
      />
      <TextInput
        source="description"
        validate={maxLength(30)}
        label={translate('ra.general.description')}
      />
    </SimpleForm>
  );
};

export const InteractionTypesSecondaryStatusEdit = (props) => {
  return (
    <SpecificFieldsEdit
      {...props}
      disableId
      validate={required()}
      specificFields={fields}
    >
      <InteractionTypesSecondaryStatusMutation isEdit {...props} />
    </SpecificFieldsEdit>
  );
};

export const InteractionTypesSecondaryStatusCreate = (props) => {
  return (
    <Create {...props}>
      <InteractionTypesSecondaryStatusMutation {...props} />
    </Create>
  );
};
