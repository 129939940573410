import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import NumberFormat from 'react-number-format';
import Error from '@material-ui/icons/Error';
import Help from '@material-ui/icons/Help';
import { replaceWhiteSpaces } from '../../utils';
import { REQUIRED } from './constants';

const NumberFormatField = ({
  input: { onChange, onBlur, ...otherInput },
  meta: { touched, error, submitError, dirtySinceLastSubmit },
  handleChange,
  customFormat: { isString, ...customProps },
  helpButton,
  onHelpButtonClick,
  submitFieldErrors,
  required,
  ...others
}) => {
  const isSubmitError =
    submitFieldErrors && !dirtySinceLastSubmit && submitError;

  const errorMessage = () => {
    if (touched) {
      if (error && error === REQUIRED) {
        return `${others.label} ${REQUIRED}`;
      }
      if (error || isSubmitError) {
        return error || isSubmitError;
      }
    }

    return undefined;
  };

  return (
    <NumberFormat
      {...others}
      {...otherInput}
      {...customProps}
      customInput={TextField}
      id={replaceWhiteSpaces(`numberformat ${others.label}`)}
      error={touched && (error || isSubmitError)}
      fullWidth
      type="tel"
      margin="normal"
      onBlur={values => onBlur(isString ? values.value : values.floatValue)}
      onValueChange={values => {
        onChange(isString ? values.value : values.floatValue);
        (handleChange || Function)();
      }}
      helperText={errorMessage()}
      InputLabelProps={{ tabIndex: 0 }}
      FormHelperTextProps={{ tabIndex: 0 }}
      required={required}
      aria-required={required ? 'true' : 'false'}
      InputProps={{
        endAdornment: (
          <InputAdornment>
            {touched && (error || isSubmitError) && <Error color="error" />}
            {helpButton && (
              <IconButton
                aria-label="toggle help button"
                onClick={onHelpButtonClick}
              >
                <Help />
              </IconButton>
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

NumberFormatField.defaultProps = {
  input: {
    onChange: () => {},
  },
  meta: {
    touched: false,
    error: null,
  },
  customFormat: {
    isString: false,
  },
};

export default NumberFormatField;
