import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  Filter,
  EditButton,
  Create,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  BooleanField
} from 'react-admin';
import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';

export const NotificationTypesList = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    filters={<NotificationTypeFilter />}
    sort={{ field: 'type', order: 'ASC' }}
    perPage={25}
    filter={{
      specificFields: [
        'nodeId',
        'type',
        'category',
        'flow',
        'saveForLater',
        'sendEmail',
        'sendWhatsapp'
      ]
    }}
  >
    <Datagrid>
      <TextField source="type" />
      <TextField source="category" />
      <TextField source="flow" />
      <BooleanField source="saveForLater" />
      <BooleanField source="sendEmail" />
      <BooleanField source="sendWhatsapp" />
      <EditButton />
    </Datagrid>
  </List>
);

const NotificationTypeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="type" />
    <TextInput source="category" />
    <TextInput source="flow" />
  </Filter>
);

export const NotificationTypeEdit = (props) => {
  return (
    <SpecificFieldsEdit
      {...props}
      specificFields={[
        'nodeId',
        'id',
        'type',
        'flow',
        'saveForLater',
        'category',
        'sendEmail',
        'sendWhatsapp'
      ]}
    >
      <NotificationTypeMutation {...props} />
    </SpecificFieldsEdit>
  );
};
export const NotificationTypeCreate = (props) => {
  return (
    <Create {...props}>
      <NotificationTypeMutation {...props} />
    </Create>
  );
};
export const NotificationTypeMutation = (props) => {
  return (
    <SimpleForm {...props}>
      <TextInput source="type" validate={required()} />
      <ReferenceInput
        source="category"
        reference="NotificationCategory"
        allowEmpty
        perPage={2000}
        validate={required()}
      >
        <SelectInput optionText="originalId" optionValue="originalId" />
      </ReferenceInput>
      <TextInput source="flow" />
      <BooleanInput source="saveForLater" />
      <BooleanInput source="sendEmail" />
      <BooleanInput source="sendWhatsapp" />
    </SimpleForm>
  );
};
