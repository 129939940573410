/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState, useMemo } from 'react';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ClearIcon from '@material-ui/icons/Clear';
import Typography from '@material-ui/core/Typography';
import InputAdornment from '@material-ui/core/InputAdornment';
import { makeStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    background: '#F5F5F5',
    width: '100%',
    marginBottom: '10px',
    padding: '10px',
    borderRadius: '5px'
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    boxSizing: 'border-box'
  },
  itemText: {
    fontWeight: 'bold'
  },
  textField: {
    width: '30%',
    height: '36px',
    marginRight: '10px',
    marginLeft: '10px',
    backgroundColor: 'white'
  },
  button: {
    height: '36px',
    marginLeft: '10px',
    borderRadius: '20px'
  },
  errorText: {
    marginTop: '15px',
    color: 'red',
    fontSize: '12px'
  }
}));

const BulkActionToolbar = ({
  selected = [],
  loading = false,
  uid,
  onBulkAction = () => {}
}) => {
  const classes = useStyles();

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState(null);
  const [error, setError] = useState('');
  const [canSave, setCanSave] = useState(false);
  const today = new Date().toISOString().split('T')[0];

  useMemo(() => {
    setCanSave(startDate || endDate || status);
  }, [startDate, endDate, status]);

  const handleStartDateChange = (event) => {
    const newStartDate = event.target.value;

    setStartDate(newStartDate);
    setError('');

    if (endDate && newStartDate > endDate) {
      setError('תאריך הסיום לא יכול להיות לפני תאריך ההתחלה');
    } else {
      setError('');
    }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = event.target.value;

    setEndDate(newEndDate);
    setError('');

    if (startDate && newEndDate < startDate) {
      setError('תאריך הסיום לא יכול להיות לפני תאריך ההתחלה');
    } else {
      setError('');
    }
  };

  const handleStatusChange = (event) => {
    setStatus(event.target.value);
  };

  const handleClearStartDate = () => {
    setStartDate('');
    setError('');
  };

  const handleClearEndDate = () => {
    setEndDate('');
    setError('');
  };

  const handleSave = async () => {
    if (error) return;

    const modified = {};

    if (startDate) {
      modified.startDate = startDate;
    }

    if (endDate) {
      modified.endDate = endDate;
    }

    if (status) {
      modified.status = status;
    }

    // console.log(modified, modifiedSelected);
    onBulkAction(selected, modified);
  };

  return (
    <div className={classes.container}>
      <div className={classes.header}>
        <span className={classes.itemText}>{selected.length} פריטים נבחרו</span>
        <div style={{ display: 'flex', flex: 1 }}>
          <TextField
            label="תאריך התחלה"
            type="date"
            defaultValue=""
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ min: today }}
            className={classes.textField}
            onChange={handleStartDateChange}
            value={startDate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleClearStartDate}>
                    <ClearIcon />
                  </Button>
                </InputAdornment>
              )
            }}
          />
          <TextField
            label="תאריך סיום"
            type="date"
            variant="outlined"
            defaultValue=""
            InputLabelProps={{
              shrink: true
            }}
            inputProps={{ min: startDate }}
            className={classes.textField}
            onChange={handleEndDateChange}
            value={endDate}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Button onClick={handleClearEndDate}>
                    <ClearIcon />
                  </Button>
                </InputAdornment>
              )
            }}
          />
          <TextField
            select
            label="סטטוס"
            value={status}
            onChange={handleStatusChange}
            size="small"
            variant="outlined"
            InputLabelProps={{
              shrink: true
            }}
            className={classes.textField}
          >
            <MenuItem value="" selected>
              בחר סטטוס
            </MenuItem>
            <MenuItem value="active">פעיל</MenuItem>
            <MenuItem value="inactive">לא פעיל</MenuItem>
          </TextField>
        </div>
        <Button
          onClick={handleSave}
          disabled={loading || !!error || !canSave}
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<CheckCircleIcon />}
        >
          אישור
        </Button>
      </div>
      <Box>
        {error && (
          <Typography className={classes.errorText}>{error}</Typography>
        )}
      </Box>
    </div>
  );
};

export default BulkActionToolbar;
