import { pipe, groupBy, prop, map, values, join, reverse } from 'ramda';
import messages from './messages';

export const initialDays = {
  sunday: {
    enName: 'sunday',
    heName: 'ראשון',
    isDefaultHours: true,
    workingHours: [
      {
        start: '08:00',
        end: '18:00'
      }
    ]
  },
  monday: {
    enName: 'monday',
    heName: 'שני',
    isDefaultHours: true,
    workingHours: [
      {
        start: '08:00',
        end: '18:00'
      }
    ]
  },
  tuesday: {
    enName: 'tuesday',
    heName: 'שלישי',
    isDefaultHours: true,
    workingHours: [
      {
        start: '08:00',
        end: '18:00'
      }
    ]
  },
  wednesday: {
    enName: 'wednesday',
    heName: 'רביעי',
    isDefaultHours: true,
    workingHours: [
      {
        start: '08:00',
        end: '18:00'
      }
    ]
  },
  thursday: {
    enName: 'thursday',
    heName: 'חמישי',
    isDefaultHours: true,
    workingHours: [
      {
        start: '08:00',
        end: '18:00'
      }
    ]
  },
  friday: {
    enName: 'friday',
    heName: 'שישי',
    isDefaultHours: false,
    workingHours: [
      {
        start: '08:00',
        end: '14:00'
      }
    ]
  },
  saturday: {
    enName: 'saturday',
    heName: 'שבת',
    isDefaultHours: false,
    workingHours: null
  }
};

export const internalInitialDefaultDay = {
  enName: 'default',
  heName: 'ברירת מחדל',
  isDefaultHours: true,
  workingHours: [
    {
      start: '08:00',
      end: '18:00'
    }
  ]
};

const getWorkingHoursText = (workingHours) => {
  if (workingHours) {
    return map(
      (workingHoursRange) =>
        `${prop('start', workingHoursRange)}-${prop('end', workingHoursRange)}`,
      workingHours
    );
  }

  return messages.close;
};

export const arrToObjByProp = (propName) =>
  pipe(groupBy(prop(propName)), map(prop(0)));

export const convertDaysToText = (allDays) => {
  if (!allDays) return '';
  const daysTextObj = map((day) => {
    const dayHours = getWorkingHoursText(prop('workingHours', day));
    const dayHoursText =
      dayHours === prop('close', messages)
        ? dayHours
        : join(' ,', reverse(dayHours));
    let dayText = `${prop('heName', day)}`;
    dayText += ': ';
    dayText += dayHoursText;
    return dayText;
  }, allDays);

  const daysTextArr = values(daysTextObj);
  const daysTextStr = `${join('\n', daysTextArr)}`;

  return daysTextStr;
};
export const getDaysParagraph = (allDays) => {
  if (!allDays) return '';
  const daysTextObj = map((day) => `${prop('heName', day)}:`, allDays);
  return `${join('\n', values(daysTextObj))}`;
};

export const getHoursParagraph = (allDays) => {
  if (!allDays) return '';
  const daysTextObj = map((day) => {
    const dayHours = getWorkingHoursText(prop('workingHours', day));
    return dayHours === prop('close', messages)
      ? dayHours
      : join(' ,', reverse(dayHours));
  }, allDays);

  return `${join('\n', values(daysTextObj))}`;
};
