import React from 'react';
import useEditMaxFundingPercent from './hooks/useFundingPercentageLimit';
import FundingPercentageLimitForm from './components/FundingPercentageLimitForm';

const EditMaxFundingPercent = () => {
  const { loading, update, value } = useEditMaxFundingPercent();

  return (
    <FundingPercentageLimitForm
      loading={loading}
      onUpdate={update}
      value={value}
    />
  );
};

export default EditMaxFundingPercent;
