import { filter, find, path, propEq } from 'ramda';
import React, { useEffect, useState } from 'react';
import { AutocompleteInput } from 'react-admin';
import { useField } from 'react-final-form';

import useTeamsForAutoComplete from '../../utils/hooks/useTeamsForAutoComplete';
import useUsersForAutoComplete from '../../utils/hooks/useUsersForAutoComplete';

const RepresentativeInput = ({ record, onRepresentiveChange }) => {
  const { teams } = useTeamsForAutoComplete(true);
  const { users } = useUsersForAutoComplete(['ADMIN', 'CARWIZ_AGENT']);
  const [teamUsers, setTeamUsers] = useState([]);
  const [selectedTeamId, setSelectedTeamId] = useState();

  const {
    // this will allow access for representative field using the changeRepresentative function (alias for onChange)
    input: { onChange: changeRepresentative }
  } = useField('representative');

  useEffect(() => {
    // to get the representative we first need to get the representative team
    const teamName = path(['team'], record);

    if (teamName && teams) {
      const t = find(propEq('name', teamName))(teams);
      if (t) setSelectedTeamId(t.teamId);
    }
  }, [teams]);

  useEffect(() => {
    if (!selectedTeamId || !users) return;

    const fetchTeamUsers = () => {
      const tu = filter((u) => u.teamId === selectedTeamId, users);

      setTeamUsers(tu);
    };

    fetchTeamUsers(); // fetch users that are on the team

    if (selectedTeamId === -1) {
      // no team, no representative
      setTeamUsers([]);
    }
  }, [selectedTeamId, users]);

  return (
    <>
      <AutocompleteInput
        choices={teams}
        source="team"
        allowEmpty
        label="צוות"
        onSelect={({ teamId }) => {
          setSelectedTeamId(teamId);
          changeRepresentative(null);
          onRepresentiveChange(null);
        }}
      />

      <AutocompleteInput
        choices={teamUsers}
        disabled={!teamUsers.length}
        onChange={(value) => onRepresentiveChange(value)}
        source="representativeUid"
        allowEmpty
        label="נציג"
      />
    </>
  );
};

export default RepresentativeInput;
