import firebase from 'firebase/app';
import { useEffect, useState } from 'react';

// Hook
const useFirebaseToken = () => {
  const [token, setToken] = useState(null);
  const [state, setState] = useState(() => {
    const user = firebase.auth().currentUser;
    return { initializing: !user, user };
  });
  const onChange = async (user) => {
    setState({ initializing: false, user });

    const result = await user.getIdToken(true);
    setToken(result);
  };

  useEffect(() => {
    // listen for auth state changes
    const unsubscribe = firebase.auth().onAuthStateChanged(onChange);
    // unsubscribe to the listener when unmounting
    return () => unsubscribe();
  }, []);

  return { state, token };
};

export default useFirebaseToken;
