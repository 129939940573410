import React from 'react';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Error from '@material-ui/icons/Error';
import { replaceWhiteSpaces } from '../../utils/general';
import { REQUIRED } from './constants';

const CarwizTextField = ({
  input,
  meta: { touched, error, submitError, dirtySinceLastSubmit },
  children,
  submitFieldErrors,
  childComponent,
  required,
  ...others
}) => {
  const isSubmitError =
    submitFieldErrors && !dirtySinceLastSubmit && submitError;

  const errorMessage = () => {
    if (touched) {
      if (error && error === REQUIRED) {
        return `${others.label} ${REQUIRED}`;
      }
      if (error || isSubmitError) {
        return error || isSubmitError;
      }
    }

    return undefined;
  };

  return (
    <React.Fragment>
      <TextField
        error={(error || isSubmitError) && touched}
        fullWidth
        id={replaceWhiteSpaces(`textfield ${others.label}`)}
        {...others}
        {...input}
        margin="normal"
        helperText={errorMessage()}
        InputLabelProps={{ tabIndex: 0 }}
        FormHelperTextProps={{ tabIndex: 0 }}
        required={required}
        aria-required={required ? 'true' : 'false'}
        InputProps={
          (error || isSubmitError) &&
          error &&
          touched && {
            endAdornment: (
              <InputAdornment>
                <Error color="error" />
              </InputAdornment>
            ),
          }
        }
      >
        {children}
      </TextField>
      {childComponent}
    </React.Fragment>
  );
};

CarwizTextField.defaultProps = {
  meta: {
    touched: false,
    error: null,
    submitError: null,
  },
};

export default CarwizTextField;
