import React, { useState } from 'react';
import {
  prop,
  path,
  pipe,
  values,
  map,
  assoc,
  filter,
  mergeDeepRight,
  assocPath,
  last,
  append,
  dropLast,
  length
} from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import {
  internalInitialDefaultDay,
  initialDays,
  arrToObjByProp
} from './utils';
import messages from './messages';
import DayList from './DayList';
import DayItem from './DayItem';
import DefaultDayItem from './DefaultDayItem';
import WorkingHoursList from './WorkingHoursList';
import WorkingHoursItem from './WorkingHoursItem';

const useStyles = makeStyles(() => ({
  submitButtonWrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));

const WorkingHours = ({
  days = initialDays,
  initialDefaultDay = internalInitialDefaultDay,
  onSubmit
}) => {
  const classes = useStyles();
  const [defaultDay, setDefaultDay] = useState(initialDefaultDay);
  const [allDays, setAllDays] = useState(days);

  const updateHours = (
    workingHoursRangeIndex,
    updatedWorkingHoursRange,
    enName
  ) => {
    const updatedDays = pipe(
      assocPath([enName, 'isDefaultHours'], false),
      assocPath(
        [enName, 'workingHours', workingHoursRangeIndex],
        updatedWorkingHoursRange
      )
    )(allDays);

    setAllDays(updatedDays);
  };

  const applyDefaultHoursToDefaultDays = (defaultWorkingHours) => {
    const updatedDays = pipe(
      values,
      filter(prop('isDefaultHours')),
      map(assoc('workingHours', defaultWorkingHours)),
      arrToObjByProp('enName'),
      mergeDeepRight(allDays)
    )(allDays);

    setAllDays(updatedDays);
  };

  const updateDefaultHours = (
    workingHoursRangeIndex,
    updatedWorkingHoursRange
  ) => {
    const updatedDefaultDay = assocPath(
      ['workingHours', workingHoursRangeIndex],
      updatedWorkingHoursRange,
      defaultDay
    );

    setDefaultDay(updatedDefaultDay);
    applyDefaultHoursToDefaultDays(prop('workingHours', updatedDefaultDay));
  };

  const toggleDayIsOpen = ({ enName, workingHours }) => {
    const newWorkingHours = workingHours
      ? null
      : prop('workingHours', initialDefaultDay);
    const isDefaultHours = false;

    setAllDays(
      pipe(
        assocPath([enName, 'workingHours'], newWorkingHours),
        assocPath([enName, 'isDefaultHours'], isDefaultHours)
      )(allDays)
    );
  };

  const toggleDefaultDayIsOpen = ({ workingHours }) => {
    const newWorkingHours = workingHours
      ? null
      : prop('workingHours', initialDefaultDay);
    setDefaultDay(assoc('workingHours', newWorkingHours, defaultDay));
    applyDefaultHoursToDefaultDays(newWorkingHours);
  };

  const toggleDefaultHours = (enName, isDefaultHours) => {
    const updatedDays = pipe(
      assocPath([enName, 'isDefaultHours'], isDefaultHours),
      assocPath(
        [enName, 'workingHours'],
        isDefaultHours
          ? prop('workingHours', defaultDay)
          : path([enName, 'workingHours'], allDays)
      )
    )(allDays);

    setAllDays(updatedDays);
  };

  const addHoursRange = (enName) => {
    const oldWorkingHours = path([enName, 'workingHours'], allDays);
    const updatedWorkingHours = append(last(oldWorkingHours), oldWorkingHours);

    setAllDays(
      pipe(
        assocPath([enName, 'workingHours'], updatedWorkingHours),
        assocPath([enName, 'isDefaultHours'], false)
      )(allDays)
    );
  };

  const addDefaultHoursRange = () => {
    const oldWorkingHours = prop('workingHours', defaultDay);
    const updatedWorkingHours = append(last(oldWorkingHours), oldWorkingHours);

    setDefaultDay(assoc('workingHours', updatedWorkingHours, defaultDay));
    applyDefaultHoursToDefaultDays(updatedWorkingHours);
  };

  const removeHoursRange = (enName) => {
    const oldWorkingHours = path([enName, 'workingHours'], allDays);

    if (length(oldWorkingHours) >= 2) {
      const updatedWorkingHours = dropLast(1, oldWorkingHours);

      setAllDays(
        pipe(
          assocPath([enName, 'workingHours'], updatedWorkingHours),
          assocPath([enName, 'isDefaultHours'], false)
        )(allDays)
      );
    }
  };

  const removeDefaultHoursRange = () => {
    const oldWorkingHours = prop('workingHours', defaultDay);
    if (length(oldWorkingHours) >= 2) {
      const updatedWorkingHours = dropLast(1, oldWorkingHours);

      setDefaultDay(assoc('workingHours', updatedWorkingHours, defaultDay));
      applyDefaultHoursToDefaultDays(updatedWorkingHours);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(allDays);
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <DayList
          defaultDayItem={
            <DefaultDayItem
              defaultDay={defaultDay}
              toggleDefaultDayIsOpen={toggleDefaultDayIsOpen}
              addDefaultHoursRange={addDefaultHoursRange}
              removeDefaultHoursRange={removeDefaultHoursRange}
              updateDefaultHours={updateDefaultHours}
            />
          }
          days={allDays}
          renderItem={(enName) => (
            <DayItem
              key={enName}
              day={prop(enName, allDays)}
              toggleDefaultHours={toggleDefaultHours}
              toggleDayIsOpen={toggleDayIsOpen}
              addHoursRange={addHoursRange}
              removeHoursRange={removeHoursRange}
            >
              <WorkingHoursList
                workingHoursRanges={path([enName, 'workingHours'], allDays)}
                renderItem={(workingHoursRange, index) => (
                  <WorkingHoursItem
                    key={index}
                    enName={enName}
                    workingHoursRange={workingHoursRange}
                    workingHoursRangeIndex={index}
                    updateHours={updateHours}
                  />
                )}
              />
            </DayItem>
          )}
        />
        <div className={prop('submitButtonWrapper', classes)}>
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            {messages.submit}
          </Button>
        </div>
      </form>
    </>
  );
};

export default WorkingHours;

export { convertDaysToText } from './utils';
