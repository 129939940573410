export default {
  phone: 'טלפון נייד',
  loading: 'הודעת SMS עם קוד בדרך אליך...',
  loginTitle: 'התחברות',
  signupTitle: 'הרשמה',
  loginSubtitle: 'כדי להתחבר לחשבונך באתר, יש להזין מספר טלפון',
  signupSubtitle:
    'הרשמה קצרצרה כדי שנוכל להשוות רכבים, להמליץ לך על רכבים ולעזור לך להתחדש בקלות ברכב הבא שלך',
  submitButton: 'שלחו לי קוד אימות',
  successText: 'הקוד נשלח אליך',
  errorMessages: {
    phoneInvalid: 'מספר טלפון לא תקין',
    validatePhoneNumberFail: 'מצטערים, משהו לא הסתדר.. כדאי לנסות שוב',
    tooManyRequests:
      'היוזר ננעל בעקבות מספר ניסיונות כושלים של התחברות למערכת, אנא נסה שוב בעוד כשעתיים'
  }
};
