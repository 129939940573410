import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
  Create,
  required,
  Toolbar
} from 'react-admin';
// import { path } from 'ramda';
import CreateFilterableInsightButton from './CreateFilterableInsightButton';

// const showDisplayName = (record) =>
//   `${path(['agency', 'displayName'], record)} ${path(
//     ['areaName'],
//     record
//   )} (${path(['originalId'], record)}`;

export const FilterableInsightsList = (props) => (
  <List
    {...props}
    filters={<FilterableInsightsFilter />}
    sort={{ field: 'type', order: 'ASC' }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      <TextField source="category" />
      <TextField source="type" />
      <TextField source="value" />
      <TextField source="label" />
      <EditButton />
    </Datagrid>
  </List>
);

const FilterableInsightsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="category" />
    <TextInput source="type" />
    <TextInput source="value" />
    <TextInput source="label" />
  </Filter>
);

export const FilterableInsightsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="category" validate={required()} />
        <TextInput source="type" validate={required()} />
        <TextInput source="value" />
        <TextInput source="label" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const FilterableInsightCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <CreateFilterableInsightButton />
        </Toolbar>
      }
    >
      <TextInput source="category" validate={required()} />
      <TextInput source="type" validate={required()} />
      <TextInput source="value" />
      <TextInput source="label" validate={required()} />
    </SimpleForm>
  </Create>
);
