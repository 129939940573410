export default {
  name: 'שם מלא',
  email: 'מייל',
  title: 'פעם ראשונה בCARWIZ?',
  subtitle: 'היכרות קצרה וסיימנו :)',
  signupLegalRequirements:
    'המידע  שמסרתי נמסר מרצוני, וידוע לי שהוא יישמר במאגרי המידע של קארוויז מערכות בע״מ וישמש אותה כפי שמפורט באתר, כולל שליחת הודעות שיווקיות.',
  submitButton: 'סיום',
  successText: 'התחברת בהצלחה',
  errorMessages: {
    nameLength: 'שם חייב להיות באורך 2-20 תוים',
    nameInvalid: 'שם מלא לא תקין',
    emailInvalid: 'מייל לא תקין'
  },
  regulationsReadCheck:
    'קראתי את התקנון לרבות את מדיניות הפרטיות ואני מסכים/ה לאמור בו.',
  dataShareCheck:
    'לטובת שיפור השירות וקבלת הצעות שיווקיות, קארוויז ומימון ישיר ישתפו אודותיי מידע השמור במאגריהן, כמפורט בתקנון',
  regulations: 'לתקנון האתר'
};
