import { curry, reduce } from 'ramda';
import Grid from '@material-ui/core/Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';
import createDecorator from 'final-form-focus';
import { mapIndexed } from '../../utils';
import CheckboxField from './CheckboxField';
import SubmitErrorMessage from './SubmitErrorMessage';
import NumberFormatField from './NumberFormatField';
import SearchableSelectField from './SearchableSelectField';
import SelectField from './SelectField';
import SubmitButton from './SubmitButton';
import TextField from './TextField';
import './form-styles.css';

const formatsMap = {
  checkbox: CheckboxField,
  select: SelectField,
  customFormat: NumberFormatField,
  searchableSelect: SearchableSelectField
};

const composeValidators = (validators) => (value, allValues) => {
  if (validators) {
    return reduce(
      (error, validator) => error || validator(value, allValues),
      undefined
    )(validators);
  }

  return undefined;
};

const createFieldComponent = curry(
  ({ validate, ...fieldOptions }, key, submitFieldErrors) => (
    <Field
      key={key}
      index={key}
      {...fieldOptions}
      validate={composeValidators(validate)}
      component={formatsMap[fieldOptions.type] || TextField}
      submitFieldErrors={submitFieldErrors}
    />
  )
);

const ContentWrapper = ({ withGridWrapper, children }) =>
  withGridWrapper ? (
    <Grid container spacing={1}>
      {children}
    </Grid>
  ) : (
    children
  );

const focusOnError = createDecorator();

const CarwizForm = ({
  submitAction,
  formFields,
  submitButtonText,
  children,
  loading,
  success,
  successText,
  errorMessage,
  withoutSubmit,
  withGridWrapper,
  submitFieldErrors,
  pristine,
  initialValues,
  submitButtonId,
  externalSubmit,
  LoadingComponent,
  className,
  fixedWidthButton,
  trackingClassName,
  submitButtonClassName,
  formRef
}) => (
  <Form
    onSubmit={withoutSubmit ? () => {} : submitAction}
    initialValues={initialValues}
    decorators={[focusOnError]}
  >
    {({ handleSubmit, pristine: formPristine, submitting, form }) => {
      if (formRef) {
        formRef.current = form;
      }
      return (
        <form onSubmit={handleSubmit} className={className} noValidate>
          <SubmitErrorMessage errorMessage={errorMessage} />
          <ContentWrapper withGridWrapper={withGridWrapper}>
            {mapIndexed(createFieldComponent)(formFields, submitFieldErrors)}
          </ContentWrapper>
          {withoutSubmit || externalSubmit ? null : (
            <SubmitButton
              success={success}
              successText={successText}
              loading={loading}
              submitButtonText={submitButtonText}
              submitting={submitting}
              pristine={pristine && formPristine}
              submitButtonId={submitButtonId}
              LoadingComponent={LoadingComponent}
              fixedWidthButton={fixedWidthButton}
              trackingClassName={trackingClassName}
              className={submitButtonClassName}
            />
          )}
          {children}
        </form>
      );
    }}
  </Form>
);

CarwizForm.defaultProps = {
  formFields: [],
  withGridWrapper: false,
  submitFieldErrors: false
};

CarwizForm.propTypes = {
  formFields: PropTypes.array,
  withGridWrapper: PropTypes.bool,
  submitFieldErrors: PropTypes.bool
};

export default CarwizForm;

export { default as CheckboxField } from './CheckboxField';
export { default as NumberFormatField } from './NumberFormatField';
export { default as SelectField } from './SelectField';
export { default as TextField } from './TextField';
