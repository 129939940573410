import withStyles from '@material-ui/core/styles/withStyles';
import React from 'react';
import { Login } from 'react-admin';
import Logo from '../images/logo.png';
import LoginPageOtp from './LoginPageOtp';

const styles = (theme) => ({
  formContainer: {
    padding: 0,
    direction: 'rtl'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    height: 30
  }
});

const MyLoginPage = ({ classes }) => {
  return (
    <Login>
      <div className={classes.logoContainer}>
        <img className={classes.logo} src={Logo} alt="logo" />
      </div>
      <LoginPageOtp />
    </Login>
  );
};

export default withStyles(styles)(MyLoginPage);
