import React, { useState, useMemo, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Divider,
  TextField,
  Grid,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  Switch,
  CircularProgress
} from '@material-ui/core';
import { useNotify } from 'react-admin';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import DevicesIcon from '@material-ui/icons/Devices';
import LinkIcon from '@material-ui/icons/Link';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ImageKitFileUpload from '../../../components/ImageKitFileUpload';
import useAgencyBranchesForAutoComplete from '../../../utils/hooks/useAgencyBranchesForAutoComplete';
import RemoveDialog from './RemoveDialog';
import { validateLink } from '../api';
import CopyLinkInput from './CopyLinkInput';
import { getUIDomain } from '../../../utils/utils';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" color="primary" />;

const TagDrawer = ({
  onClose = () => {},
  onSave = () => {},
  data = {},
  uid,
  initialBranches = [],
  title = 'תגית' // todo: get the default branches as a prop
}) => {
  const notify = useNotify();
  const [branchesInputValue, setBranchesInputValue] = useState('');
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const [desktopImage, setDesktopImage] = useState(
    data.desktopImageUrl || null
  );
  const [startDate, setStartDate] = useState(data.startDate || '');
  const [endDate, setEndDate] = useState(data.endDate || '');
  const [linkError, setLinkError] = useState(false);
  const [status, setStatus] = useState(data.status || null);
  const [fullSearchUrl, setFullSearchUrl] = useState(null);
  const [campaignName, setCampaignName] = useState(data.campaignName || null);
  const [text, setText] = useState(data.text || null);
  const [url, setUrl] = useState(data.url || null);
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const [selectedBranches, setSelectedBranches] = useState(data.branches || []);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [carsFound, setCarsFound] = useState(null);
  const modified = { ...data };

  const createPreviewLink = () => {
    if (!url || !url.startsWith('used-cars')) {
      return '';
    }

    setFullSearchUrl(null);

    const baseUrl = `${getUIDomain()}${url}`;
    let branchesQuery = selectedBranches
      .filter((branch) => branch !== 0)
      .map((branch, i) => `agenciesIds[${i}]=${branch}`)
      .join('&');

    if (branchesQuery === 'agenciesIds[0]=0') branchesQuery = ''; // if all branches are selected, don't include the query

    const separator = baseUrl.includes('?') ? '&' : '?';
    const fullUrl = branchesQuery
      ? `${baseUrl}${separator}${branchesQuery}`
      : baseUrl;

    setFullSearchUrl(fullUrl);
  };

  const validateSearchUrl = async () => {
    let branches = selectedBranches || [];

    if (
      selectedBranches &&
      selectedBranches.length === 1 &&
      selectedBranches[0] === 0
    )
      branches = [];

    try {
      setCarsFound(-1);
      setLoading(true);

      const res = await validateLink({ url, branches });

      setLoading(false);
      setCarsFound(res.count);
    } catch (err) {
      notify('קרתה שגיאה בבדיקת כמות הרכבים בסינון', 'error');
      setLoading(false);
      setCarsFound(-1);
    }
  };

  const areRequiredFieldsFilled = () => {
    return (
      !!startDate &&
      !!endDate &&
      !!campaignName &&
      !!desktopImage &&
      !!text &&
      !!url &&
      url.startsWith('used-cars')
    );
  };

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];

    if (!startDate || !endDate || startDate > today || today > endDate) {
      setStatus('inactive');
      setIsStatusDisabled(true);
    } else {
      setIsStatusDisabled(false);
    }
  }, [startDate, endDate]);

  const handleSave = (clear = false) => {
    if (clear) {
      Object.keys(modified).forEach((key) => {
        modified[key] = null;
      });

      modified.creatingUid = uid;
    } else {
      modified.startDate = startDate;
      modified.endDate = endDate;
      modified.creatingUid = uid;
      modified.desktopImageUrl = desktopImage;
      modified.branches = selectedBranches;
      modified.status = status;
      modified.text = text;
      modified.url = url;
      modified.campaignName = campaignName;
    }

    modified.location = 'USEDCARS';
    modified.sourceTable = 'Tag';

    onSave(modified);
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
    setDirty(true);
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    setDirty(true);
  };

  const hasSomeData = () => {
    // Check if any value in the data object, excluding some keys that always have a value
    return Object.entries(data).some(([key, value]) => {
      if (key === 'id' || key === 'nodeId' || key === 'location') {
        return false;
      }

      return !!value; // Convert value to boolean and check if it's truthy
    });
  };

  const helpText = `ניתן להעלות קבצים בפורמטים: JPG, PNG, GIF. גודל קובץ מקסימלי 10mb`;

  const branchOpts = useMemo(() => {
    return [{ id: 0, name: 'בחר הכל' }, ...(agencyBranches || [])];
  }, [agencyBranches]);

  return (
    <div style={{ width: 700, padding: 16 }}>
      {/* Close button */}
      <IconButton
        style={{ position: 'absolute', top: 8, left: 8 }}
        onClick={() => onClose(dirty)}
      >
        <CloseIcon />
      </IconButton>
      {/* Title */}
      <Typography
        variant="h6"
        style={{ marginTop: 4, marginBottom: 8, color: '#519FAB' }}
      >
        {title}
      </Typography>
      {/* Divider */}
      <Divider style={{ marginBottom: 16 }} />
      {/* Switch input */}
      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}
      >
        {/* Switch input */}
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="body1"
            style={{ marginLeft: 16, fontWeight: 'bold' }}
          >
            סטטוס
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={data.status === 'active'}
                checked={status === 'active'}
                name="status"
                color="primary"
                disabled={isStatusDisabled}
                onChange={(ev) => {
                  setStatus(ev.currentTarget.checked ? 'active' : 'inactive');
                  setDirty(true);
                }}
              />
            }
            label="פעיל"
          />
        </Grid>
        {/* Status label */}
      </Grid>
      {/* Date fields */}
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <TextField
            required
            error={errors.startDate}
            helperText={errors.startDate && 'שדה זה חובה'}
            variant="outlined"
            label="תאריך התחלה"
            type="date"
            defaultValue={data.startDate || ''}
            onChange={(ev) => {
              handleStartDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: { min: new Date().toISOString().split('T')[0] }
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            error={errors.endDate}
            helperText={errors.endDate && 'שדה זה חובה'}
            variant="outlined"
            label="תאריך סיום"
            type="date"
            defaultValue={data.endDate || ''}
            onChange={(ev) => {
              handleEndDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: {
                min: startDate || new Date().toISOString().split('T')[0]
              }
            }}
          />
        </Grid>
      </Grid>
      {/* Text fields */}

      {endDate && startDate && endDate < startDate && (
        <p style={{ color: 'red' }}>
          לא ניתן להגדיר תאריך סיום שקטן מתאריך ההתחלה
        </p>
      )}

      <TextField
        label="שם קמפיין"
        error={errors.campaignName}
        onBlur={(e) => setErrors({ campaignName: !e.target.value })}
        helperText={errors.campaignName && 'שדה זה חובה'}
        required
        defaultValue={data.campaignName || ''}
        onChange={(e) => {
          setCampaignName(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
      />

      <TextField
        label="טקסט"
        required
        error={errors.text}
        onBlur={(e) => setErrors({ text: !e.target.value })}
        helperText={errors.text && 'שדה זה חובה'}
        defaultValue={data.text || ''}
        onChange={(e) => {
          setText(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        inputProps={{ maxLength: 20 }}
        style={{ marginBottom: 16 }}
      />

      <TextField
        label="לינק לבחירת רכבים"
        error={errors.url}
        helperText={errors.url && 'שדה זה חובה'}
        required
        defaultValue={data.url || ''}
        onChange={(e) => {
          setUrl(e.target.value);
          setDirty(true);
          setCarsFound(-1);
        }}
        onBlur={(e) => {
          setErrors({ url: !e.target.value });
          setLinkError(
            e.target.value && !e.target.value.startsWith('used-cars')
          );
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        InputProps={{
          style: { direction: 'ltr' },
          startAdornment: <LinkIcon />
        }}
      />

      {linkError && (
        <p style={{ color: 'red' }}>הלינק חייב להתחיל ב- “used-cars”</p>
      )}

      {/* Select input */}
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <Autocomplete
          multiple
          autoComplete
          defaultValue={initialBranches}
          options={branchOpts}
          limitTags={3}
          style={{ width: '100%' }}
          disableCloseOnSelect
          inputValue={branchesInputValue}
          onInputChange={(event, value, reason) => {
            if (event && event.type === 'blur') {
              setBranchesInputValue('');
            } else if (reason !== 'reset') {
              setBranchesInputValue(value);
            }
          }}
          value={selectedBranches.map((id) =>
            branchOpts.find((option) => option.id === id)
          )}
          getOptionSelected={(option, value) => {
            return value && option.id === value.id;
          }}
          getOptionLabel={(option) => {
            if (!option) return null;

            return option.id !== 0
              ? `${option.agencyName} - ${option.areaName}`
              : 'כל הסניפים';
          }}
          getOptionDisabled={(option) =>
            selectedBranches.includes(0) && option.id !== 0
          }
          onChange={(event, selectedOptions) => {
            const selectedIds = selectedOptions.map((option) => option.id);

            if (selectedIds.includes(0)) {
              // If "All Branches" is selected, clear other selections
              setSelectedBranches([0]);
            } else {
              setSelectedBranches(selectedIds);
            }

            setCarsFound(-1);
            setDirty(true);
          }}
          renderOption={(option, { selected }) => (
            <>
              <Checkbox
                icon={icon}
                checkedIcon={checkedIcon}
                style={{ marginRight: 8 }}
                checked={selected}
              />
              {option?.id !== 0
                ? `${option.agencyName} - ${option.areaName}`
                : option.name}
            </>
          )}
          renderInput={(params) => (
            <TextField label="סניף" {...params} variant="outlined" />
          )}
        />
      </FormControl>

      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 16 }}>
        <Button
          variant="contained"
          color="primary"
          helperText="* לא ניתן לשמור טרם ביצוע בדיקת רכבים"
          onClick={validateSearchUrl}
          disabled={loading || !url}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            'הצג כמות רכבים המתאימים לסינון'
          )}
        </Button>
        {carsFound !== null && carsFound >= 0 && (
          <Typography style={{ marginRight: 16 }}>
            {carsFound >= 10
              ? `נמצאו ${carsFound} רכבים `
              : `נמצאו ${carsFound} רכבים  , לא ניתן לסנן עבור מספר נמוך של רכבים`}
          </Typography>
        )}
      </div>
      {carsFound && carsFound === -1 && (
        <Typography variant="caption" style={{ margin: 5 }}>
          * לא ניתן לשמור טרם ביצוע בדיקת רכבים
        </Typography>
      )}

      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginBottom: 16,
          marginTop: 10
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={createPreviewLink}
          disabled={!url || !url.startsWith('used-cars')}
        >
          הכן URL מאוחד
        </Button>
      </div>

      {url && fullSearchUrl && <CopyLinkInput link={fullSearchUrl} />}

      <ImageKitFileUpload
        maxSizeMB={10}
        helpText={helpText}
        onUploadSuccess={setDesktopImage}
        onImageChange={(imageUrl) => {
          setDirty(true);
          setErrors({ desktopImage: !imageUrl });
        }}
        text="לחץ כאן כדי להעלות מדיה "
        folder="hero-content"
        initialImage={desktopImage}
        required={errors.desktopImage}
        icon={<DevicesIcon fontSize="large" />}
      />
      <br />

      {/* Save and Cancel buttons */}
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Button
          disabled={!hasSomeData()}
          variant="outlined"
          onClick={() => setDialogOpen(true)}
          style={{
            marginLeft: 16,
            width: '40%',
            color: hasSomeData() ? 'red' : 'rgba(0, 0, 0, 0.26)',
            backgroundColor: hasSomeData() ? '' : 'rgba(0, 0, 0, 0.12)',
            border: hasSomeData() ? '1px solid red' : '1px solid lightgray'
          }}
        >
          הסרה
        </Button>

        <Button
          disabled={
            !dirty ||
            !areRequiredFieldsFilled() ||
            (carsFound !== null && carsFound < 10)
          }
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          style={{ width: '40%' }}
        >
          שמירה
        </Button>
      </div>

      <RemoveDialog
        onConfirm={() => handleSave(true)}
        open={isDialogOpen}
        onCancel={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default TagDrawer;
