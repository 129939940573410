import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useDynamicContentAd = (adNum = null) => {
  const [dynamicContentAd, setDynamicContentAd] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchDynamicContentAd = async (num) => {
    const filters = {
      specificFields: [
        'id',
        'adNum',
        'campaignName',
        'desktopImageUrl',
        'startDate',
        'endDate',
        'location',
        'status',
        'text',
        'textColor',
        'textSize',
        'textWeight',
        'buttonText',
        'buttonTextColor',
        'buttonTextSize',
        'buttonTextWeight',
        'buttonBgColor',
        'url',
        'clickable',
        'creatingUid',
        'updatedAt',
        'legalStatus',
        'legalText',
        'legalColor'
      ]
    };

    if (num) {
      filters.adNum = parseInt(num, 10);
    }

    const { data } = await dataProvider(GET_LIST, 'DynamicContentAd', {
      filter: filters,
      sort: { field: 'updatedAt', order: 'DESC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedContent = pipe(
      map((content) => ({
        id: prop('originalId', content),
        nodeId: prop('id', content),
        adNum: prop('adNum', content),
        campaignName: prop('campaignName', content),
        startDate: prop('startDate', content),
        endDate: prop('endDate', content),
        status: prop('status', content),
        creatingUid: prop('creatingUid', content),
        location: prop('location', content),
        text: prop('text', content),
        textColor: prop('textColor', content),
        textSize: prop('textSize', content),
        textWeight: prop('textWeight', content),
        buttonText: prop('buttonText', content),
        buttonTextColor: prop('buttonTextColor', content),
        buttonTextSize: prop('buttonTextSize', content),
        buttonTextWeight: prop('buttonTextWeight', content),
        buttonBgColor: prop('buttonBgColor', content),
        desktopImageUrl: prop('desktopImageUrl', content),
        clickable: prop('clickable', content),
        url: prop('url', content),
        legalStatus: prop('legalStatus', content),
        legalText: prop('legalText', content),
        legalColor: prop('legalColor', content)
      })),
      filter(prop('id'))
    )(data);

    mappedContent.shift(); // remove the recently added record

    setDynamicContentAd([...mappedContent]);
  };

  useEffect(() => {
    if (!adNum) {
      console.error('useDynamicContentAd - missing dynamic content ad num!');
      setDynamicContentAd([]);
    } else {
      fetchDynamicContentAd(adNum.split('d')[1]);
    }
  }, [version]);

  return {
    dynamicContentAd
  };
};

export default useDynamicContentAd;
