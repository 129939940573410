import { equals } from 'ramda';
import { convertDaysToText } from '../../bitComponents/working-hours/WorkingHours';

import './types';

const DayNames = [
  ['sunday', 'ראשון'],
  ['monday', 'שני'],
  ['tuesday', 'שלישי'],
  ['wednesday', 'רביעי'],
  ['thursday', 'חמישי'],
  ['friday', 'שישי'],
  ['saturday', 'שבת']
];

const DayNameOrder = {
  sunday: 1,
  monday: 2,
  tuesday: 3,
  wednesday: 4,
  thursday: 5,
  friday: 6,
  saturday: 7
};

export const DayKeys = DayNames.map(([a]) => a);

/** @type {(day:number) => DayInput} */
export const DayInput = (day) => {
  const [enName, heName] = DayNames[day - 1];
  return {
    enName,
    heName,
    isDefaultHours: false,
    workingHours: []
  };
};

/** @type {() => HoursInput} */
export const HoursInput = () => ({
  sunday: DayInput(1),
  monday: DayInput(2),
  tuesday: DayInput(3),
  wednesday: DayInput(4),
  thursday: DayInput(5),
  friday: DayInput(6),
  saturday: DayInput(7)
});

/** @type {(start:string,end:string) => HoursRange} */
export const HoursRange = (start, end) => ({ start, end });

const formatHoursTime = (value = '00:00') => `${value}:00`;

const formatInputTime = (value = '00:00:00') => {
  const [hh, mm] = value.split(':');
  return `${hh}:${mm}`;
};

/** @type {(src: MokedWorkingHour[]) => HoursInput} */
export const mapHoursToInput = (src) => {
  const input = HoursInput();

  for (let i = 0; i < src.length; i += 1) {
    const record = src[i];
    /** @type {DayInput} */
    const day = input[DayNames[record.day - 1][0]];
    day.workingHours.push({
      start: formatInputTime(record.startTime),
      end: formatInputTime(record.endTime)
    });
  }

  return input;
};

/** @type {(input: HoursInput) => HoursInput} */
export const cleanInput = (input) => {
  const values = Object.values(input);
  for (let i = 0; i < values.length; i += 1) {
    const day = values[i];

    if (day.workingHours && day.workingHours.length === 0) {
      day.workingHours = null;
    }
  }

  return input;
};

export const prepareHoursInput = (hours) => cleanInput(mapHoursToInput(hours));

export const getFormInput = (value) => {
  const res = {
    hours: prepareHoursInput(value.hours),
    active: value.active
  };

  return res;
};

/** @type {(feature:string, input: HoursInput) => MokedWorkingHour[]} */
export const mapInputToHours = (feature, input) => {
  return Object.keys(input)
    .map((key) => {
      /** @type {DayInput} */
      const day = input[key];
      if (!day.workingHours || day.workingHours.length === 0) {
        return [];
      }
      return day.workingHours.map((wh) => ({
        day: DayNameOrder[key],
        feature,
        startTime: formatHoursTime(wh.start),
        endTime: formatHoursTime(wh.end)
      }));
    })
    .reduce((a, b) => a.concat(b), []);
};

export const getFormValue = (feature, value) => ({
  hours: mapInputToHours(feature, value.hours),
  active: value.active
});

/** @type {(value: MokedWorkingHour[], other:MokedWorkingHour[]) => boolean} */
export const checkHoursEqual = (value, other) => {
  const val1 = mapHoursToInput(value);
  const val2 = mapHoursToInput(other);

  return equals(val1, val2);
};

function transposeArrays(arrays, defValue) {
  const maxLength = arrays.reduce(
    (max, array) => Math.max(max, array.length),
    0
  );

  const transposed = [];
  for (let i = 0; i < maxLength; i += 1) {
    transposed[i] = arrays.map((array) => array[i] || defValue);
  }

  return transposed;
}

/** @type {(hours:HoursInput) => HoursRange[][]} */
export const mapHoursToRows = (hours) => {
  const ranges = [
    'sunday',
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday'
  ].map((key) => hours[key].workingHours || []);

  const rows = transposeArrays(ranges);
  return rows;
};

export const formatDays = (allDays) => {
  try {
    return convertDaysToText(allDays);
  } catch (error) {
    return allDays;
  }
};

/** @type {(range:HoursRange) => string} */
export const hoursRangeToString = ({ start, end }) => `[${start}-${end}]`;

/** @type {(day:DayInput) => string} */
export const dayInputToString = (day) => {
  const workingHours = day.workingHours || [];
  const hours = workingHours.map(hoursRangeToString).join(',');
  return `DayInput(${hours})`;
};

/** @type {(in1:HoursInput, in2:HoursInput) => boolean} */
export const hoursInputsAreEqual = (in1, in2) => {
  return DayKeys.every((key) => {
    const d1 = in1[key] || { workingHours: [] };
    const d2 = in2[key] || { workingHours: [] };
    return dayInputToString(d1) === dayInputToString(d2);
  });
};

/** @type {(time:string) => number} */
const getNumMin = (time) => {
  const [hh, mm] = time.split(':');
  const hours = +hh || 0;
  const min = +mm || 0;
  return hours * 60 + min;
};

/** @type {(range:HoursRange) => [number, number]} */
const toMin = (range) => [getNumMin(range.start), getNumMin(range.end)];

/** @type {(r1:HoursRange, r2:HoursRange) => boolean} */
const isRangeInside = (r1, r2) => {
  const [s1, e1] = toMin(r1);
  const [s2, e2] = toMin(r2);

  return s1 >= s2 && e1 <= e2;
};

/** @type {(ranges: HoursRange[]) => HoursRange[]} */
export const normalizeWorkingHours = (ranges) => {
  return ranges.reduce((/** @type {HoursRange[]} */ acc, range) => {
    const isInside = acc.some((r) => isRangeInside(range, r));
    if (isInside) return acc;
    const filtered = acc.filter((r) => !isRangeInside(r, range));
    return [...filtered, range];
  }, []);
};

/** @type {(hours:HoursInput) => HoursInput} */
export const normalizeHoursInput = (hours) => {
  const res = HoursInput();

  const keys = Object.keys(hours);

  for (let i = 0; i < keys.length; i += 1) {
    const key = keys[i];
    const day = hours[key];
    res[key].workingHours = normalizeWorkingHours(day?.workingHours || []);
  }

  return res;
};
