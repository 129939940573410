import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

const styles = () => ({
  progressContainer: {
    padding: 10,
    justifyContent: 'center',
    display: 'flex'
  }
});

const StepperLoading = ({ classes }) => (
  <div className={classes.progressContainer}>
    <CircularProgress />
  </div>
);

export default withStyles(styles)(StepperLoading);
