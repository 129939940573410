import { isEmpty, trim } from 'ramda';

export const nameValidator = (value, checkSpecialChars = true) => {
  const nameReg = /^[a-zA-Zא-ת ]+$/;
  const nameRegWithNumbers = /^[a-zA-Zא-ת0-9]+$/;
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

  /**
   * consider using this
   * const hebrewChars = new RegExp("^[\u0590-\u05FF]+$");
   * const alphaNumericChars = new RegExp("^[a-zA-Z0-9\-]+$");
   * const englishChars = new RegExp("^[a-zA-Z\-]+$");
   * const legalChars = new RegExp("^[a-zA-Z\-\u0590-\u05FF ]+$");
   */

  if (!value.match(nameReg)) {
    return 'name should contain some alphabet characters';
  }
  if (checkSpecialChars && value.match(specialChars)) {
    return 'name should not contain special characters';
  }

  return undefined;
};

export const trimName = (value) => {
  if (isEmpty(value)) {
    return value;
  }

  return trim(value);
};
