import { introspectionQuery } from 'graphql';
import gql from 'graphql-tag';
import { GET_LIST, GET_ONE } from 'ra-core';
import { ALL_TYPES } from './constants';
import { getIntrospection, storeIntrospection } from './cache';

export const filterTypesByIncludeExclude = ({ include, exclude }) => {
  if (Array.isArray(include)) {
    return (type) => include.includes(type.name);
  }

  if (typeof include === 'function') {
    return (type) => include(type);
  }

  if (Array.isArray(exclude)) {
    return (type) => !exclude.includes(type.name);
  }

  if (typeof exclude === 'function') {
    return (type) => !exclude(type);
  }

  return () => true;
};

/**
 * @param {ApolloClient} client The Apollo client
 * @param {Object} options The introspection options
 */
export default async (client, options) => {
  const backendVersion = options.backendVersion;
  const cached = await getIntrospection(backendVersion);
  if (cached) {
    return cached;
  }
  const schema = options.schema
    ? options.schema
    : await client
        .query({
          fetchPolicy: 'network-only',
          query: gql`
            ${introspectionQuery}
          `
        })
        .then(({ data: { __schema } }) => __schema);

  const queries = schema.types.reduce((acc, type) => {
    if (
      type.name !== schema.queryType.name &&
      type.name !== schema.mutationType.name
    )
      return acc;

    return [...acc, ...type.fields];
  }, []);

  const types = schema.types.filter(
    (type) =>
      type.name !== schema.queryType.name &&
      type.name !== schema.mutationType.name
  );

  const isResource = (type) => {
    return (
      (queries.some(
        (query) =>
          `all${query.name}`.toLowerCase() ===
          options.operationNames[GET_LIST](type).toLowerCase()
      ) &&
        queries.some(
          (query) =>
            query.name.toLowerCase() ===
              options.operationNames[GET_ONE](type).toLowerCase() ||
            query.name.toLowerCase() ===
              `${options.operationNames[GET_ONE](type).toLowerCase()}s`
        )) ||
      (queries.some(
        (query) =>
          `all${query.name}es`.toLowerCase() ===
          options.operationNames[GET_LIST](type).toLowerCase()
      ) &&
        queries.some(
          (query) =>
            query.name.toLowerCase() ===
            options.operationNames[GET_ONE](type).toLowerCase()
        )) ||
      (queries.some(
        (query) =>
          `all${query.name}s`.toLowerCase() ===
          options.operationNames[GET_LIST](type).toLowerCase()
      ) &&
        queries.some(
          (query) =>
            query.name.toLowerCase() ===
            options.operationNames[GET_ONE](type).toLowerCase()
        ))
    );
  };

  const buildResource = (type) =>
    ALL_TYPES.reduce(
      (acc, aorFetchType) => ({
        ...acc,
        [aorFetchType]: queries.find(
          (query) =>
            options.operationNames[aorFetchType] &&
            (query.name.toLowerCase() ===
              options.operationNames[aorFetchType](type).toLowerCase() ||
              `all${query.name}`.toLowerCase() ===
                options.operationNames[aorFetchType](type).toLowerCase() ||
              `all${query.name}es`.toLowerCase() ===
                options.operationNames[aorFetchType](type).toLowerCase() ||
              `all${query.name}s`.toLowerCase() ===
                options.operationNames[aorFetchType](type).toLowerCase())
        )
      }),
      { type }
    );

  const potentialResources = types.filter(isResource);
  const filteredResources = potentialResources.filter(
    filterTypesByIncludeExclude(options)
  );
  const resources = filteredResources.map(buildResource);

  const introspesionObj = {
    types,
    queries,
    resources,
    schema
  };

  storeIntrospection(introspesionObj, backendVersion);
  return introspesionObj;
};
