import axios from 'axios';
import { API_URL } from '../constants/constants';
import buildGraphQLProvider from '../shared-components/ra-data-postgraphile';
import { buildQuery } from '../shared-components/ra-data-postgraphile/buildQuery';

let dbProvider = null;
let loadingPromise = null;

const getBackendVersion = async () => {
  try {
    const backendVersion = await axios.get(`${API_URL}/data/version`);
    return backendVersion.data.version;
  } catch {
    return null;
  }
};

const load = async () => {
  const backendVersion = await getBackendVersion();

  const provider = await buildGraphQLProvider({
    clientOptions: { uri: API_URL },
    buildQuery,
    introspection: { backendVersion }
  });

  return provider;
};

export const initGraphQLProvider = async () => {
  if (dbProvider) return;
  if (loadingPromise) return loadingPromise;

  loadingPromise = load();
  dbProvider = await loadingPromise;
};

export const dataProviderWrapper = async (...args) => {
  if (!dbProvider) {
    await initGraphQLProvider();
  }
  return dbProvider(...args);
};
