import React from 'react';
import { Toolbar } from 'react-admin';
import TeamsSaveButton from './TeamsSaveButton';

const TeamsToolbar = ({ handler }, props) => {
  return (
    <Toolbar {...props}>
      <TeamsSaveButton handler={handler} />
    </Toolbar>
  );
};

export default TeamsToolbar;
