export default {
  code: 'קוד האימות שנשלח אליך',
  loading: 'רק רגע...',
  title: 'אימות הטלפון',
  subtitle: 'שלחנו לך SMS עם קוד אימות',
  successText: 'קוד אומת',
  submitButton: 'בואו נתקדם',
  resend: 'שליחת קוד אימות שוב',
  reset: 'שליחה לטלפון אחר',
  errorMessages: { validateVerificationCodeFail: 'הקוד שהזנת שגוי' },
  resendLoading: 'קוד אימות בדרך...',
  resendError: 'שגיאה בשליחה בשנית'
};
