import React, { useCallback, useState } from 'react';
import Pager from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import { useNotify } from 'react-admin';
import SearchFilter from './SearchFilter';
import AuditLogsList from './AuditLogsList';
import fetchAuditLogs from '../api';
import useFirebaseToken from '../../../bitComponents/utils/hooks/useFirebaseToken';

const AuditLogsView = () => {
  const { token } = useFirebaseToken();
  const notify = useNotify();
  const [loading, setLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const pageSize = 25;

  const validateSearchFilter = (filter) => {
    if (Object.keys(filter).length === 0) return false;

    if (!filter.tables || !filter.tables.length) {
      notify('לא נבחרו טבלאות', 'error');
      return false;
    }

    if (!filter.from || !filter.to) {
      notify('לא נבחרו תאריכים', 'error');
      return false;
    }

    if (new Date(filter.from) > new Date(filter.to)) {
      notify('תאריך ההתחלה הוא אחרי תאריך הסיום', 'error');
      return false;
    }

    return true;
  };

  const fetchLogs = useCallback(
    async (filter, page = 0) => {
      setLoading(true);

      try {
        if (!validateSearchFilter(filter) || !token) return;

        const res = await fetchAuditLogs(token, {
          ...filter,
          pageSize,
          page
        });

        setLogs(res.logs); // Reset logs state
        setTotal(res.total); // Reset total state
      } catch (error) {
        console.error('Error fetching audit logs:', error); // Log any errors
        notify('שגיאה בעת יבוא לוגים', 'error');
      } finally {
        setLoading(false); // Update loading state
      }
    },
    [token]
  );

  const handleOnPageChange = useCallback(
    async (page) => {
      await fetchLogs(searchFilter, page);
      setCurrentPage(page);
    },
    [searchFilter]
  );

  const handleOnSearchFilterChange = useCallback(
    (filter) => {
      setCurrentPage(0); // reset the page state if the filter changes
      setSearchFilter(filter);
      fetchLogs(filter);
    },
    [fetchLogs, token]
  );

  return (
    <Pager>
      <Card style={{ padding: 16 }}>
        <SearchFilter
          onSearchFilterChange={handleOnSearchFilterChange}
          loading={loading}
        />
        <AuditLogsList
          logs={logs}
          loading={loading}
          total={total}
          onPageChange={handleOnPageChange}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </Card>
    </Pager>
  );
};

export default AuditLogsView;
