import { LinearProgress, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import DirectionsCar from '@material-ui/icons/DirectionsCar';
import PropTypes from 'prop-types';
import {
  assoc,
  filter,
  head,
  isEmpty,
  isNil,
  length,
  map,
  not,
  pipe,
  prop
} from 'ramda';
import React from 'react';

import ImageLightboxV2 from '../ImageLightBoxV2';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    minHeight: 60,
    backgroundColor: 'white'
  },
  image: {
    width: '100%',
    minHeight: '100%',
    overflow: 'hidden',
    objectFit: 'cover'
  },
  iconRoot: {
    fontSize: 40
  },
  noImageTypography: {
    color: '#959595 '
  },
  noImageLayout: { margin: 'auto', textAlign: 'center' }
};

const NoImage = withStyles(styles)((props) => {
  const { classes, label, loading } = props;
  return (
    <div className={classes.noImageLayout}>
      <DirectionsCar color="disabled" classes={{ root: classes.iconRoot }} />
      {loading ? (
        <LinearProgress />
      ) : (
        <Typography className={classes.noImageTypography}>{label}</Typography>
      )}
    </div>
  );
});

const imageUrl = (file) => prop('originalUrl', file);

const buildUrl = (file) => ({
  url: imageUrl(file),
  fallbackUrl: prop('originalUrl', file) || ''
});

const getImageAndFallbacks = (images) => map(buildUrl, images);

const getVideo = (videos) =>
  length(videos) > 0
    ? pipe(map(buildUrl), head, assoc('video', true))(videos)
    : null;

const notNil = pipe(isNil, not);

const getValidImages = (images) =>
  notNil(images) ? pipe(filter(notNil))(images) : [];

const ImageSectionV2 = ({
  classes,
  images = [],
  videos = [],
  license = null,
  noImageLabel,
  loading,
  error
}) => {
  const validImages = getValidImages(images);
  const noImages = isEmpty(validImages) || isNil(validImages);
  const noVideo = isEmpty(getVideo(videos)) || isNil(getVideo(videos));

  return (
    <div className={classes.root}>
      {noImages && noVideo ? (
        <NoImage label={noImageLabel} loading={loading} error={error} />
      ) : (
        <ImageLightboxV2
          images={getImageAndFallbacks(images)}
          video={getVideo(videos)}
          license={license}
        />
      )}
    </div>
  );
};

ImageSectionV2.propTypes = {
  classes: PropTypes.object.isRequired,
  images: PropTypes.array.isRequired
};

export default withStyles(styles)(ImageSectionV2);
