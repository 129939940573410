import React, { useState, useRef } from 'react';
import { IKUpload } from 'imagekitio-react';
import {
  Grid,
  IconButton,
  Typography,
  Paper,
  CircularProgress
} from '@material-ui/core';
import { CloudUpload, Delete } from '@material-ui/icons';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const ImageKitFileUpload = ({
  maxSizeMB = null,
  icon = <CloudUpload fontSize="large" />,
  text = 'לחץ כאן להעלאת תמונה',
  helpText = null,
  onUploadSuccess = () => {},
  onImageChange = () => {},
  initialImage = null,
  folder = null,
  required = false
}) => {
  const [uploadedImage, setUploadedImage] = useState(initialImage);
  const [uploadError, setUploadError] = useState(null);
  const [uploading, setUploading] = useState(false);
  const ikUploadRef = useRef(null);
  const { token: userToken } = useFirebaseToken();

  const handleUploadSuccess = (response) => {
    const { url } = response;

    setUploadedImage(url);
    setUploadError(null);
    setUploading(false);
    onUploadSuccess(url);
    onImageChange(url);
  };

  const handleUploadFailure = (error) => {
    setUploadError('שגיאה בהעלאת תמונה. אנא נסו שוב.');
    console.error('Image upload failed:', error);
    setUploading(false);
  };

  const handleRemoveImage = () => {
    setUploadedImage(null);
    onUploadSuccess(null);
    onImageChange();
  };

  const authenticator = async () => {
    try {
      const response = await fetch(
        process.env.REACT_APP_IMAGEKIT_AUTH_ENDPOINT,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${userToken}`
          }
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(
          `Request failed with status ${response.status}: ${errorText}`
        );
      }

      const data = await response.json();
      const { signature, expire, token } = data;
      return { signature, expire, token };
    } catch (error) {
      throw new Error(`Authentication request failed: ${error.message}`);
    }
  };

  const validateFile = (file) => {
    // Check if file type is an image
    const imageTypes = [
      'image/jpeg',
      'image/png',
      'image/gif',
      'image/svg+xml'
    ];

    if (!imageTypes.includes(file.type)) {
      setUploadError('ניתן לעלות רק קבצי תמונות.');
      return false;
    }

    if (!maxSizeMB) return true;

    const maxSizeBytes = maxSizeMB * 1024 * 1024;
    if (file.size > maxSizeBytes) {
      setUploadError(`ניתן להעלות תמונה בגודל ע ${maxSizeMB} MB.`);
      return false;
    }

    setUploadError(null);
    return true;
  };

  const handleUploadProgress = (progress) => {
    setUploading(progress !== 100);
  };

  return (
    <div id="upload-box">
      {!uploadedImage && (
        <div>
          <IKUpload
            publicKey={process.env.REACT_APP_IMAGEKIT_PUBLIC_KEY}
            urlEndpoint={process.env.REACT_APP_IMAGEKIT_URL}
            authenticator={authenticator}
            folder={folder}
            disabled={uploading}
            validateFile={validateFile}
            onError={handleUploadFailure}
            onSuccess={handleUploadSuccess}
            onUploadProgress={handleUploadProgress}
            style={{ display: 'none' }}
            ref={ikUploadRef}
          />
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            style={{
              height: '200px',
              border: required ? '1px solid red' : '2px solid #ccc',
              marginBottom: '10px'
            }}
            onClick={() => ikUploadRef.current.click()}
          >
            {uploading ? <CircularProgress /> : <IconButton>{icon}</IconButton>}
            <Typography variant="subtitle1">
              {uploading ? 'מעלה תמונה...' : text}
            </Typography>
          </Grid>
          <Paper style={{ backgroundColor: '#F5F5F5', padding: '10px' }}>
            {helpText && (
              <Typography variant="body2" color="textSecondary">
                {helpText}
              </Typography>
            )}
          </Paper>
        </div>
      )}
      {uploadedImage && (
        <div style={{ position: 'relative', textAlign: 'center' }}>
          <img
            src={uploadedImage}
            alt="Uploaded"
            style={{
              maxWidth: `650px`,
              maxHeight: `300px`,
              width: 'auto',
              height: 'auto'
            }}
          />
          <IconButton
            style={{ position: 'absolute', top: '5px', right: '5px' }}
            onClick={handleRemoveImage}
          >
            <Delete />
          </IconButton>
        </div>
      )}

      {uploadError && <p style={{ color: 'red' }}>{uploadError}</p>}
      {required && (
        <p
          style={{
            color: 'red',
            fontSize: '0.75rem',
            marginLeft: '14px',
            marginRight: '14px',
            fontFamily: "'Noto Sans Hebrew', 'Noto Sans', sans-serif"
          }}
        >
          שדה זה חובה
        </p>
      )}
    </div>
  );
};

export default ImageKitFileUpload;
