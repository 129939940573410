import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import jsonExport from 'jsonexport/dist';
/* eslint-disable no-param-reassign */
import React from 'react';
import {
  BooleanField,
  Datagrid,
  downloadCSV,
  EditButton,
  fetchRelatedRecords,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  useDataProvider,
  usePermissions
} from 'react-admin';

import useCitiesForAutoComplete from '../../utils/hooks/useCitiesForAutoComplete';
import AgencyBranchFilter from './AgencyBranchFilter';
import AgencyBranchListActions from './AgencyBranchListActions';

const allFields = [
  'nodeId',
  'id',
  'agencyId',
  'team',
  'representative',
  'implementer',
  'existingCity',
  'areaName',
  'city',
  'district',
  'longitude',
  'latitude',
  'address',
  'expose',
  'indexPage',
  'isTradein',
  'isCarlandBranch',
  'isMoked',
  'showInFbCatalog',
  'displayCustomerVirtualPhone',
  'manualScoreBooster',
  'virtualPhone',
  'allowStatistics',
  'implementerUid',
  'representativeUid',
  'fundingAdvisorUid',
  'fundingAdvisor',
  'areaManager',
  'areaManagerUid'
];

const AgenciesBranchList = (props) => {
  const { cities } = useCitiesForAutoComplete();

  const dataProvider = useDataProvider();

  const exporter = (agenciesBranches) => {
    fetchRelatedRecords(dataProvider)(
      agenciesBranches,
      'agencyId',
      'Agency'
    ).then((agencies) => {
      const agenciesBranchesForExport = agenciesBranches.map(
        ({ agencyId, originalId, ...branchRecord }) => {
          const found = cities.find((city) => city.name === branchRecord.city);
          // delete redundant columns for export
          delete branchRecord.nodeId;
          delete branchRecord.id;
          return {
            ...branchRecord,
            agencyId: agencies[agencyId].id,
            agencyBranchId: originalId,
            'branch name': agencies[agencyId].displayName,
            'existing city': found ? 'TRUE' : 'FALSE'
          };
        }
      );
      jsonExport(
        agenciesBranchesForExport,
        {
          headers: ['agencyId', 'agencyBranchId', 'branch name']
        },
        (err, csv) => {
          downloadCSV(csv, 'agenciesBranches');
        }
      );
    });
  };

  return (
    <List
      {...props}
      filters={<AgencyBranchFilter />}
      // exporter={exporter}
      sort={{ field: 'agencyId', order: 'ASC' }}
      bulkActionButtons={false}
      actions={<AgencyBranchListActions exporter={exporter} />}
      perPage={25}
      filter={{
        specificFields: allFields
      }}
    >
      <Datagrid rowClick="edit">
        <TextField source="originalId" label="מזהה" />
        <ReferenceField
          source="agencyId"
          reference="Agency"
          link={false}
          label="סוכנות"
        >
          <TextField label="סוכנות" source="displayName" />
        </ReferenceField>
        <TextField source="areaName" label="שם סניף" />
        <TextField source="city" label="עיר" />
        <TextField source="address" label="כתובת" />
        <TextField source="team" label="צוות מוקד" />
        <TextField source="representative" label="נציג מוקד" />
        <TextField source="fundingAdvisor" label="יועץ מימון" />
        <TextField source="areaManager" label="מנהל אזור" />
        <NumberField source="manualScoreBooster" label="פקטור לדירוג מודעה" />

        <FunctionField
          label="טלפון וירטואלי"
          source="virtualPhone"
          cellClassName="important-cell"
          sortable={false}
          render={(r) => {
            return r.virtualPhone ? (
              <CheckIcon fontSize="small" />
            ) : (
              <CloseIcon fontSize="small" />
            );
          }}
        />

        {/* <TextField source="virtualPhone" label="טלפון וירטואלי" /> */}

        <BooleanField source="expose" label="סניף פעיל" />
        <BooleanField source="isCarlandBranch" label="סניף קארלנד" />
        <BooleanField source="showInFbCatalog" label="הצגה ברשתות חברתיות" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default AgenciesBranchList;
