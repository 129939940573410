import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useTeamsForAutoComplete = (textOnly = false) => {
  const [teams, setTeams] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchTeams = async () => {
    const { data } = await dataProvider(GET_LIST, 'Team', {
      filter: {
        specificFields: ['nodeId', 'id', 'name']
      },
      sort: { field: 'name', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedTeams = pipe(
      map((team) => ({
        id: prop(textOnly ? 'name' : 'originalId', team),
        name: `${prop('name', team)}`,
        teamId: prop('originalId', team)
      })),
      filter(prop('id'))
    )(data);

    setTeams([...mappedTeams]);
  };

  useEffect(() => {
    fetchTeams();
  }, [version]);

  return {
    teams
  };
};

export default useTeamsForAutoComplete;
