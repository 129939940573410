import { path } from 'ramda';
import { graphqlServiceRequest } from '../../bitComponents/utils';
import { districtsQuery } from './queries';
import { API_URL } from '../../constants/constants';

// eslint-disable-next-line import/prefer-default-export
export const fetchDistricts = async (token) => {
  const result = await graphqlServiceRequest(token, districtsQuery, API_URL);

  const districstRes = path(['data', 'districts', 'nodes'], result);

  return districstRes || [];
};
