import React from 'react';

import useEditor from './useEditor';
import Form from './Form';

/** @type {import('react').FunctionComponent<EditorProps>} */
const Editor = ({ feature, title }) => {
  const { loading, formValue, save, saving } = useEditor(feature);

  return (
    <>
      {!loading && (
        <Form
          title={title}
          value={formValue}
          feature={feature}
          onSubmit={save}
          saving={saving}
        />
      )}
    </>
  );
};

export default Editor;

/**
 * @typedef EditorProps
 * @property {string} feature
 * @property {string} title
 */
