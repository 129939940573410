import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Divider,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import RemoveDialog from './RemoveDialog';
import { COLOR_CHOICES, SIZE_CHOICES, WEIGHT_CHOICES } from '../constants';

const CarouselDrawer = ({
  onClose = () => {},
  onSave = () => {},
  data = {},
  uid,
  title = 'קרוסלת רכבים'
}) => {
  const [startDate, setStartDate] = useState(data.startDate || '');
  const [endDate, setEndDate] = useState(data.endDate || '');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState(data.status || null);
  const [campaignName, setCampaignName] = useState(data.campaignName || '');
  const [header, setHeader] = useState(data.header || '');
  const [headerWeight, setHeaderWeight] = useState(data.headerWeight || '');
  const [headerSize, setHeaderSize] = useState(data.headerSize || '');
  const [headerColor, setHeaderColor] = useState(data.headerColor || '');
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});

  const useStyles = makeStyles({
    errorMessage: {
      color: 'red',
      fontSize: '0.75rem',
      marginLeft: '14px',
      marginRight: '14px',
      fontFamily: "'Noto Sans Hebrew', 'Noto Sans', sans-serif"
    }
  });

  const classes = useStyles();

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];

    if (!startDate || !endDate || startDate > today || today > endDate) {
      setStatus('inactive');
      setIsStatusDisabled(true);
    } else {
      setIsStatusDisabled(false);
    }
  }, [startDate, endDate]);

  const modified = { ...data };

  const handleSave = (clear = false) => {
    if (clear) {
      Object.keys(modified).forEach((key) => {
        modified[key] = null;
      });

      modified.creatingUid = uid;
    } else {
      modified.startDate = startDate;
      modified.endDate = endDate;
      modified.creatingUid = uid;
      modified.status = status;
      modified.header = header;
      modified.campaignName = campaignName;
      modified.headerColor = headerColor;
      modified.headerSize = headerSize;
      modified.headerWeight = headerWeight;
    }

    modified.location = 'HOME';
    modified.sourceTable = 'Carrousel';

    onSave(modified);
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
    setDirty(true);
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    setDirty(true);
  };

  const areRequiredFieldsFilled = () => {
    return (
      !!startDate &&
      !!endDate &&
      !!campaignName &&
      !!header &&
      !!headerColor &&
      !!headerWeight &&
      !!headerSize
    );
  };

  const hasSomeData = () => {
    // Check if all required fields have values
    return (
      startDate ||
      endDate ||
      campaignName ||
      (header && headerColor && headerWeight && headerSize)
    );
  };

  return (
    <div style={{ width: 700, padding: 16 }}>
      <IconButton
        style={{ position: 'absolute', top: 8, left: 8 }}
        onClick={() => onClose(dirty)}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="h6"
        style={{ marginTop: 4, marginBottom: 8, color: '#519FAB' }}
      >
        {title}
      </Typography>

      <Divider style={{ marginBottom: 16 }} />

      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}
      >
        {/* Switch input */}
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="body1"
            style={{ marginLeft: 16, fontWeight: 'bold' }}
          >
            סטטוס
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={data.status === 'active'}
                checked={status === 'active'}
                name="status"
                color="primary"
                disabled={isStatusDisabled}
                onChange={(ev) => {
                  setStatus(ev.currentTarget.checked ? 'active' : 'inactive');
                  setDirty(true);
                }}
              />
            }
            label="פעיל"
          />
        </Grid>
        {/* Status label */}
      </Grid>

      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך התחלה"
            type="date"
            defaultValue={startDate}
            onChange={(ev) => {
              handleStartDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: { min: new Date().toISOString().split('T')[0] }
            }}
            error={errors.startDate}
            helperText={errors.startDate && 'שדה זה חובה'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך סיום"
            type="date"
            defaultValue={endDate}
            onChange={(ev) => {
              handleEndDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: {
                min: startDate || new Date().toISOString().split('T')[0]
              }
            }}
            error={errors.endDate}
            helperText={errors.endDate && 'שדה זה חובה'}
          />
        </Grid>
      </Grid>

      {endDate && startDate && endDate < startDate && (
        <p style={{ color: 'red' }}>
          לא ניתן להגדיר תאריך סיום שקטן מתאריך ההתחלה
        </p>
      )}

      <TextField
        required
        label="שם קמפיין"
        defaultValue={campaignName}
        onBlur={(e) => setErrors({ campaignName: !e.target.value })}
        onChange={(e) => {
          setCampaignName(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        error={errors.campaignName}
        helperText={errors.campaignName && 'שדה זה חובה'}
      />

      <TextField
        required
        label="כותרת"
        defaultValue={header}
        onBlur={(e) => setErrors({ header: !e.target.value })}
        onChange={(e) => {
          setHeader(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        error={errors.header}
        helperText={errors.header && 'שדה זה חובה'}
      />

      <FormControl
        fullWidth
        style={{ marginBottom: 16 }}
        error={errors.headerColor}
      >
        <InputLabel style={{ right: '10px' }}>צבע כותרת *</InputLabel>
        <Select
          required
          onBlur={(e) => setErrors({ headerColor: !e.target.value })}
          defaultValue={headerColor}
          onChange={(e) => {
            setHeaderColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {errors.headerColor && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )}
      </FormControl>

      <FormControl
        fullWidth
        style={{ marginBottom: 16 }}
        error={errors.headerWeight}
      >
        <InputLabel style={{ right: '10px' }}>משקל כותרת *</InputLabel>
        <Select
          required
          onBlur={(e) => setErrors({ headerWeight: !e.target.value })}
          defaultValue={headerWeight}
          onChange={(e) => {
            setHeaderWeight(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {WEIGHT_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {errors.headerWeight && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )}
      </FormControl>

      <FormControl
        fullWidth
        style={{ marginBottom: 16 }}
        error={errors.headerSize}
      >
        <InputLabel style={{ right: '10px' }}>גודל כותרת *</InputLabel>
        <Select
          required
          onBlur={(e) => setErrors({ headerSize: !e.target.value })}
          defaultValue={headerSize}
          onChange={(e) => {
            setHeaderSize(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {SIZE_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
        {errors.headerSize && (
          <p className={classes.errorMessage}>שדה זה חובה</p>
        )}
      </FormControl>

      <div
        style={{
          position: 'absolute',
          bottom: '16px',
          width: '100%',
          textAlign: 'center'
        }}
      >
        <Button
          disabled={!hasSomeData()}
          variant="outlined"
          onClick={() => setDialogOpen(true)}
          style={{
            marginLeft: 16,
            width: '40%',
            color: hasSomeData() ? 'red' : 'rgba(0, 0, 0, 0.26)',
            backgroundColor: hasSomeData() ? '' : 'rgba(0, 0, 0, 0.12)',
            border: hasSomeData() ? '1px solid red' : '1px solid lightgray'
          }}
        >
          הסרה
        </Button>

        <Button
          disabled={!dirty || !areRequiredFieldsFilled()}
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          style={{ width: '40%' }}
        >
          שמירה
        </Button>
      </div>

      <RemoveDialog
        onConfirm={() => handleSave(true)}
        open={isDialogOpen}
        onCancel={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default CarouselDrawer;
