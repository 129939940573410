import { useEffect, useState } from 'react';
import { GET_ONE, useDataProvider, useVersion } from 'react-admin';

const useSubscriber = (nodeId) => {
  const [subscriber, setSubscriber] = useState();
  const version = useVersion();
  const [loading, setLoading] = useState(false);
  const dataProvider = useDataProvider();

  // eslint-disable-next-line no-shadow
  const fetchSubscriber = async (nodeId) => {
    setLoading(true);
    const { data } = await dataProvider(GET_ONE, 'Subscriber', {
      specificFields: [
        'sid',
        'uid',
        'email',
        'phone',
        'createdAt',
        'unsubscribeEmail',
        'unsubscribePhone',
        'whatsappRecommendations',
        'nodeId',
        'id'
      ],
      id: nodeId
    });
    setLoading(false);
    setSubscriber(data);
  };

  useEffect(() => {
    if (nodeId) {
      fetchSubscriber(nodeId);
    }
  }, [version, nodeId]);

  return {
    subscriber,
    loading
  };
};

export default useSubscriber;
