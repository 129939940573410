import { useCallback } from 'react';
import API from './api';
import './types';
import { checkHoursEqual } from './helpers';

const useApi = (feature) => {
  /** @type {() => Promise<FormValue>} */
  const getFormValue = useCallback(async () => {
    const api = API(feature);
    const hours = await api.fetchHours();
    const active = await api.getActive();
    return { hours, active };
  }, []);

  /**
   * @type {(current: FormValue, updated: FormValue) => Promise<FormValue>}
   */
  const updateFormValue = useCallback(async (current, updated) => {
    const api = API(feature);
    const hoursAreEqual = checkHoursEqual(current.hours, updated.hours);
    const hours = !hoursAreEqual
      ? await api.updateWorkingHours(current.hours, updated.hours)
      : current.hours;

    if (current.active !== updated.active) {
      await api.setActive(updated.active);
    }

    return {
      active: updated.active,
      hours
    };
  }, []);

  return {
    getFormValue,
    updateFormValue
  };
};

export default useApi;
