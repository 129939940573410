import React from 'react';
import { prop, path, length } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import CreateIcon from '@material-ui/icons/Create';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(1, 1, 2, 1)
  },
  dayName: {
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(1)
    }
  },
  workingHoursList: {
    paddingRight: theme.spacing(1)
  }
}));

const DayItem = ({
  day,
  toggleDefaultHours,
  toggleDayIsOpen,
  addHoursRange,
  removeHoursRange,
  children
}) => {
  const classes = useStyles();

  return (
    <Paper>
      <Grid
        container
        alignItems="center"
        className={prop('container', classes)}
      >
        <Grid item xs={1} md={1}>
          <Checkbox
            disabled={prop('enName', day) === 'default'}
            checked={prop('isDefaultHours', day)}
            color="primary"
            onChange={(e) => {
              toggleDefaultHours(
                prop('enName', day),
                path(['target', 'checked'], e)
              );
            }}
          />
        </Grid>
        <Grid item xs={2} md={1}>
          <Typography className={prop('dayName', classes)}>
            {prop('heName', day)}
          </Typography>
        </Grid>
        <Grid item xs={6} md={7} className={prop('workingHoursList', classes)}>
          {children}
        </Grid>

        <Grid item xs={1} md={1}>
          {prop('workingHours', day) ? (
            <IconButton color="primary" onClick={() => toggleDayIsOpen(day)}>
              <HighlightOffIcon />
            </IconButton>
          ) : (
            <IconButton color="primary" onClick={() => toggleDayIsOpen(day)}>
              <CreateIcon />
            </IconButton>
          )}
        </Grid>
        {prop('workingHours', day) && (
          <Grid
            item
            xs={1}
            md={1}
            onClick={() => addHoursRange(prop('enName', day))}
          >
            <IconButton color="primary">
              <AddIcon />
            </IconButton>
          </Grid>
        )}
        {prop('workingHours', day) && length(prop('workingHours', day)) > 1 && (
          <Grid
            item
            xs={1}
            md={1}
            onClick={() => removeHoursRange(prop('enName', day))}
          >
            <IconButton color="primary">
              <RemoveIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default DayItem;
