import { UI_URL } from '../../constants/constants';

// eslint-disable-next-line import/prefer-default-export
export async function cacheUrls(token, pageSize, page, filter) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  });
  const response = await fetch(
    `${UI_URL}/api/cache/urls?pageSize=${pageSize}&page=${page}&filter=${
      filter || '/'
    }*`,
    {
      method: 'GET',
      headers
    }
  );
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
  const res = await response.json();
  return res;
}

export async function clearCache(token, body = null) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  });

  const options = {
    method: 'POST',
    headers
  };

  if (body) {
    options.body = JSON.stringify(body);
  }

  const response = await fetch(`${UI_URL}/api/cache/clear`, options);

  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
}

export async function revalidateUrls(token, urls) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  });
  const response = await fetch(`${UI_URL}/api/cache/revalidate`, {
    method: 'POST',
    headers,
    body: JSON.stringify({ urls })
  });
  if (!response.ok) {
    throw new Error(`HTTP error! Status: ${response.status}`);
  }
}
