import CircularProgress from '@material-ui/core/CircularProgress';
import UserPropsIcon from '@material-ui/icons/Accessible';
import NotificationTypeIcon from '@material-ui/icons/AddAlert';
import VersionPageMappingIcon from '@material-ui/icons/Commute';
import FeaturedModelIcon from '@material-ui/icons/DriveEta';
import FilterableInsightIcon from '@material-ui/icons/EmojiObjects';
import ErrorIcon from '@material-ui/icons/Error';
import FormatListNumberedIcon from '@material-ui/icons/FormatListNumbered';
import UserIcon from '@material-ui/icons/Group';
import NotificationCategoryIcon from '@material-ui/icons/Notifications';
import AgentIcon from '@material-ui/icons/RecordVoiceOver';
import FilterNoneIcon from '@material-ui/icons/FilterNone';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import ViewListIcon from '@material-ui/icons/ViewList';
import HiddenPostIcon from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { Admin, Resource } from 'react-admin';

import LoginPage from './authUtils/LoginPage';
import authProvider from './authUtils/authProvider';
import RTL from './bitComponents/rtl';
import Menu from './components/Menu';
import theme from './components/theme';
import { API_URL } from './constants/constants';
import Layout from './custom-ra/Layout';
import Logo from './images/logo.png';
import labels from './labels';
import { i18nProvider } from './locale';
import agencies from './resources/AgenciesNew';
import agencyBranches from './resources/AgencyBranches';
import { AgentCreate, AgentEdit, AgentsList } from './resources/Agents';
import cities from './resources/Cities';
import districts from './resources/Districts';
import loginTestUsers from './resources/LoginTestUsers';
import {
  FeaturedModelsCreate,
  FeaturedModelsEdit,
  FeaturedModelsList
} from './resources/FeaturedModels';
import {
  FilterableInsightCreate,
  FilterableInsightsEdit,
  FilterableInsightsList
} from './resources/FilterableInsights';
import FundingCalculatorVariables from './resources/FundingCalculatorVariables';
import {
  InteractionTypesCreate,
  InteractionTypesEdit,
  InteractionTypesList
} from './resources/InteractionType';
import {
  InteractionTypesSecondaryStatusCreate,
  InteractionTypesSecondaryStatusEdit,
  InteractionTypesSecondaryStatusList
} from './resources/InteractionTypeSecondaryStatus';
import {
  ChatWorkingHourResource,
  ContactWorkingHourResource
} from './resources/MokedWorkingHours';
import {
  NotificationCategoryCreate,
  NotificationCategoryEdit,
  NotificationCategorysList
} from './resources/Notifications/notificationCategories';
import {
  NotificationTypeCreate,
  NotificationTypeEdit,
  NotificationTypesList
} from './resources/Notifications/notificationTypes';
import shortLinks from './resources/ShortLinks';
import { SubscribersEdit, SubscribersList } from './resources/Subscribers';
import TeamsCreate from './resources/Teams/TeamsCreate';
import TeamsEdit from './resources/Teams/TeamsEdit';
import TeamsList from './resources/Teams/TeamsList';
import { UserCreate, UsersEdit, UsersList } from './resources/Users';
import {
  VersionPageMappingCreate,
  VersionPageMappingsEdit,
  VersionPageMappingsList
} from './resources/VersionPageMappings';
import { HiddenPostCreate, HiddenPostList } from './resources/hiddenPosts';
import UploadAttemptsEdit from './resources/uploadAttempts/UploadAttemptsEdit';
import UploadAttemptsList from './resources/uploadAttempts/UploadAttemptsList';
import {
  UserPropTypeCreate,
  UserPropTypeEdit,
  UserPropTypeList
} from './resources/userPropTypes';
import buildGraphQLProvider from './shared-components/ra-data-postgraphile';
import { buildQuery } from './shared-components/ra-data-postgraphile/buildQuery';

import './App.css';
import SalesForceIdentifiersResource from './resources/SalesForceIdentifiers';
import UICache from './resources/UICache';
import CatalogJato from './resources/CatalogJato';
import CarNameManagement from './resources/CarNameManagement';
import AuditLogs from './resources/AuditLogs';
import {
  InteractionTypeGroup,
  InteractionTypeGroupCreate,
  InteractionTypeGroupEdit
} from './resources/InteractionTypeGroups';
import DynamicContent from './resources/DynamicContent';
import { dataProviderWrapper } from './utils/dataProviderWrapper';

class App extends Component {
  constructor() {
    super();
    const auth = authProvider();
    this.state = {
      dataProvider: dataProviderWrapper,
      authProvider: auth.provider,
      loading: true
    };
    this.auth = auth;
  }

  componentDidMount() {
    this.auth.firebaseLoaded().then(() => {
      this.setState({ loading: false });
    });
  }

  // TODO: add global utils with enums of our backend
  render() {
    const { dataProvider, authProvider, loading } = this.state;

    if (loading) {
      return (
        <div>
          <div className="logoContainer">
            <img className="logo" src={Logo} alt="logo" />
          </div>
          <div className="loaderContainer">
            <CircularProgress size={80} className="circularProgress" />
          </div>
        </div>
      );
    }

    return (
      <RTL>
        {/* <ErrorBoundary fallback={<>oops, something went wrong!</>}> */}
        <Admin
          locale="he"
          i18nProvider={i18nProvider}
          theme={theme}
          authProvider={authProvider}
          dataProvider={dataProvider}
          loginPage={LoginPage}
          appLayout={Layout}
          menu={Menu}
        >
          {(permission) =>
            permission
              ? [
                  <Resource
                    icon={UserIcon}
                    name="User"
                    options={{ label: labels.users }}
                    list={UsersList}
                    edit={UsersEdit}
                    create={UserCreate}
                  />,
                  <Resource
                    icon={AgentIcon}
                    name="Agent"
                    options={{ label: labels.agents }}
                    list={AgentsList}
                    edit={AgentEdit}
                    create={AgentCreate}
                  />,
                  <Resource
                    name="Agency"
                    options={{ label: labels.agencies }}
                    {...agencies}
                  />,
                  <Resource
                    name="AgencyBranch"
                    options={{ label: labels.agencyBranches }}
                    {...agencyBranches}
                  />,
                  <Resource {...AuditLogs} />,
                  // <Resource {...HeroContent} />,
                  <Resource {...DynamicContent} />,
                  <Resource
                    name="TeamsView"
                    icon={SupervisedUserCircleIcon}
                    list={TeamsList}
                    create={TeamsCreate}
                    edit={TeamsEdit}
                    options={{ label: labels.teams }}
                  />,
                  <Resource
                    name="UploadAttemptsView"
                    icon={ErrorIcon}
                    list={UploadAttemptsList}
                    edit={UploadAttemptsEdit}
                    options={{ label: labels.uploadAttempts }}
                  />,
                  // <Resource
                  //   name="CampaignsView"
                  //   icon={ImportantDevicesIcon}
                  //   create={CampaignCreate}
                  //   edit={CampaignEdit}
                  //   list={CampaignsList}
                  //   options={{ label: labels.campaigns }}
                  // />,
                  <Resource
                    name="ShortLink"
                    options={{ label: labels.shortLinks }}
                    {...shortLinks}
                  />,
                  <Resource
                    name="HiddenPost"
                    list={HiddenPostList}
                    icon={HiddenPostIcon}
                    create={HiddenPostCreate}
                    options={{ label: labels.hiddenPosts }}
                  />,

                  <Resource
                    name="UserPropType"
                    list={UserPropTypeList}
                    icon={UserPropsIcon}
                    create={UserPropTypeCreate}
                    edit={UserPropTypeEdit}
                    options={{ label: labels.userProps }}
                  />,
                  <Resource name="CarNameManagement" {...CarNameManagement} />,
                  <Resource
                    icon={FilterableInsightIcon}
                    name="FilterableInsight"
                    options={{ label: labels.filterableInsight }}
                    list={FilterableInsightsList}
                    edit={FilterableInsightsEdit}
                    create={FilterableInsightCreate}
                  />,
                  <Resource
                    icon={VersionPageMappingIcon}
                    name="VersionPageMapping"
                    options={{ label: labels.versionPageMapping }}
                    list={VersionPageMappingsList}
                    edit={VersionPageMappingsEdit}
                    create={VersionPageMappingCreate}
                  />,
                  <Resource
                    name="NotificationCategory"
                    list={NotificationCategorysList}
                    icon={NotificationCategoryIcon}
                    create={NotificationCategoryCreate}
                    edit={NotificationCategoryEdit}
                    options={{ label: labels.notificationCategories }}
                  />,
                  <Resource
                    name="NotificationType"
                    list={NotificationTypesList}
                    icon={NotificationTypeIcon}
                    create={NotificationTypeCreate}
                    edit={NotificationTypeEdit}
                    options={{ label: labels.notificationTypes }}
                  />,
                  // <Resource
                  //   name="Experiment"
                  //   list={ExperimentsList}
                  //   icon={NotificationTypeIcon}
                  //   create={ExperimentsCreate}
                  //   edit={ExperimentsEdit}
                  //   options={{ label: labels.experiments }}
                  // />,
                  <Resource
                    name="FeaturedModel"
                    list={FeaturedModelsList}
                    icon={FeaturedModelIcon}
                    create={FeaturedModelsCreate}
                    edit={FeaturedModelsEdit}
                    options={{ label: labels.featuredModels }}
                  />,
                  <Resource name="UserPropCurrent" />,
                  <Resource
                    icon={UserIcon}
                    name="Subscriber"
                    options={{ label: labels.subscriber }}
                    list={SubscribersList}
                    edit={SubscribersEdit}
                  />,
                  SalesForceIdentifiersResource,
                  <Resource
                    name="InteractionType"
                    icon={ViewListIcon}
                    list={InteractionTypesList}
                    edit={InteractionTypesEdit}
                    create={InteractionTypesCreate}
                    options={{ label: labels.interactionTypes }}
                  />,
                  <Resource
                    name="InteractionTypeSecondaryStatus"
                    icon={FormatListNumberedIcon}
                    list={InteractionTypesSecondaryStatusList}
                    edit={InteractionTypesSecondaryStatusEdit}
                    create={InteractionTypesSecondaryStatusCreate}
                    options={{
                      label: labels.interactionTypesSecondaryStatus
                    }}
                  />,
                  <Resource
                    name="InteractionTypeGroup"
                    icon={FilterNoneIcon}
                    list={InteractionTypeGroup}
                    edit={InteractionTypeGroupEdit}
                    create={InteractionTypeGroupCreate}
                    options={{
                      label: labels.interactionTypeGroups
                    }}
                  />,
                  <Resource {...FundingCalculatorVariables} />,
                  ContactWorkingHourResource,
                  ChatWorkingHourResource,

                  <Resource name="ProPermission" />,
                  <Resource name="City" />,
                  <Resource
                    name="CityLocation" // this holds the table name but as a single : CityLocations -> CityLocation
                    options={{ label: labels.cities }}
                    {...cities}
                  />,
                  <Resource
                    name="District" // this holds the table name but as a single : CityLocations -> CityLocation
                    options={{ label: labels.districts }}
                    {...districts}
                  />,
                  // <Resource
                  //   name="LoginTestUser"
                  //   options={{ label: labels.loginTestUsers }}
                  //   {...loginTestUsers}
                  // />,
                  <Resource {...UICache} />,
                  <Resource {...CatalogJato} />
                ]
              : []
          }
        </Admin>
        {/* </ErrorBoundary> */}
      </RTL>
    );
  }
}

export default App;
