import React from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
  useTranslate
} from 'react-admin';
import TeamsDeleteButton from './TeamsDeleteButton';

const TeamsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" label="Team" />
  </Filter>
);

const TeamsList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      sort={{ field: 'teamId', order: 'ASC' }}
      filters={<TeamsFilter />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid size="small">
        <NumberField source="teamId" label="ID" />
        <TextField
          source="name"
          label={translate('ra.resources.teams.team_name')}
        />
        <NumberField
          source="totalUsers"
          label={translate('ra.resources.teams.total_users')}
        />
        <NumberField
          source="totalAgencyBranches"
          label={translate('ra.resources.teams.total_branches')}
        />
        <EditButton />
        <TeamsDeleteButton />
      </Datagrid>
    </List>
  );
};

export default TeamsList;
