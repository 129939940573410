import React from 'react';
import { Edit } from 'react-admin';
import ShortLinksMutation from './ShortLinksMutation';

const ShortLinksEdit = (props) => (
  <Edit {...props}>
    <ShortLinksMutation {...props} />
  </Edit>
);

export default ShortLinksEdit;
