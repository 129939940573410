export default {
  labelIdle:
    'גררו קבצים או <span class="filepond--label-action"> פתחו קובץ </span>',
  labelInvalidField: 'קבצים לא חוקיים',
  labelFileWaitingForSize: 'מחכה לקבלת גודל',
  labelFileSizeNotAvailable: 'לא ניתן לדעת מה גודל הקובץ',
  labelFileLoading: 'טוען',
  labelFileLoadError: 'שגיאה בטעינה',
  labelFileProcessing: 'העלאה בביצוע',
  labelFileProcessingComplete: 'העלאה הסתיימה בהצלחה',
  labelFileProcessingAborted: 'העלאה בוטלה',
  labelFileProcessingError: 'שגיאה בהעלאה',
  labelFileProcessingRevertError: 'שגיאה בביטול העלאה',
  labelFileRemoveError: 'שגיאה במחיקת התמונה',
  labelTapToCancel: 'לחץ לביטול',
  labelTapToRetry: 'נסה שנית',
  labelTapToUndo: 'בטל פעולה',
  labelButtonRemoveItem: 'מחק',
  labelButtonAbortItemLoad: 'ביטול',
  labelButtonRetryItemLoad: 'נסה שנית',
  labelButtonAbortItemProcessing: 'ביטול',
  labelButtonUndoItemProcessing: 'ביטול',
  labelButtonRetryItemProcessing: 'נסה שנית',
  labelButtonProcessItem: 'טוען',
  submitText: 'אישור',
  labelFileTypeNotAllowed: 'סוג הקובץ אינו תקין',
  labelMaxFileSizeExceeded: 'גודל חורג מהמותר',
  labelMaxFileSize: '{filesize} גודל הקובץ המקסימאלי הוא ',
  fileValidateTypeLabelExpectedTypes: ' בלבד {allTypes} קבצי',
  requiredMsg: 'חובה לעלות קובץ אחד לפחות',
  maxFilesMessage: (files) =>
    files === 1 ? 'ניתן להעלות קובץ אחד בלבד' : `ניתן להעלות עד ${files} קבצים`
};
