import { map, path } from 'ramda';
import React, { useState } from 'react';
import {
  Create,
  required,
  SimpleForm,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

import { API_URL } from '../../constants/constants';
import { buildCreateTeamQuery } from './queries';
import TeamsToolbar from './TeamsToolbar';
import { mapError } from '../../utils/utils';

const TeamsCreate = ({ initialValues, ...props }) => {
  const [teamName, setTeamName] = useState();
  const { token } = useFirebaseToken();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const refresh = useRefresh();

  const create = async () => {
    if (!teamName || !token) return;

    try {
      const result = await graphqlServiceRequest(
        token,
        buildCreateTeamQuery(teamName),
        API_URL
      );

      const nodeId = path(['data', 'createTeam', 'team', 'nodeId'], result);
      const errors = path(['errors'], result);

      if (nodeId) {
        notify(translate('ra.resources.teams.add_success'), 'success');
        redirect(`/TeamsView/`);
        refresh();
      }

      if (errors) {
        const errMessage = mapError(errors);

        if (errMessage.includes('duplicate key')) {
          notify(translate('ra.resources.teams.team_name_exists'), 'error');
        } else {
          notify(errMessage, 'error');
        }
      }
    } catch (err) {
      console.log(err.message);
      notify(translate('ra.page.error'), 'error');
    }
  };

  return (
    <Create {...props}>
      <SimpleForm
        {...props}
        initialValues={initialValues}
        toolbar={<TeamsToolbar handler={create} />}
      >
        <TextInput disabled label="ID" source="teamId" />
        <TextInput
          label="Team Name"
          source="name"
          validate={required()}
          onChange={(e) => setTeamName(e.target.value)}
        />
      </SimpleForm>
    </Create>
  );
};

export default TeamsCreate;
