import React from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  captionRoot: { alignItems: 'flex-start' },
  captionLabel: {
    color: theme.palette.text.primary,
    margin: '6px 0px 8px 0px',
    fontSize: 14,
  },
  error: {
    color: 'red',
  },
}));

const CarwizCheckboxField = ({
  input,
  label,
  meta: { touched, error },
  captionCheckbox,
  children,
  required,
}) => {
  const classes = useStyles();
  const isError = touched && error;
  return (
    <React.Fragment>
      <FormControlLabel
        classes={{
          label: captionCheckbox ? classes.captionLabel : '',
          root: captionCheckbox ? classes.captionRoot : '',
        }}
        error={touched && error}
        control={
          <Checkbox
            classes={{ root: isError ? classes.error : '' }}
            {...input}
            required={required}
            aria-required={required ? 'true' : 'false'}
            color="primary"
          />
        }
        label={
          <React.Fragment>
            {label}
            {children}
          </React.Fragment>
        }
      />
      {isError && (
        <FormHelperText tabIndex={0} error>
          {error}
        </FormHelperText>
      )}
    </React.Fragment>
  );
};

CarwizCheckboxField.defaultProps = {
  meta: {
    touched: false,
    error: null,
  },
};

export default CarwizCheckboxField;
