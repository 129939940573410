export const buildCreateTeamQuery = (newName) =>
  `mutation {
    createTeam(input: {team: {name: "${newName}"}}){
            team {
              nodeId
            }
        }
    }`;

export const buildDeleteTeamQuery = (teamId) =>
  `mutation {
    deleteTeamById(input: {id: ${teamId}}){
            team {
              nodeId
            }
        }
    }`;

export const buildEditTeamQuery = (id, newName) =>
  `mutation {
    updateTeamById(input: {patch: {name: "${newName}"}, id: ${id}}){
            team {
              nodeId
            }
        }
    }`;

export const buildUpdateUserTeamId = (user, teamId) =>
  `mutation {
    updateUser(
    input: {
        uid: "${user.uid}"
        email: ${user.email ? `"${user.email}"` : null}
        ${teamId ? `teamId:${teamId}` : ''}
    }){
        user {
          nodeId
        }
    }
}`;

export const fetchRelevantUsersQuery = () => `
  query getUsers {
    users(orderBy: NAME_ASC, filter: {role: {in: [ADMIN, CARWIZ_AGENT]}}) {
      edges {
        node {
          uid
          name
          email
          team {
            id
            name
          }
        }
      }
    }
  }`;
