import React from 'react';
import { assoc, prop, path } from 'ramda';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import messages from './messages';

const useStyles = makeStyles((theme) => ({
  container: {
    marginBottom: theme.spacing(1)
  },
  openingTime: {
    paddingRight: theme.spacing(1)
  }
}));

const WorkingHoursItem = ({
  enName,
  workingHoursRange,
  workingHoursRangeIndex,
  updateHours
}) => {
  const classes = useStyles();

  return (
    <Grid container className={prop('container', classes)}>
      <Grid item xs={6} className={prop('openingTime', classes)}>
        <TextField
          id="openingTime"
          label={prop('from', messages)}
          type="time"
          value={prop('start', workingHoursRange)}
          fullWidth
          inputProps={{
            step: 60 // 1 min
          }}
          onChange={(e) => {
            updateHours(
              workingHoursRangeIndex,
              assoc('start', path(['target', 'value'], e), workingHoursRange),
              enName
            );
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          id="closingTime"
          label={prop('until', messages)}
          type="time"
          value={prop('end', workingHoursRange)}
          fullWidth
          inputProps={{
            step: 60 // 1 min
          }}
          onChange={(e) => {
            updateHours(
              workingHoursRangeIndex,
              assoc('end', path(['target', 'value'], e), workingHoursRange),
              enName
            );
          }}
        />
      </Grid>
    </Grid>
  );
};

export default WorkingHoursItem;
