import React, { useCallback, useEffect, useState } from 'react';
import { prop } from 'ramda';
import Pager from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import CarsDataList from './CarsDataList';
import { saveUpdatedCars, syncBqToPgNames } from '../api';

const ManageView = ({ getNewCars }) => {
  const [loading, setLoading] = useState(false);
  const [syncLoading, setSyncLoading] = useState(false);
  const [newCars, setNewCars] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);

  const [isArrowUp, setIsArrowUp] = useState(true);
  const [selectedField, setSelectedField] = useState('');
  const [filters, setFilters] = useState([]);

  const pageSize = 25;

  const fetchCars = useCallback(
    async (page, orderByField, orderDesc, selectedFilters) => {
      setLoading(true);
      setIsArrowUp(orderDesc);
      setSelectedField(orderByField);
      setFilters(selectedFilters);
      const res = await getNewCars(
        pageSize,
        page + 1,
        orderByField,
        orderDesc,
        selectedFilters
      );
      setNewCars(prop('data', res));
      setTotal(prop('totalCount', res));
      setLoading(false);
    },
    [getNewCars, pageSize]
  );

  useEffect(() => {
    fetchCars(0);
  }, []);

  const handleOnPageChange = useCallback(
    async (page) => {
      await fetchCars(page, selectedField, isArrowUp, filters);
      setCurrentPage(page);
    },
    [isArrowUp, selectedField, filters]
  );

  const handleToolbarChange = useCallback(
    async (orderByField, orderDesc, selectedFilters) => {
      await fetchCars(0, orderByField, orderDesc, selectedFilters);
      setCurrentPage(0);
    },
    []
  );

  const handleSaveUpdatedCars = useCallback(
    async (updatedCar, updatedField) => {
      try {
        await saveUpdatedCars(updatedCar, updatedField);
      } catch (error) {
        console.error('Error saving data:', error);
      }
    },
    []
  );

  const syncMakesToSite = async () => {
    try {
      setSyncLoading(true);
      await syncBqToPgNames();
    } catch (error) {
      console.error('Error sync data:', error);
    } finally {
      setSyncLoading(false);
    }
  };

  return (
    <Pager>
      <Card style={{ padding: 16 }}>
        <CarsDataList
          newCars={newCars}
          loading={loading}
          total={total}
          currentPage={currentPage}
          onPageChange={handleOnPageChange}
          handleToolbarChange={handleToolbarChange}
          handleSaveUpdatedCars={handleSaveUpdatedCars}
          syncMakesToSite={syncMakesToSite}
          pageSize={pageSize}
          isSyncLoading={syncLoading}
        />
      </Card>
    </Pager>
  );
};

export default ManageView;
