import React, { useCallback, useState } from 'react';
import {
  Button,
  Popover,
  TextField,
  IconButton,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

const MAX_FILTERS = 8;

const FilterPopover = ({ fieldsOptions, onSubmit }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [filters, setFilters] = useState([{ name: '', value: '' }]);
  const [error, setError] = useState('');

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setError('');
  };

  const handleAddFilter = () => {
    if (filters.length < MAX_FILTERS) {
      setFilters([...filters, { name: '', value: '' }]);
    }
  };

  const handleRemoveFilter = (index) => {
    const newFilters = filters.filter((_, i) => i !== index);
    setFilters(newFilters);
  };

  const handleChangeFilter = (index, field, value) => {
    const newFilters = [...filters];
    newFilters[index][field] = value;
    setFilters(newFilters);
  };

  const onSubmitFilters = useCallback(() => {
    const isValid = filters.every((filter) => filter.name && filter.value);
    if (!isValid) {
      setError('אחד הפרמטרים של הסנן חסרים');
      return;
    }
    onSubmit(filters);
    handleClose();
  }, [filters, onSubmit]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const selectedFields = filters.map((filter) => filter.name);
  const activeFiltersCount = filters.filter(
    (filter) => filter.name && filter.value
  ).length;

  return (
    <div>
      <Button color="primary" onClick={handleOpen}>
        סינון {activeFiltersCount ? `(${activeFiltersCount})` : ''}
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <div style={{ padding: 16, width: 300 }}>
          {filters.map((filter, index) => (
            <div
              key={index}
              style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}
            >
              <FormControl
                variant="outlined"
                size="small"
                style={{ marginRight: 8, minWidth: 120 }}
              >
                <InputLabel>שדה</InputLabel>
                <Select
                  value={filter.name}
                  onChange={(e) =>
                    handleChangeFilter(index, 'name', e.target.value)
                  }
                  label="שדה"
                >
                  {fieldsOptions
                    .filter(
                      (field) =>
                        !selectedFields.includes(field) || field === filter.name
                    )
                    .map((field) => (
                      <MenuItem key={field} value={field}>
                        {field}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <TextField
                label="ערך"
                variant="outlined"
                size="small"
                value={filter.value}
                onChange={(e) =>
                  handleChangeFilter(index, 'value', e.target.value)
                }
                style={{ marginRight: 8 }}
              />
              <IconButton
                onClick={() => handleRemoveFilter(index)}
                size="small"
              >
                <DeleteIcon fontSize="small" />
              </IconButton>
            </div>
          ))}
          {error && (
            <Typography color="error" variant="body2">
              {error}
            </Typography>
          )}
          {filters.length < MAX_FILTERS && (
            <Button
              variant="outlined"
              color="primary"
              startIcon={<AddIcon />}
              onClick={handleAddFilter}
              fullWidth
              style={{ margin: '10px 0' }}
            >
              הוספת סנן
            </Button>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={onSubmitFilters}
            fullWidth
          >
            החל
          </Button>
        </div>
      </Popover>
    </div>
  );
};

export default FilterPopover;
