import React from 'react';
import Pager from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import DynamicContentList from './DynamicContentList';
import useLatestDynamicContent from '../../../utils/hooks/useLatestDynamicContent';
import useLatestDynamicContentAd from '../../../utils/hooks/useLatestDynamicContentAd';

const DynamicContentView = () => {
  const { dynamicContent = [] } = useLatestDynamicContent();
  const { dynamicContentAd = [] } = useLatestDynamicContentAd();

  return (
    <Pager>
      <Card style={{ padding: 16, background: '#F5F5F5' }}>
        <DynamicContentList
          content={dynamicContent}
          contentAd={dynamicContentAd}
        />
      </Card>
    </Pager>
  );
};

export default DynamicContentView;
