import React from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import { map as rmap } from 'ramda';
import CarwizTextField from './TextField';

const CarwizSelectField = ({ options, ...others }) => (
  <CarwizTextField {...others} select>
    {options &&
      rmap(
        option => (
          <MenuItem key={option.id} value={option.id}>
            {option.value}
          </MenuItem>
        ),
        options,
      )}
  </CarwizTextField>
);

CarwizTextField.defaultProps = {
  meta: {
    touched: false,
    error: null,
  },
  options: [],
};

export default CarwizSelectField;
