import React from 'react';
import { Edit } from 'react-admin';
import AgencyMutation from './AgencyMutation';

const AgencyEdit = (props) => (
  <Edit {...props}>
    <AgencyMutation {...props} disableId />
  </Edit>
);

export default AgencyEdit;
