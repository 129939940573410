import BranchIcon from '@material-ui/icons/LocationCity';
import AgenciesBranchCreate from './AgencyBranchCreate';
import AgenciesBranchEdit from './AgencyBranchEdit';
import AgenciesBranchList from './AgencyBranchList';

export default {
  list: AgenciesBranchList,
  icon: BranchIcon,
  edit: AgenciesBranchEdit,
  create: AgenciesBranchCreate
};
