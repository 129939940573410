import { map, prop } from 'ramda';
import React, { useEffect, useState } from 'react';
import { FilePond, registerPlugin } from 'react-filepond';
// Import the plugin code
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginGetFile from 'filepond-plugin-get-file';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginMediaPreview from 'filepond-plugin-media-preview';

import 'filepond-plugin-get-file/dist/filepond-plugin-get-file.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond-plugin-media-preview/dist/filepond-plugin-media-preview.css';
import 'filepond/dist/filepond.min.css';

import './filePondStyles.css';

import { getBlob, getImageSignedUrl, uploadFileToSignedURL } from '../../utils';
import messages from './messages';

// Register the plugins
registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginImageTransform,
  FilePondPluginGetFile,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginMediaPreview
);

const mapInitalFiles = (fileUrl) => ({
  // the server file reference
  source: fileUrl,

  // set type to local to indicate an already uploaded file
  options: {
    type: 'local'
  }
});

const renderLabelIdle = (message, maxFiles) => `
<span class="filepond--label-action">${message}</span>
<br/>
<strong>${messages.maxFilesMessage(maxFiles)}</strong>
`;

/* eslint-disable react/prefer-stateless-function */
const FilepondWrapper = ({
  maxFiles = 5,
  maxFileSize = '5MB',
  pond,
  initialFiles,
  uploadText,
  onProcessFiles,
  acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg'],
  apiUrlPrefix = '/api',
  onUpdateFile = () => {},
  destDirectory
}) => {
  const [files, setFiles] = useState([]);
  const { labelIdle, ...otherMessages } = messages;
  const server = {
    revert: null,
    load: async (source, load, error) => {
      try {
        const response = await getBlob(source);

        load(response);
      } catch (e) {
        error(e);
      }
    },
    process: async (
      fieldName,
      file,
      metadata,
      load,
      error,
      progress,
      abort
    ) => {
      try {
        const fileName = prop('name', file);
        const signedUrl = await getImageSignedUrl(
          fileName.substr(fileName.lastIndexOf('.') + 1),
          apiUrlPrefix,
          destDirectory
        );
        await uploadFileToSignedURL(signedUrl, file);
        const fileUrl = prop(0, signedUrl.split('?'));

        load(fileUrl);
      } catch (e) {
        error();
      }

      return {
        abort: () => {
          // This function is entered if the user has tapped the cancel button

          // Let FilePond know the request has been cancelled
          abort();
        }
      };
    }
  };

  useEffect(() => {
    if (initialFiles && initialFiles !== files) {
      setFiles(() => map(mapInitalFiles, initialFiles));
    }
  }, [initialFiles]);

  return (
    <FilePond
      files={files}
      ref={pond}
      allowMultiple
      maxFiles={maxFiles}
      server={server}
      onupdatefiles={(fileItems) => {
        setFiles(map(prop('file'), fileItems));
        onUpdateFile();
      }}
      labelIdle={renderLabelIdle(uploadText || labelIdle, maxFiles)}
      onaddfilestart={() => onProcessFiles()}
      onprocessfile={() => onProcessFiles()}
      oninit={() => onProcessFiles()}
      acceptedFileTypes={acceptedTypes}
      maxFileSize={maxFileSize}
      {...otherMessages}
    />
  );
};

export default FilepondWrapper;
