import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  SimpleForm,
  TextInput,
  SelectInput,
  EditButton,
  ReferenceInput,
  Filter,
  Create,
  required,
  Toolbar
} from 'react-admin';
import { path } from 'ramda';
import CreateAgentButton from './CreateAgentButton';
// import AgentImgInput from './ImageInput';
import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';

const showDisplayName = (record) =>
  `${path(['agency', 'displayName'], record)} ${path(
    ['areaName'],
    record
  )} (${path(['originalId'], record)}`;

export const AgentsList = (props) => {
  return (
    <List
      {...props}
      filters={<AgentFilter />}
      sort={{ field: 'name', order: 'ASC' }}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid>
        <TextField source="name" />
        <TextField source="title" />
        <TextField source="imgUrl" />
        <TextField source="agencyBranchId" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

const AgentFilter = (props) => (
  <Filter {...props}>
    <TextInput source="name" />
    <TextInput source="title" />
    <ReferenceInput
      source="agencyBranchId"
      reference="AgencyBranch"
      perPage={2000}
    >
      <SelectInput optionText="originalId" optionValue="originalId" />
    </ReferenceInput>
  </Filter>
);

export const AgentEdit = (props) => {
  return (
    <SpecificFieldsEdit
      {...props}
      specificFields={[
        'nodeId',
        'id',
        'name',
        'title',
        'imgUrl',
        'agencyBranchId'
      ]}
    >
      <SimpleForm>
        <TextInput source="name" validate={required()} />
        <TextInput source="title" validate={required()} />
        {/* <AgentImgInput /> */}
        <TextInput label="Image" source="imgUrl" />
        <div style={{ display: 'none' }}>
          <TextInput label="Compressed Image Url" source="compressedImgUrl" />
        </div>
        <ReferenceInput
          source="agencyBranchId"
          reference="AgencyBranch"
          validate={required()}
          perPage={2000}
          filter={{
            specificFields: ['id', 'nodeId', 'agency', 'areaName']
          }}
        >
          <SelectInput optionText={showDisplayName} optionValue="originalId" />
        </ReferenceInput>
      </SimpleForm>
    </SpecificFieldsEdit>
  );
};

export const AgentCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <CreateAgentButton />
        </Toolbar>
      }
    >
      <TextInput source="name" validate={required()} />
      <TextInput source="title" validate={required()} />
      {/* <AgentImgInput /> */}
      <TextInput label="Image" source="imgUrl" disabled />
      <div style={{ display: 'none' }}>
        <TextInput label="Compressed Image Url" source="compressedImgUrl" />
      </div>
      <ReferenceInput
        source="agencyBranchId"
        reference="AgencyBranch"
        perPage={2000}
        validate={required()}
      >
        <SelectInput optionText={showDisplayName} optionValue="originalId" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);
