import React, { useEffect, useState } from 'react';
import {
  required,
  minValue,
  maxValue,
  number,
  NumberInput,
  SimpleForm,
  TextInput,
  SelectInput
} from 'react-admin';
import { fetchDistricts } from '../Districts/api';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const validateDecimal = (value) => {
  const numStr = String(value);

  if (numStr.includes('.')) {
    const countAfterDecimal = numStr.split('.')[1].length; // count numbers are decimal
    if (countAfterDecimal > 4) return 'לא יותר מ 4 ספרות אחרי הנקודה';
  }

  return undefined; // no decimal
};

const validateLongitude = [
  number(),
  minValue(-180),
  maxValue(180),
  required(),
  validateDecimal
];
const validateLatitude = [
  number(),
  minValue(-90),
  maxValue(90),
  required(),
  validateDecimal
];

const CitiesMutation = ({ initialValues, ...props }) => {
  const { token } = useFirebaseToken();
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    fetchDistricts(token).then(setDistricts);
  }, [token]);

  return (
    <SimpleForm {...props} initialValues={initialValues}>
      <TextInput disabled label="ID" source="id" />
      <TextInput label="City" source="city" validate={required()} />
      <SelectInput
        source="district"
        choices={districts}
        label="District"
        validate={required()}
      />
      <NumberInput
        label="Longitude"
        source="longitude"
        validate={validateLongitude}
        min={-180}
        max={180}
      />
      <NumberInput
        label="Latitude"
        source="latitude"
        validate={validateLatitude}
        min={-90}
        max={90}
      />
    </SimpleForm>
  );
};

export default CitiesMutation;
