import React from 'react';
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  useTranslate
} from 'react-admin';

const ShortLinksFilter = (props) => {
  const translate = useTranslate();

  return (
    <Filter {...props}>
      <TextInput
        source="shortenedUrl"
        label={translate('ra.resources.short_links.short_link')}
      />
      <TextInput
        source="originalUrl"
        label={translate('ra.resources.short_links.original_link')}
      />
    </Filter>
  );
};

const ShortLinksList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      sort={{ field: 'shortenedUrl', order: 'ASC' }}
      filters={<ShortLinksFilter />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid>
        <TextField
          source="originalId"
          label={translate('ra.resources.short_links.id')}
        />
        <TextField
          source="shortenedUrl"
          label={translate('ra.resources.short_links.short_link')}
        />
        <TextField
          source="originalUrl"
          label={translate('ra.resources.short_links.original_link')}
        />
      </Datagrid>
    </List>
  );
};
export default ShortLinksList;
