import React, { createElement } from 'react';
import { MenuItemLink } from 'react-admin';

/** @type {import('react').FunctionComponent<MenuItemProps>} */
const MenuItem = ({ resource, onMenuClick, open }) => {
  return (
    <MenuItemLink
      key={resource.name}
      to={`/${resource.name}`}
      primaryText={
        (resource.options && resource.options.label) || resource.name
      }
      leftIcon={resource.icon && createElement(resource.icon)}
      onClick={onMenuClick}
      sidebarIsOpen={open}
    />
  );
};

export default MenuItem;

/**
 * @typedef MenuItemProps
 * @property {import('react-admin').ResourceDefinition} resource
 * @property {Function} onMenuClick
 * @property {boolean} open
 */
