import firebase from 'firebase';
import * as React from 'react';
import { SaveButton, Toolbar, useRefresh } from 'react-admin';
import { useFormState } from 'react-final-form';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import { YEARS } from './constants';

const handleSubmit = async (formVals, redirect, basePath, refresh) => {
  const { nodeId, updatingUserUid, rate, originalId } = formVals;
  const decimalRate = (rate / 100).toFixed(6);

  const sqlDate = new Date().toISOString().slice(0, 19).replace('T', ' ');

  const user = firebase.auth().currentUser;
  const token = await user.getIdToken();

  const response = await graphqlServiceRequest(
    token,
    `mutation  {
      updateFundingRate(input: {nodeId: "${nodeId}",
       patch: {updatedAt: "${sqlDate}", updatingUserUid: "${updatingUserUid}", rate: "${decimalRate}", vehicleAge:"${
      YEARS[originalId - 1]
    }"}}) {
        fundingRate {
          ratesUpdatedAt
          updatingUserUid
          rate
    }
  }
}`,
    API_URL
  )
    .then(redirect(basePath))
    .then(refresh);
};
// eslint-disable-next-line import/prefer-default-export
export const EditToolbar = (props) => {
  const formState = useFormState();
  const refresh = useRefresh();

  return (
    <Toolbar {...props}>
      <SaveButton
        disabled={props.invalid}
        handleSubmitWithRedirect={() =>
          handleSubmit(
            formState.values,
            props.redirect,
            props.basePath,
            refresh
          )
        }
      />
    </Toolbar>
  );
};
