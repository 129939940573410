import { useCallback, useState } from 'react';
import { gql } from 'apollo-client-preset';
import buildApolloClient from '../../shared-components/ra-data-postgraphile/buildApolloClient';
import { API_URL } from '../../constants/constants';

const getQuery = gql`
  query GetSiteSetting($key: String!) {
    siteSettingByKey(key: $key) {
      value
    }
  }
`;

const setQuey = gql`
  mutation SetSiteSetting($key: String!, $value: String!) {
    updateSiteSettingByKey(input: { key: $key, patch: { value: $value } }) {
      siteSetting {
        value
      }
    }
  }
`;

const useSiteSettings = (key) => {
  const [client] = useState(() => buildApolloClient({ uri: API_URL }));

  const getValue = useCallback(async () => {
    const res = await client.query({ query: getQuery, variables: { key } });
    const value = res?.data?.siteSettingByKey?.value;
    return value;
  }, []);

  const saveValue = useCallback(async (value) => {
    await client.mutate({ mutation: setQuey, variables: { key, value } });
  }, []);
  return {
    getValue,
    saveValue
  };
};

export default useSiteSettings;
