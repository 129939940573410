import polyglotI18nProvider from 'ra-i18n-polyglot';
import hebrewMessages from './carwiz-lib/ra-language-hebrew';

const messages = {
  he: hebrewMessages
};

export const i18nProvider = polyglotI18nProvider(
  (locale) => messages[locale],
  'he'
);
