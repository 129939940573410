import messages from './messages';
import generalValidations from '../validations';

// eslint-disable-next-line import/prefer-default-export
export const VERIFICATION_CODE_FIELD = {
  name: 'verificationCode',
  label: messages.code,
  validate: [generalValidations.required],
  type: 'tel',
  required: true,
  autocomplete: 'one-time-code'
};
