/* eslint-disable import/no-extraneous-dependencies */
import React, { useState } from 'react';
import { Button, makeStyles, TextField } from '@material-ui/core';
import {
  DateInput,
  AutocompleteInput,
  SelectArrayInput,
  BooleanInput
} from 'react-admin';
import { Form } from 'react-final-form';
import useAgenciesForAutoComplete from '../../../utils/hooks/useAgenciesForAutoComplete';
import useAgencyBranchesForAutoComplete from '../../../utils/hooks/useAgencyBranchesForAutoComplete';
import { TABLE_CHOICES } from '../constants';

const useStyles = makeStyles((theme) => ({
  formRow: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))',
    gap: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  input: {
    width: '100%' // Set a fixed width for the input fields
  },
  button: {
    gridColumn: 'span 2',
    justifySelf: 'start'
  }
}));

const SearchFilter = ({ onSearchFilterChange, loading = false }) => {
  const classes = useStyles();
  const { agencies } = useAgenciesForAutoComplete();
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const today = new Date();
  const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

  const [fromDate, setFromDate] = useState(firstDayOfMonth);
  const [toDate, setToDate] = useState(today);
  const [selectedTables, setSelectedTables] = useState(
    TABLE_CHOICES.map((choice) => choice.id)
  );
  const [carId, setCarId] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [selectedAgency, setSelectedAgency] = useState(null);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [skipAutomated, setSkipAutomated] = useState(true);

  const handleFilterClick = () => {
    const filterObj = {
      from: `${new Date(fromDate).toLocaleDateString()} 00:00:00`,
      to: `${new Date(toDate).toLocaleDateString()} 23:59:59`,
      tables: selectedTables,
      skip_automated: skipAutomated,
      search_values: {}
    };

    if (carId) filterObj.search_values.carId = carId;
    if (customerId) filterObj.search_values.customerId = customerId;
    if (selectedAgency) filterObj.search_values.agencyId = selectedAgency;
    if (selectedBranch) filterObj.search_values.branchId = selectedBranch;

    onSearchFilterChange(filterObj);
  };

  return (
    <Form
      onSubmit={handleFilterClick}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <h3>פרמטרים לחיפוש</h3>
          <div className={classes.formRow}>
            <DateInput
              className={classes.input}
              label="מתאריך"
              source="fromtDate"
              locales="he-IL"
              onChange={(ev) => setFromDate(ev.target.value)}
              defaultValue={fromDate}
            />
            <DateInput
              className={classes.input}
              label="עד תאריך"
              source="toDate"
              locales="he-IL"
              onChange={(ev) => setToDate(ev.target.value)}
              defaultValue={toDate}
            />
            <SelectArrayInput
              className={classes.input}
              label="טבלאות"
              choices={TABLE_CHOICES}
              name="tables"
              defaultValue={selectedTables}
              onChange={(ev) => {
                setSelectedTables(ev.target.value);
              }}
            />
          </div>
          <div className={classes.formRow}>
            <TextField
              className={classes.input}
              label="מזהה רכב"
              value={carId}
              onChange={(e) => setCarId(e.target.value)}
            />
            <TextField
              className={classes.input}
              label="מזהה לקוח"
              value={customerId}
              onChange={(e) => setCustomerId(e.target.value)}
            />
            <AutocompleteInput
              className={classes.input}
              label="סוכנות"
              source="agencies"
              choices={agencies || []}
              onChange={(agency) => setSelectedAgency(agency)}
              allowEmpty
            />
            <AutocompleteInput
              className={classes.input}
              label="סניף"
              source="branches"
              choices={agencyBranches || []}
              onChange={(branch) => setSelectedBranch(branch)}
              allowEmpty
            />
          </div>
          <BooleanInput
            source="skipAutomted"
            label="ללא תהליכים אוטומטים"
            defaultValue={skipAutomated}
            onChange={(v) => {
              setSkipAutomated(v);
            }}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            className={classes.button}
          >
            חפש
          </Button>
        </form>
      )}
    />
  );
};

export default SearchFilter;
