// eslint-disable-next-line import/prefer-default-export
export const fetchTeamUsersQuery = (teamId) => `
  query getUsers {
    users(filter: {teamId: {in: [${teamId}]}}) {
      edges {
        node {
          uid
          name
        }
      }
    }
  }`;

export const latestBranchIdQuery = `query {
    agencyBranches(
      orderBy : ID_DESC
      first : 1
      filter: { id: { notEqualTo: 10000 } }
    ) {
      nodes {
        id
      }
    }
  }
`;
