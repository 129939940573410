import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const useInteractionTypesForAutoComplete = (interactionTypes) => {
  const [types, setTypes] = useState([]);
  const version = useVersion();
  const dataProvider = useDataProvider();

  const filters = { specificFields: ['nodeId', 'id', 'label'] };

  if (interactionTypes) {
    if (typeof interactionTypes === 'string') {
      filters.id = interactionTypes;
    } else if (Array.isArray(interactionTypes) && interactionTypes.length) {
      filters.id = { filter: 'in', value: interactionTypes };
    }
  }

  const fetchInteractionTypes = async () => {
    const { data } = await dataProvider(GET_LIST, 'InteractionType', {
      filter: filters,
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedTypes = pipe(
      map((type) => ({
        id: prop('originalId', type),
        name: prop('originalId', type),
        label: prop('label', type) || prop('originalId', type)
      })),
      filter(prop('id'))
    )(data);

    setTypes(mappedTypes);
  };

  useEffect(() => {
    fetchInteractionTypes();
  }, [version]);

  return {
    types
  };
};

export default useInteractionTypesForAutoComplete;
