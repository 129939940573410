import React from 'react';
import Grid from '@material-ui/core/Grid';
import { prop } from 'ramda';
import messages from './messages';
import { mapIndexed } from '../../utils';

const WorkingHoursList = ({ workingHoursRanges, renderItem }) => (
  <>
    {workingHoursRanges ? (
      mapIndexed(renderItem, workingHoursRanges)
    ) : (
      <Grid item xs={12}>
        {prop('close', messages)}
      </Grid>
    )}
  </>
);

export default WorkingHoursList;
