import React, { useRef } from 'react';
import { useField } from 'react-final-form';
import { pipe, split, remove, adjust, join, prop } from 'ramda';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { FilepondWrapper } from '../../bitComponents/multiple-files-upload/MultipleFilesUpload';
import { getFilesUrls } from '../../bitComponents/multiple-files-upload/MultipleFilesUpload/utils';
import { API_URL, CDN_PREFIX } from '../../constants/constants';

const BoundedFilepondWrapper = ({ name, label }) => {
  const pond = useRef(null);
  const {
    input: { onChange, value: logoValue }
  } = useField(name);
  const {
    input: { onChange: changeCompressedLogo }
  } = useField('compressedLogo');

  const onProcessFiles = () => {
    const fileUrls = getFilesUrls(pond.current.getFiles());

    const cdnUrl =
      fileUrls.length > 0
        ? pipe(
            prop(0),
            split('/'),
            adjust(2, () => CDN_PREFIX), // replace prefix with the cdn
            remove(3, 1), // remove the name of teh bucket
            join('/')
          )(fileUrls)
        : prop(0, fileUrls);
    const compressedCdnUrl = cdnUrl
      ? pipe(
          split('/'),
          adjust(3, (v) => `compressed/${v}`), // Add `compressed` prefix to the path
          adjust(-1, (v) => {
            // eslint-disable-next-line no-shadow
            const [name] = split('.', v);
            return `${name}.webp`;
          }), // replace extension with webp
          join('/')
        )(cdnUrl)
      : cdnUrl;
    onChange(cdnUrl);
    changeCompressedLogo(compressedCdnUrl);
  };

  const handleChangeImage = () => {
    onChange('');
    changeCompressedLogo('');
  };

  if (logoValue && logoValue !== 'null') {
    return (
      <div style={{ width: 250, marginBottom: 20 }}>
        <Typography variant="body2">{label}</Typography>
        <img src={logoValue} alt="LOGO" width="250" />
        <Button
          type="button"
          variant="contained"
          color="secondary"
          style={{ marginTop: 10 }}
          onClick={handleChangeImage}
        >
          Change Image
        </Button>
      </div>
    );
  }

  return (
    <div style={{ width: 250 }}>
      <FilepondWrapper
        pond={pond}
        uploadText={label}
        maxFiles={1}
        onProcessFiles={onProcessFiles}
        acceptedTypes={['image/webp', 'image/png', 'image/jpeg', 'image/jpg']}
        apiUrlPrefix={API_URL}
        destDirectory="logos"
      />
    </div>
  );
};

const LogoInput = () => {
  return <BoundedFilepondWrapper name="logo" label="upload logo" />;
};

export default LogoInput;
