import Container from '@material-ui/core/Container';
import withStyles from '@material-ui/core/styles/withStyles';
import firebase from 'firebase/app';
import React, { useEffect } from 'react';
import { useLogin, useTranslate } from 'react-admin';
import {
  LoginSuccess,
  PhoneForm,
  usePhoneLoginForm,
  VerificationCodeForm
} from '../../bitComponents/phone-login-form/PhoneLoginForm';
import StepperForm from '../../bitComponents/stepper-form';
import UnauthorizedLogin from '../UnauthorizedLogin';

const styles = (theme) => ({
  formContainer: {
    padding: 0,
    direction: 'rtl'
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center'
  },
  logo: {
    height: 30
  },
  loginFormContainer: {
    [theme.breakpoints.up('sm')]: {
      width: 400
    },
    [theme.breakpoints.down('sm')]: {
      width: 330
    }
  }
});

const CAPTCHA_ID = 'captcha-container-id"';

const FirebaseLoginPageOtp = ({ classes }) => {
  const {
    resendCode,
    submitPhone,
    resendLoading,
    resendError,
    resetPhone,
    confirmationResult,
    setStepIndex,
    sendPhoneVerificationCode,
    phoneSent,
    stepIndex,
    setRecapchaVerifier
  } = usePhoneLoginForm(firebase);

  const login = useLogin();
  const translate = useTranslate();

  useEffect(() => {
    setRecapchaVerifier(
      new firebase.auth.RecaptchaVerifier(CAPTCHA_ID, {
        size: 'invisible'
      })
    );
  }, [setRecapchaVerifier]);

  const steps = [
    {
      getContent: () => (
        <PhoneForm
          onSubmit={submitPhone}
          sendPhoneVerificationCode={sendPhoneVerificationCode}
          title={translate('ra.auth.sign_in')}
          subtitle={translate('ra.auth.sign_in_subtitle')}
          disableReassuringTip
          disableToggleSignupLogin
        />
      ),
      withoutActions: true
    },
    {
      getContent: () => (
        <VerificationCodeForm
          confirmationResult={confirmationResult}
          phoneSent={phoneSent}
          onResetPhone={resetPhone}
          onResendCode={resendCode}
          onSubmit={async ({ user }) => {
            try {
              await login({ user });
              setStepIndex(stepIndex + 2);
            } catch (e) {
              setStepIndex(stepIndex + 1);
            }
          }}
          resendLoading={resendLoading}
          resendError={resendError}
        />
      ),
      withoutActions: true
    },
    {
      getContent: () => <UnauthorizedLogin resetPhone={resetPhone} />,
      withoutActions: true
    }
  ];

  return (
    <>
      <Container maxWidth="sm" className={classes.formContainer}>
        <div className={classes.loginFormContainer}>
          <StepperForm
            steps={steps}
            withoutActions
            stepIndex={stepIndex}
            handleReset={() => setStepIndex(0)}
            formPreview={<LoginSuccess />}
            withoutReset
            withoutStepsHeader
          />{' '}
        </div>
      </Container>
      <div id={CAPTCHA_ID} />
    </>
  );
};

export default withStyles(styles)(FirebaseLoginPageOtp);
