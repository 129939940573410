import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  EditButton,
  Filter,
  List,
  NumberField,
  TextField,
  TextInput,
  SelectInput
} from 'react-admin';
import { fetchDistricts } from '../Districts/api';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const CitiesFilter = (props) => {
  const { token } = useFirebaseToken();
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    fetchDistricts(token).then(setDistricts);
  }, [token]);

  return (
    <Filter {...props}>
      <TextInput source="city" label="עיר" />
      <SelectInput source="district" label="מחוז" choices={districts} />
    </Filter>
  );
};

const CitiesList = (props) => (
  <List
    {...props}
    sort={{ field: 'city', order: 'ASC' }}
    filters={<CitiesFilter />}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      {/* <TextField source="id" /> */}
      <TextField source="city" label="City" />
      <TextField source="district" label="District" />
      <NumberField source="longitude" label="Longitude" />
      <NumberField source="latitude" label="Latitude" />
      <EditButton />
    </Datagrid>
  </List>
);

export default CitiesList;
