import { createTheme } from '@material-ui/core/styles';

export default createTheme({
  palette: {
    primary: {
      main: '#0286A4',
      dark: '#007C98',
      contrastText: '#FFFFFF'
    },
    secondary: {
      main: '#FFAA43',
      dark: '#FD9710',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#444444'
    },
    background: {
      default: '#F7F7F7'
    }
  },
  direction: 'rtl',
  typography: {
    fontFamily: "'Noto Sans Hebrew', 'Noto Sans', sans-serif",
    subtitle1: {
      fontSize: 14,
      color: '#777777'
    },
    body1: {
      fontSize: 14,
      color: '#777777'
    },
    h2: {
      color: '#444444',
      fontSize: 24
    },
    h3: {
      color: '#444444',
      fontSize: 16
    }
  },
  rounded: 20
});
