import { useCallback, useEffect, useState } from 'react';
import useLoading from '../../../../utils/hooks/useLoading';
import useSiteSettings from '../../../../utils/hooks/useSiteSettings';
import useNotifications from './useNotifications';

const useFundingPercentageLimit = () => {
  const [value, setValue] = useState();
  const { loading, withLoading } = useLoading();
  const { getValue, saveValue } = useSiteSettings(
    'funding.funding_percentage_limit'
  );
  const notifications = useNotifications();

  const update = useCallback((v) => {
    return withLoading(async () => {
      await saveValue((v / 100).toFixed(2));
      setValue(v);
      notifications.updateSuccess();
    }, notifications.updateFail);
  }, []);

  useEffect(() => {
    withLoading(async () => {
      const v = await getValue();
      setValue(v * 100);
    }, notifications.getFail);
  }, []);

  return {
    loading,
    value,
    update
  };
};

export default useFundingPercentageLimit;
