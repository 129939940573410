import React from 'react';
import { Create } from 'react-admin';
import CitiesMutation from './CitiesMutation';

const CitiesCreate = (props) => (
  <Create {...props}>
    <CitiesMutation {...props} />
  </Create>
);

export default CitiesCreate;
