/* eslint-disable import/no-extraneous-dependencies */
import * as React from 'react';
import { useSelector } from 'react-redux';
import { getResources } from 'react-admin';
import { withRouter } from 'react-router-dom';
import Menu from './Menu';

const MenuWrapper = (props) => {
  const {
    onMenuClick,
    location: { pathname }
  } = props;
  const open = useSelector((state) => state.admin.ui.sidebarOpen);
  const resources = useSelector(getResources);

  return (
    <Menu
      open={open}
      resources={resources}
      onMenuClick={onMenuClick}
      urlpath={pathname}
    />
  );
};

export default withRouter(MenuWrapper);
