/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-plusplus */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from 'react';
import { useTranslate, useNotify, useRefresh } from 'react-admin';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  useGridSlotComponentProps
} from '@material-ui/data-grid';
import { makeStyles } from '@material-ui/styles';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Pagination } from '@material-ui/lab';
import useAgenciesForAutoComplete from '../../utils/hooks/useAgenciesForAutoComplete';
import useAgencyBranchesForAutoComplete from '../../utils/hooks/useAgencyBranchesForAutoComplete';
import UseSalesForceAccountsForAutoComplete from '../../utils/hooks/UseSalesForceAccountsForAutoComplete';
import { API_URL } from '../../constants/constants';
import { graphqlServiceRequest } from '../../bitComponents/utils';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const AGENCY_TYPES = [
  'רשת',
  'דילר פרטי יחיד',
  'דילר פרטי עם סניפים',
  'זכיין',
  'יבואן'
];

const BRANCH_TYPES = ['סניף מתחת לדילר', 'סוכנות תחת יבואן', 'סוכנות תחת רשת'];

const useStyles = makeStyles({
  root: {
    display: 'flex'
  }
});

const SalesForceIdsTable = () => {
  const translate = useTranslate();
  const { agencies } = useAgenciesForAutoComplete();
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const { salesForceAccounts } = UseSalesForceAccountsForAutoComplete();
  const [rows, setRows] = useState([]);
  const [columns, setColumns] = useState([]);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();

  function CustomPagination() {
    const { state, apiRef } = useGridSlotComponentProps();
    const classes = useStyles();

    return (
      <Pagination
        className={classes.root}
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(event, value) => apiRef.current.setPage(value - 1)}
      />
    );
  }

  const CustomToolbar = (props) => {
    // const { onClick, selectedRows } = props;
    const { token } = useFirebaseToken();

    const handleSave = async () => {
      const updatedAgencies = rows.filter(
        (row) => row.hasChanged && row.isAgency
      );

      const updatedBranches = rows.filter(
        (row) => row.hasChanged && !row.isAgency
      );

      setLoading(true);

      try {
        for (const agency of updatedAgencies) {
          const psidParam =
            agency.psid !== null ? `psid: "${agency.psid}"` : 'psid: null';
          const sfidParam =
            agency.sfid !== null ? `sfid: "${agency.sfid}"` : 'sfid: null';

          const query = `
        mutation {
          updateAgencyById(
            input: { patch: { ${psidParam} ${sfidParam} }, id: ${agency.value} }
          ) {
            agency {
              id
            }
          }
        }`;

          await graphqlServiceRequest(token, query, API_URL);
        }

        for (const branch of updatedBranches) {
          const psidParam =
            branch.psid !== null ? `psid: "${branch.psid}"` : 'psid: null';
          const sfidParam =
            branch.sfid !== null ? `sfid: "${branch.sfid}"` : 'sfid: null';

          const query = `
        mutation {
          updateAgencyBranchById(
            input: { patch: { ${psidParam} ${sfidParam} }, id: ${branch.value} }
          ) {
            agencyBranch {
              id
            }
          }
        }`;

          await graphqlServiceRequest(token, query, API_URL);
        }

        setLoading(false);
        notify('ra.notification.updated');
        refresh();
      } catch (error) {
        console.error(error);
        notify('ra.notification.http_error', 'error');
      }
    };

    return (
      <GridToolbarContainer>
        <GridToolbarFilterButton />
        <Button
          variant="contained"
          color="primary"
          startIcon={<SaveIcon />}
          style={{ marginRight: '30px' }}
          onClick={() => {
            handleSave();
          }}
        >
          {translate('ra.action.save')}
        </Button>
      </GridToolbarContainer>
    );
  };

  useEffect(() => {
    setLoading(true);

    if (!salesForceAccounts || !salesForceAccounts.length) return;

    const allRows = [];
    let index = 0;

    if (agencies && agencies.length > 0) {
      agencies.forEach((a) => {
        const sfAccount = (salesForceAccounts || []).find(
          (sfa) => sfa.psid === a.psid && sfa.sfid === a.sfid
        );

        allRows.push({
          id: ++index,
          value: a.id,
          carwizName: a.name,
          sfAccount: sfAccount?.id || null,
          sfid: sfAccount?.sfid || null,
          psid: sfAccount?.psid || null,
          isAgency: true,
          accountType: translate('ra.general.agency')
        });
      });
    }

    if (agencyBranches && agencyBranches.length > 0) {
      agencyBranches.forEach((a) => {
        const sfAccount = (salesForceAccounts || []).find((sfa) => {
          return sfa.psid === a.psid && sfa.sfid === a.sfid;
        });

        allRows.push({
          id: ++index,
          value: a.id,
          carwizName: a.name,
          sfAccount: sfAccount?.id || null,
          sfid: sfAccount?.sfid || null,
          psid: sfAccount?.psid || null,
          isAgency: false,
          accountType: translate('ra.general.agency_branch')
        });
      });
    }

    allRows.sort((a, b) => a.carwizName.localeCompare(b.carwizName));

    setRows(allRows);
    setLoading(false);
  }, [agencies, agencyBranches, salesForceAccounts]);

  useEffect(() => {
    setLoading(false);

    if (!salesForceAccounts || !salesForceAccounts.length) return;

    const values = [
      ...salesForceAccounts.map((a) => ({
        value: a.id,
        label: a.accountName,
        sfid: a.sfid,
        psid: a.psid,
        isAgency: AGENCY_TYPES.includes(a.type)
      }))
    ];

    const agencyValues = [
      {
        value: null,
        label: translate('ra.resources.salesforce.noAccount'),
        sfid: null,
        psid: null,
        isAgency: true
      },
      ...values.filter((v) => v.isAgency)
    ];

    const branchValues = [
      {
        value: null,
        label: translate('ra.resources.salesforce.noAccount'),
        sfid: null,
        psid: null,
        isAgency: false
      },
      ...values.filter((v) => !v.isAgency)
    ];

    const cols = [
      {
        field: 'id',
        headerName: translate('ra.general.id'),
        width: 150
      },
      {
        field: 'carwizName',
        headerName: translate('ra.resources.salesforce.carwizName'),
        editable: false,
        width: 300
      },
      {
        field: 'accountType',
        headerName: translate('ra.resources.salesforce.type'),
        editable: false,
        width: 300
      },
      {
        field: 'sfAccount',
        headerName: translate('ra.resources.salesforce.account'),
        editable: false,
        width: 300,
        type: 'singleSelect',
        // valueOptions: values,
        renderCell: (params) => {
          const { isAgency } = params.row;
          const opts = isAgency ? agencyValues : branchValues;

          return (
            <div
              className={
                params.row.hasChanged
                  ? 'sfAccount-cell changed-cell'
                  : 'sfAccount-cell'
              }
            >
              <Autocomplete
                options={opts}
                disableClearable
                noOptionsText={translate(
                  'ra.resources.salesforce.noAccountsFound'
                )}
                getOptionLabel={(option) => option.label}
                value={opts.find(
                  (option) => option.value === params.row.sfAccount
                )}
                onChange={(_, newValue) => {
                  const selectedValue = newValue || null;
                  const updatedRows = rows.map((row) =>
                    row.id === params.row.id
                      ? {
                          ...row,
                          sfAccount: selectedValue?.value || null,
                          hasChanged: true,
                          sfid: selectedValue?.sfid || null,
                          psid: selectedValue?.psid || null
                        }
                      : row
                  );
                  setRows(updatedRows);
                }}
                style={{ width: 290, marginTop: 10 }}
                renderInput={(p) => <TextField {...p} variant="outlined" />}
              />
              {/* <strong>
                {params.row.sfAccount &&
                  values.find((option) => option.value === params.row.sfAccount)
                    ?.label}
              </strong> */}
              {/* {!params.row.sfAccount &&
                translate('ra.resources.salesforce.doubleClick')} */}
            </div>
          );
        }
      }
    ];

    setColumns(cols);
    setLoading(false);
  }, [salesForceAccounts, rows]);

  if (!columns.length) return null;
  if (!salesForceAccounts.length) return null;

  return (
    <Box>
      <style>
        {`
          .changed-cell .MuiTextField-root {
            background-color: #70D5FF !important;
          }

          .sfAccount-cell:hover {
             cursor: pointer;
          }
        `}
      </style>
      <DataGrid
        rows={rows}
        disableColumnMenu
        disableColumnReorder
        autoPageSize
        autoHeight
        density="comfortable"
        pageSize={20}
        loading={loading}
        columns={columns}
        components={{
          Toolbar: CustomToolbar,
          Pagination: CustomPagination
        }}
      />
    </Box>
  );
};

export default SalesForceIdsTable;
