import { useCallback, useState } from 'react';

const useLoading = (initValue = false) => {
  const [loading, setLoading] = useState(initValue);

  const withLoading = useCallback(async (fn, onError) => {
    try {
      setLoading(true);
      await fn();
    } catch (error) {
      console.error(error);
      if (onError) {
        onError(error);
      }
    } finally {
      setLoading(false);
    }
  }, []);

  return {
    loading,
    withLoading
  };
};

export default useLoading;
