import FirebaseLoginPageOtp from './FirebaseLoginPageOtp';
import MimunLoginPageOtp from './MimunLoginPageOtp';

function getLoginPageOtp() {
  const type = (process.env.REACT_APP_OTP_LOGIN_TYPE || 'mimun').toLowerCase();

  switch (type) {
    case 'mimun':
      return MimunLoginPageOtp;

    default:
      return FirebaseLoginPageOtp;
  }
}

const LoginPageOtp = getLoginPageOtp();

export default LoginPageOtp;
