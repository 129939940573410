import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import React, { useState } from 'react';
import CarwizForm from '../../../carwiz-form/CarwizForm';
import SubmitButton from '../../../carwiz-form/CarwizForm/SubmitButton';
import FormHeader from '../../../dialog-header';
import { VERIFICATION_CODE_FIELD } from './constants';
import messages from './messages';

const useStyles = makeStyles(() => ({
  resetPhone: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  resetPhoneLabel: {
    fontSize: 16,
    fontWeight: 300
  }
}));

/* eslint-disable react/prefer-stateless-function */
const VerificationCodeForm = ({
  submitButtonText,
  onSubmit,
  confirmationResult,
  success,
  phoneSent,
  onResendCode,
  onResetPhone,
  resendLoading,
  resendError,
  submitTrackingClassName,
  headerImageComponent
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);

  const verifyConfirmationCode = async ({ verificationCode }) => {
    try {
      setLoading(true);
      const result = await confirmationResult.confirm(verificationCode);
      setLoading(false);
      onSubmit(result);
      return null;
    } catch (e) {
      setLoading(false);
      return {
        [VERIFICATION_CODE_FIELD.name]:
          messages.errorMessages.validateVerificationCodeFail
      };
    }
  };

  return (
    <>
      <FormHeader
        title={messages.title}
        subtitle={messages.subtitle}
        middleImageComponent={headerImageComponent}
      >
        <Box className={classes.resetPhone}>
          <Typography
            variant="body2"
            align="center"
            className={classes.resetPhoneLabel}
          >
            {phoneSent}
          </Typography>
          <Button
            color="primary"
            onClick={onResetPhone}
            className={classes.resetPhoneLabel}
          >
            {messages.reset}
          </Button>
        </Box>
      </FormHeader>

      <CarwizForm
        formFields={[VERIFICATION_CODE_FIELD]}
        submitAction={verifyConfirmationCode}
        loading={loading}
        submitFieldErrors
        externalSubmit
      >
        <SubmitButton
          loading={loading}
          submitButtonText={submitButtonText || messages.submitButton}
          success={success}
          successText={messages.successText}
          trackingClassName={submitTrackingClassName}
        />
        {loading && (
          <Typography variant="caption">{messages.loading}</Typography>
        )}
        <Button
          onClick={onResendCode}
          color="default"
          variant="text"
          disabled={resendLoading || resendError}
          fullWidth
          classes={{ label: 'resend-code-button' }}
        >
          {
            // eslint-disable-next-line no-nested-ternary
            resendLoading
              ? messages.resendLoading
              : resendError
              ? messages.resendError
              : messages.resend
          }
        </Button>
      </CarwizForm>
    </>
  );
};

export default VerificationCodeForm;
