import React from 'react';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import classnames from 'classnames';
import messages from './messages';

const styles = theme => ({
  wrapper: {
    margin: theme.spacing(2, 0),
    position: 'relative',
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  successTextTypography: {
    textAlign: 'center',
  },
  rounded: {
    borderRadius: 25,
  },
  fixedWidthButton: {
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    margin: `${theme.spacing(2)}px auto`,
  },
  button: {
    height: 50,
    fontSize: '1rem',
  },
});

const SubmitButton = ({
  pristine,
  submitting,
  submitButtonText,
  loading,
  success,
  successText,
  classes,
  submitButtonId,
  rounded,
  LoadingComponent,
  fullWidth = true,
  fixedWidthButton,
  trackingClassName,
  className,
}) => (
  <div
    className={classnames(
      classes.wrapper,
      fixedWidthButton ? classes.fixedWidthButton : undefined,
    )}
  >
    {success ? (
      <Typography className={classes.successTextTypography}>
        {successText}
      </Typography>
    ) : (
      <Button
        classes={{
          root: classnames(
            classes.button,
            {
              [classes.buttonSuccess]: success,
              [classes.rounded]: rounded,
            },
            className,
          ),
          label: trackingClassName,
        }}
        fullWidth={fullWidth}
        variant="contained"
        color="primary"
        disabled={pristine || submitting || loading || success}
        type="submit"
        id={submitButtonId}
        disableElevation
        aria-busy={loading ? 'true' : 'false'}
      >
        {submitButtonText}
      </Button>
    )}
    {loading &&
      (LoadingComponent || (
        <CircularProgress size={24} className={classes.buttonProgress} />
      ))}
  </div>
);

SubmitButton.defaultProps = {
  successText: messages.successText,
  loading: false,
  success: false,
};

export default withStyles(styles)(SubmitButton);
