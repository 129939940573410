import Button from '@material-ui/core/Button';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { find, map, path } from 'ramda';
import React, { useEffect, useState } from 'react';
import {
  Datagrid,
  Edit,
  ReferenceField,
  ReferenceManyField,
  required,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

import { API_URL } from '../../constants/constants';
import labels from '../../labels';
import {
  buildEditTeamQuery,
  buildUpdateUserTeamId,
  fetchRelevantUsersQuery
} from './queries';
import TeamsToolbar from './TeamsToolbar';
import UserDeleteConfirmDialog from './UserDeleteConfirmDialog';
import { mapError } from '../../utils/utils';

const TeamsEdit = ({ initialValues, ...props }) => {
  const [teamName, setTeamName] = useState();
  const [users, setUsers] = useState([]);
  const [selectedUserUid, setSelectedUserUid] = useState();
  const { token } = useFirebaseToken();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const refresh = useRefresh();

  const isUserOnOtherTeam = () => {
    const user = find((u) => u.node.uid === selectedUserUid)(users);
    const userInTeam = user && user.node.team && user.node.team;

    if (userInTeam) {
      const teamId = document.getElementById('teamId').value;
      const teamNameReplacer =
        user.node.team.id === parseInt(teamId, 10)
          ? 'הנוכחי'
          : user.node.team.name;

      notify(
        translate('ra.resources.teams.user_on_other_team', {
          team: teamNameReplacer
        }),
        'error'
      );
      return true;
    }

    return false;
  };

  const updateUserTeam = (uidToUpdate, id, name) => {
    const cloned = [...users];
    const user = find((u) => u.node.uid === uidToUpdate)(cloned);

    if (!user) return;

    if (!id && !name) {
      // remove user from team
      user.node.team = null;
    } else {
      // update user team
      if (!user.node.team) user.node.team = { id: 0, name: '' };

      user.node.team.id = id;
      user.node.team.name = name;
    }

    setUsers(cloned);
  };

  const removeUserFromTeam = async (uidToRemove) => {
    updateUserTeam(uidToRemove);
    setSelectedUserUid(null);
  };

  const addUserToTeam = async () => {
    if (!selectedUserUid) return;
    if (isUserOnOtherTeam()) return;

    const teamId = document.getElementById('teamId').value;

    try {
      const user = (users || []).find((u) => u.node.uid === selectedUserUid);
      const result = await graphqlServiceRequest(
        token,
        buildUpdateUserTeamId(user.node, teamId),
        API_URL
      );

      const nodeId = path(['data', 'updateUser', 'user', 'nodeId'], result);

      if (nodeId) {
        setSelectedUserUid(null);
        updateUserTeam(
          selectedUserUid,
          teamId,
          teamName || document.getElementById('teamName').value
        );
        notify(translate('ra.resources.teams.edit_success'), 'success');
        refresh();
      }
    } catch (err) {
      console.log(err.message);
      notify(translate('ra.page.error'), 'error');
    }
  };

  const updateTeamName = async () => {
    if (!teamName) return;

    const id = document.getElementById('teamId').value;

    try {
      const result = await graphqlServiceRequest(
        token,
        buildEditTeamQuery(id, teamName),
        API_URL
      );

      const nodeId = path(['data', 'updateTeamById', 'team', 'nodeId'], result);
      const errors = path(['errors'], result);

      if (nodeId) {
        notify(translate('ra.resources.teams.edit_success'), 'success');
        redirect('/TeamsView');
        refresh();
      }

      if (errors) {
        const errMessage = mapError(errors);

        if (errMessage.includes('duplicate key')) {
          notify(translate('ra.resources.teams.team_name_exists'), 'error');
        } else {
          notify(errMessage, 'error');
        }
      }
    } catch (err) {
      console.log(err.message);
      notify(translate('ra.page.error'), 'error');
    }
  };

  useEffect(() => {
    if (!token) return;

    const fetchUsers = async () => {
      const result = await graphqlServiceRequest(
        token,
        fetchRelevantUsersQuery(),
        API_URL
      );

      const resultId = path(
        ['data', 'users', 'edges', 0, 'node', 'uid'],
        result
      );

      if (resultId) {
        setUsers(path(['data', 'users', 'edges'], result));
      }
    };

    fetchUsers();
  }, [token]);

  return (
    <Edit {...props}>
      <SimpleForm
        {...props}
        initialValues={initialValues}
        toolbar={null}
        // toolbar={<TeamsToolbar handler={updateTeamName} />}
      >
        <TextInput disabled label="ID" source="teamId" id="teamId" />
        <TextInput
          label={translate('ra.resources.teams.team_name')}
          source="name"
          id="teamName"
          disabled
          validate={required()}
          onChange={(e) => setTeamName(e.target.value)}
        />
        <>
          <div>
            <SelectInput
              source="Users"
              label={labels.users}
              onChange={(e) => setSelectedUserUid(e.target.value)}
              choices={map((u) => {
                return { id: u.node.uid, name: u.node.name };
              }, users)}
            />
            <Button
              variant="contained"
              color="primary"
              size="small"
              onClick={addUserToTeam}
              style={{ marginTop: '10px', marginRight: '10px' }}
              disabled={!selectedUserUid}
              startIcon={<PersonAddIcon />}
            >
              {translate('ra.resources.teams.add_user')}
            </Button>
          </div>
        </>
        <ReferenceManyField
          reference="User"
          target="teamId"
          label={labels.users}
          source="originalId"
          filter={{
            specificFields: ['nodeId', 'uid', 'name', 'phone', 'role']
          }}
        >
          <Datagrid>
            <TextField source="name" label={translate('ra.general.name')} />
            <TextField source="phone" label={translate('ra.general.phone')} />
            <TextField source="role" label={translate('ra.general.role')} />
            <UserDeleteConfirmDialog
              callback={removeUserFromTeam}
              users={users}
            />
          </Datagrid>
        </ReferenceManyField>

        <ReferenceManyField
          reference="AgencyBranch"
          target="team"
          label={labels.agencyBranches}
          source="name"
          filter={{
            specificFields: [
              'nodeId',
              'id',
              'areaName',
              'address',
              'district',
              'agencyId'
            ]
          }}
        >
          <Datagrid>
            <ReferenceField
              label={labels.agencies}
              reference="Agency"
              target="id"
              source="agencyId"
              link={false}
            >
              <TextField source="displayName" />
            </ReferenceField>
            <TextField
              source="areaName"
              label={translate('ra.general.agency_branch')}
            />
            <TextField
              source="district"
              label={translate('ra.general.district')}
            />
            <TextField
              source="address"
              label={translate('ra.general.address')}
            />
          </Datagrid>
        </ReferenceManyField>
      </SimpleForm>
    </Edit>
  );
};

export default TeamsEdit;
