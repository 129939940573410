import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/Info';
import React, { useState } from 'react';
import CarwizForm from '../../../carwiz-form/CarwizForm';
import DialogHeader from '../../../dialog-header';
import { PHONE_FIELD } from './constants';
import messages from './messages';

/* eslint-disable react/prefer-stateless-function */

const useStyles = makeStyles((theme) => ({
  dontPassPersonalInfo: {
    fontWeight: 'bold'
  },
  infoIcon: {
    fontSize: 14,
    marginRight: theme.spacing(0.5),
    marginBottom: -2
  },
  reasuringTip: {
    marginBottom: theme.spacing(3)
  },
  submitButton: {
    margin: theme.spacing(3, 0)
  },
  subtitle: {
    marginBottom: theme.spacing(3)
  }
}));

const PhoneForm = ({
  submitButtonText,
  sendPhoneVerificationCode,
  onSubmit,
  success,
  title,
  subtitle,
  info,
  submitTrackingClassName,
  isRegisteredUser,
  titleVariant,

  headerImageComponent
}) => {
  const [loading, setLoading] = useState(false);
  const classes = useStyles();

  const submit = async ({ phone }) => {
    try {
      setLoading(true);
      const result = await sendPhoneVerificationCode(phone);
      setLoading(false);
      onSubmit({
        firebaseConfirmationResult: result,
        phone
      });
      return null;
    } catch (e) {
      setLoading(false);

      let errorMsg = messages.errorMessages.validatePhoneNumberFail;

      if (
        e.code === 'auth/too-many-requests' ||
        e.code.includes('too-many-requests') ||
        e.code.includes('attempts')
      ) {
        errorMsg = messages.errorMessages.tooManyRequests;
      }

      return {
        [PHONE_FIELD.name]: errorMsg
      };
    }
  };

  const getTitle = () => {
    if (isRegisteredUser) {
      return messages.loginTitle;
    }

    if (title) {
      return title;
    }

    return messages.signupTitle;
  };

  const getSubtitle = () => {
    if (isRegisteredUser) {
      return messages.loginSubtitle;
    }

    if (subtitle) {
      return subtitle;
    }

    return messages.signupSubtitle;
  };

  return (
    <>
      <DialogHeader
        title={getTitle()}
        subtitle={getSubtitle()}
        subtitleClass={classes.subtitle}
        titleVariant={titleVariant}
        middleImageComponent={headerImageComponent}
      />
      {info && (
        <Typography
          variant="body1"
          className={classes.dontPassPersonalInfo}
          gutterBottom
        >
          <InfoIcon className={classes.infoIcon} />
          {info}
        </Typography>
      )}
      <CarwizForm
        formFields={[{ ...PHONE_FIELD, disabled: success }]}
        submitButtonText={submitButtonText || messages.submitButton}
        submitAction={submit}
        loading={loading}
        submitFieldErrors
        success={success}
        successText={messages.successText}
        fixedWidthButton
        trackingClassName={submitTrackingClassName}
        aria-busy={loading ? 'true' : 'false'}
        submitButtonClassName={classes.submitButton}
      >
        <Typography variant="caption" tabIndex="0" role="alert">
          {loading ? messages.loading : ''}
        </Typography>
      </CarwizForm>
    </>
  );
};

export default PhoneForm;
