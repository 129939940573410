import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import React from 'react';
import classnames from 'classnames';

const useStyles = makeStyles(theme => ({
  titleTypographyColor: {
    color: theme.palette.text.primary,
  },
  contrastText: {
    fontWeight: 'bold',
    color: theme.palette.background.paper,
  },
  titleTypography: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 38,
    [theme.breakpoints.up('md')]: {
      fontSize: 48,
    },
    marginBottom: theme.spacing(1),
  },
  subTitleTypography: {
    textAlign: 'center',
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
    marginBottom: theme.spacing(1),
  },
  titleTypographySmall: {
    fontWeight: 'bold',
    fontSize: 18,
    [theme.breakpoints.up('md')]: {
      fontSize: 24,
    },
    marginBottom: theme.spacing(0.5),
  },
  subTitleTypographySmall: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
    marginBottom: theme.spacing(0.5),
  },
  buttonSkip: {
    textDecoration: 'underline',
    padding: 0,
    minHeight: 'initial',
    fontWeight: 500,
  },
  buttonFontSmall: {
    fontSize: 14,
    [theme.breakpoints.up('md')]: {
      fontSize: 18,
    },
  },
  buttonFont: {
    fontSize: 24,
    [theme.breakpoints.down('sm')]: {
      fontSize: 18,
    },
  },
}));

const DialogHeader = ({
  title,
  subtitle,
  children,
  constrast,
  titleStyle,
  buttonLabel,
  handleButtonClick,
  titleVariant = 'h2',
  subtitleVariant = 'body1',
  subtitleClass,
  small,
  middleImageComponent,
}) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {title && (
        <Typography
          className={classnames(
            titleStyle ||
              (small ? classes.titleTypographySmall : classes.titleTypography),
            constrast ? classes.contrastText : classes.textPrimary,
          )}
          variant={titleVariant}
          tabIndex="0"
        >
          {title}
        </Typography>
      )}
      {middleImageComponent}
      {subtitle && (
        <Typography
          className={classnames(
            small
              ? classes.subTitleTypographySmall
              : classes.subTitleTypography,
            constrast ? classes.contrastText : classes.textPrimary,
            subtitleClass,
          )}
          variant={subtitleVariant}
        >
          {subtitle}
          {buttonLabel && (
            <Button
              color="primary"
              onClick={handleButtonClick}
              className={classnames(
                classes.buttonSkip,
                small ? classes.buttonFontSmall : classes.buttonFont,
              )}
            >
              {buttonLabel}
            </Button>
          )}
        </Typography>
      )}
      {children}
    </React.Fragment>
  );
};

export default DialogHeader;
