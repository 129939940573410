import React from 'react';
import { Datagrid, EditButton, List, TextField, UrlField } from 'react-admin';
import AgencyFilter from './AgencyFilter';

const AgenciesList = (props) => (
  <List
    {...props}
    filters={<AgencyFilter />}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      {/* <TextField source="id" /> */}
      <TextField source="originalId" label="ID" />
      <TextField source="name" />
      <TextField source="displayName" label="Display Name" />
      <UrlField source="website" />
      <TextField source="proPackage" label="Pro Package" />
      <EditButton />
    </Datagrid>
  </List>
);

export default AgenciesList;
