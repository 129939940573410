import React from 'react';
import { required, SimpleForm, TextInput, inpu } from 'react-admin';

function validatePhoneNumber(phoneNumber = '') {
  if (!phoneNumber.startsWith('+9725')) {
    return 'מספר טלפון חייב להתחיל ב+9725';
  }
  const pattern = /^\+9725\d{8}$/;
  if (!pattern.test(phoneNumber)) {
    return 'מספר טלפון אינו תקין';
  }
}

const LoginTestUsersMutation = ({ ...props }) => {
  return (
    <SimpleForm
      {...props}
      save={(data) => {
        if (props.id) {
          data.updatedAt = new Date();
        }
        props.save(data);
      }}
    >
      <TextInput
        label="טלפון"
        disabled={!!props.id}
        source="phone"
        dir="ltr"
        validate={[required(), validatePhoneNumber]}
      />
      <TextInput label="קוד" source="verificationCode" validate={required()} />
    </SimpleForm>
  );
};

export default LoginTestUsersMutation;
