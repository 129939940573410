import axios from 'axios';
import { prop } from 'ramda';
import {
  BQ_TO_PG_TOPIC,
  CAR_NAME_TABLE,
  CAR_NAMES_MANAGEMENT_URL
} from '../../constants/constants';

export const fetchNewCarsBulk = async (
  pageSize,
  page,
  orderByField,
  orderDesc,
  filters
) => {
  try {
    const params = new URLSearchParams();
    params.append('page', page);
    params.append('buffer', pageSize);

    if (orderByField) {
      params.append('orderBy', orderByField);
    }

    if (orderDesc) {
      params.append('orderDesc', orderDesc);
    }

    const response = await axios.post(
      `${CAR_NAMES_MANAGEMENT_URL}/new-cars-aggregate?${params.toString()}`,
      {
        filters
      }
    );

    return prop('data', response);
  } catch (error) {
    console.error('validateLink Error:', error);
    throw error;
  }
};

export const saveUpdatedCars = async (updatedCar, updatedField) => {
  try {
    const response = await axios.post(
      `${CAR_NAMES_MANAGEMENT_URL}/update-car-data`,
      {
        updatedCar,
        updatedField
      }
    );

    return prop('data', response);
  } catch (error) {
    console.error('validateLink Error:', error);
    throw error;
  }
};

export const syncBqToPgNames = async () => {
  try {
    await axios.post(`${CAR_NAMES_MANAGEMENT_URL}/run-scheduler-job`, {
      topic: BQ_TO_PG_TOPIC,
      data: {
        bigqueryDataset: 'static',
        bigqueryTable: CAR_NAME_TABLE,
        postgresTable: 'name',
        orderByParams: 'carwiz_make'
      }
    });
  } catch (error) {
    console.error('Error running scheduler:', error);
    throw error;
  }
};
