import React, { useEffect } from 'react';
import { useField, useForm } from 'react-final-form';
import { SelectInput, BooleanInput, required } from 'react-admin';
import StarwizVideoInput from './StarwizVideoInput';

const monthOptions = [
  { id: 1, name: 'ינואר' },
  { id: 2, name: 'פברואר' },
  { id: 3, name: 'מרץ' },
  { id: 4, name: 'אפריל' },
  { id: 5, name: 'מאי' },
  { id: 6, name: 'יוני' },
  { id: 7, name: 'יולי' },
  { id: 8, name: 'אוגוסט' },
  { id: 9, name: 'ספטמבר' },
  { id: 10, name: 'אוקטובר' },
  { id: 11, name: 'נובמבר' },
  { id: 12, name: 'דצמבר' }
];

const fromYear = 2023;
const toYear = new Date().getFullYear();
const yearsOptions = [];

for (let year = fromYear; year <= toYear; year += 1) {
  yearsOptions.push({ id: year, name: year });
}

const Separator = () => (
  <div style={{ width: '256px', marginBottom: '26px' }}>
    <hr />
  </div>
);

const StarwizInput = ({ record }) => {
  const {
    input: { value: isStarwizWinner }
  } = useField('starwizWinner');
  const form = useForm();

  useEffect(() => {
    if (!isStarwizWinner) {
      form.change('starwizMonth', undefined);
      form.change('starwizYear', undefined);
    }
  }, [isStarwizWinner, form]);

  return (
    <div>
      <h3>STARWIZ</h3>
      <BooleanInput label="זוכה STARWIZ" source="starwizWinner" />

      {!isStarwizWinner ? (
        <Separator />
      ) : (
        <>
          <div className="ra-input">
            <SelectInput
              allowEmpty
              validate={required()}
              label="חודש"
              source="starwizMonth"
              choices={monthOptions}
            />
          </div>
          <div className="ra-input">
            <SelectInput
              allowEmpty
              validate={required()}
              label="שנה"
              source="starwizYear"
              choices={yearsOptions}
            />
          </div>
          <StarwizVideoInput />
          <Separator />
        </>
      )}
    </div>
  );
};

export default StarwizInput;
