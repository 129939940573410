import LoginTestUsersIcon from '@material-ui/icons/LockOpen';
import LoginTestUsersCreate from './LoginTestUsersCreate';
import LoginTestUsersEdit from './LoginTestUsersEdit';
import LoginTestUsersList from './LoginTestUsersList';

export default {
  list: LoginTestUsersList,
  icon: LoginTestUsersIcon,
  edit: LoginTestUsersEdit,
  create: LoginTestUsersCreate
};
