import React from 'react';
import { prop } from 'ramda';
import DayItem from './DayItem';
import WorkingHoursList from './WorkingHoursList';
import WorkingHoursItem from './WorkingHoursItem';

const DefaultDayItem = ({
  defaultDay,
  toggleDefaultDayIsOpen,
  addDefaultHoursRange,
  removeDefaultHoursRange,
  updateDefaultHours
}) => (
  <DayItem
    day={defaultDay}
    toggleDayIsOpen={toggleDefaultDayIsOpen}
    addHoursRange={addDefaultHoursRange}
    removeHoursRange={removeDefaultHoursRange}
  >
    <WorkingHoursList
      workingHoursRanges={prop('workingHours', defaultDay)}
      renderItem={(workingHoursRange, index) => (
        <WorkingHoursItem
          key={index}
          enName={prop('enName', defaultDay)}
          workingHoursRange={workingHoursRange}
          workingHoursRangeIndex={index}
          updateHours={updateDefaultHours}
        />
      )}
    />
  </DayItem>
);

export default DefaultDayItem;
