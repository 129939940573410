import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  BooleanInput,
  SaveContextProvider,
  SimpleForm,
  Title
} from 'react-admin';
import { Box } from '@material-ui/core';
import './types';
import { getFormInput, getFormValue } from './helpers';
import HoursFormInput from './HoursFormInput';

/** @type {import('react').FunctionComponent<FormProps>} */
const Form = ({ value, onSubmit, feature, saving, title }) => {
  const [display, setDisplay] = useState(true);
  const [curValue, setCurValue] = useState(value);
  const formInput = useMemo(() => getFormInput(curValue), [curValue]);

  useEffect(() => {
    setDisplay(false);
    setTimeout(() => {
      setDisplay(true);
    }, 0);
  }, [value]);

  const handleSubmit = useCallback(
    (data) => {
      if (onSubmit) {
        const newValue = getFormValue(feature, data);
        setCurValue(newValue);
        onSubmit(newValue);
      }
    },
    [onSubmit]
  );
  return (
    <Box textAlign="left">
      <Title title={title} />
      <SaveContextProvider
        value={{
          save: handleSubmit,
          saving,
          mutationMode: 'pessimistic',
          setOnFailure: () => {}
        }}
      >
        {display && (
          <SimpleForm
            initialValues={formInput}
            save={handleSubmit}
            saving={saving}
          >
            <HoursFormInput value={formInput.hours} />
            <Box marginTop="20px">
              <BooleanInput label="פעיל" source="active" />
            </Box>
          </SimpleForm>
        )}
      </SaveContextProvider>
    </Box>
  );
};

export default Form;
