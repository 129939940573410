import { ApolloClient } from 'apollo-client';
import { HttpLink, InMemoryCache } from 'apollo-client-preset';
import { setContext } from 'apollo-link-context';
import firebase from 'firebase';

const authLink = setContext(async (_, { headers }) => {
  // get the authentication token from firebase
  await new Promise(resolve => {
    firebase.auth().onAuthStateChanged(resolve);
  });
  const currentUser = await firebase.auth().currentUser;
  const token = currentUser ? await currentUser.getIdToken() : null;
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

export default options => {
  if (!options) {
    return new ApolloClient();
  }

  const { cache, link, uri, ...otherOptions } = options;
  let finalLink = link;
  let finalCache = cache;

  if (!link && uri) {
    finalLink = new HttpLink({ uri });
  }

  if (!cache) {
    finalCache = new InMemoryCache().restore({});
  }

  return new ApolloClient({
    link: authLink.concat(finalLink),
    cache: finalCache,
    ...otherOptions,
  });
};
