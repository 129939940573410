import React from 'react';
import { Resource } from 'react-admin';
import Editor from './Editor';
import labels from '../../labels';

export const ContactWorkingHourResource = (
  <Resource
    name="MokedWorkingHour_Contact"
    list={() => (
      <Editor feature="contact" title={labels.mokedWorkingHour_contact} />
    )}
    options={{
      label: labels.mokedWorkingHour_contact,
      group: 'parameters'
    }}
  />
);

export const ChatWorkingHourResource = (
  <Resource
    name="MokedWorkingHour_Chat"
    list={() => <Editor feature="chat" title={labels.mokedWorkingHour_chat} />}
    options={{
      label: labels.mokedWorkingHour_chat,
      group: 'parameters'
    }}
  />
);
