import { gql } from 'apollo-client-preset';
import { API_URL } from '../../constants/constants';
import buildApolloClient from '../../shared-components/ra-data-postgraphile/buildApolloClient';
import './types';

const API = (feature = 'contact') => {
  const client = buildApolloClient({ uri: API_URL });

  const siteSettingsKey = `moked_working_hours.${feature}.active`;

  /** @type {(record:MokedWorkingHour) => string} */
  const createQl = (record) => `
  createMokedWorkingHour(
    input: {
      mokedWorkingHour: {
        feature: "${record.feature}"
        startTime: "${record.startTime}"
        endTime: "${record.endTime}"
        day: ${record.day}
      }
    }
  ) {
    mokedWorkingHour {
      id
    }
}`;

  const delQl = (id) => `
deleteMokedWorkingHourById(
  input: { id: "${id}" }
) {
  deletedMokedWorkingHourId
}
`;

  /**
   * @type {(current:MokedWorkingHour[], updates:MokedWorkingHour[]) => string}
   */
  const getResetQuery = (current, updated) => {
    const dels = current.map((a, i) => `del${i}: ${delQl(a.id)}`).join('\n');
    const crts = updated.map((a, i) => `crt${i}: ${createQl(a)}`).join('\n');
    return `
  mutation ResetHours {
    ${dels}
    ${crts}
  }
  `;
  };

  /**
   * @param {MokedWorkingHour[]} current
   * @param {MokedWorkingHour[]} updated
   */
  const updateWorkingHours = async (current, updated) => {
    const res = [...updated];
    const ql = getResetQuery(current, updated);
    const mutation = gql`
      ${ql}
    `;
    const result = await client.mutate({ mutation });
    for (let i = 0; i < updated.length; i += 1) {
      const wh = updated[i];
      wh.id = result.data[`crt${i}`]?.mokedWorkingHour?.id;
    }

    return res;
  };

  /** @type {() => Promise<MokedWorkingHour[]>} */
  const fetchHours = async () => {
    const query = gql`
  {
  mokedWorkingHours(condition:{feature:"${feature}"}) {
    nodes {
      nodeId
      id
      feature
      startTime
      endTime
      day
    }
  }
}
`;

    const res = await client.query({ query });
    return res.data.mokedWorkingHours.nodes;
  };

  const getActiveQuery = () => {
    const query = `
      {
        siteSettingByKey(key: "${siteSettingsKey}")  {
          value
        }
      }
    `;
    return gql`
      ${query}
    `;
  };

  /** @type {() => Promise<boolean>} */
  const getActive = async () => {
    const res = await client.query({ query: getActiveQuery() });
    return res.data?.siteSettingByKey?.value === '1';
  };

  /** @type {(disabled: boolean) => Promise} */
  const setActive = async (active) => {
    const value = active ? '1' : '0';
    const current = await client.query({ query: getActiveQuery() });

    if (current.data.siteSettingByKey === null) {
      const createQuery = `
        mutation {
          createSiteSetting(
            input: {
              siteSetting: { key: "${siteSettingsKey}", value: "${value}" }
            }
          ) {
            clientMutationId
          }
        }
      `;

      await client.mutate({
        mutation: gql`
          ${createQuery}
        `
      });
    } else {
      const updateQuery = `
        mutation {
          updateSiteSettingByKey(
            input: {
              key: "${siteSettingsKey}"
              patch: { value: "${value}" }
            }
          ) {
            siteSetting {
              value
            }
          }
        }
      `;

      await client.mutate({
        mutation: gql`
          ${updateQuery}
        `
      });
    }
  };

  return {
    fetchHours,
    updateWorkingHours,
    getActive,
    setActive
  };
};

export default API;
