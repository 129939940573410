/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  CreateButton,
  ExportButton,
  TopToolbar,
  useTranslate
} from 'react-admin';

const AgencyBranchListActions = ({
  basePath,
  displayedFilters,
  filters,
  filterValues,
  resource,
  showFilter,
  total,
  exporter
}) => {
  const translate = useTranslate();

  return (
    <TopToolbar>
      {filters &&
        React.cloneElement(filters, {
          resource,
          showFilter,
          displayedFilters,
          filterValues,
          context: 'button'
        })}

      <CreateButton basePath={basePath} label={translate('ra.action.create')} />
      <ExportButton maxResults={total} exporter={exporter} />
    </TopToolbar>
  );
};

export default AgencyBranchListActions;
