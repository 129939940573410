import * as React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  makeStyles,
  Box,
  Divider,
  Avatar,
  Tooltip
} from '@material-ui/core';
import { cond, equals, always, startsWith } from 'ramda';
import CheckIcon from '@material-ui/icons/Check';
import LinkIcon from '@material-ui/icons/Link';
import CircularProgress from '@material-ui/core/CircularProgress';
import HistoryIcon from '@material-ui/icons/History';
import CloseIcon from '@material-ui/icons/Close';
import { DataGrid } from '@material-ui/data-grid';
import useDynamicContent from '../../../utils/hooks/useDynamicContent';
import useUsersForAutoComplete from '../../../utils/hooks/useUsersForAutoComplete';
import useAgencyBranchesForAutoComplete from '../../../utils/hooks/useAgencyBranchesForAutoComplete';
import { API_URL } from '../../../constants/constants';
import { COLOR_CHOICES } from '../constants';
import useDynamicContentAd from '../../../utils/hooks/useDynamicContentAd';
import { getUIDomain } from '../../../utils/utils';

const useStyles = makeStyles({
  root: {
    padding: 20,
    height: 400 // Set a reasonable initial height
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8
  },
  headerText: {
    marginBottom: 5,
    display: 'flex', // Align icon and title horizontally
    alignItems: 'center' // Vertically center icon and title
  },
  divider: {
    // Style for the divider to make it full width
    width: '100%',
    marginBottom: '20px' // Add margin above and below for spacing
  },
  dataGridContainer: {
    // Wrap the DataGrid in a container
    width: '100%',
    overflowX: 'auto'
  },
  customHeader: {
    whiteSpace: 'normal', // Allow text to wrap
    // wordBreak: 'break-word', // Break words if necessary
    lineHeight: '1.43', // Adjust line height to match the wrapping text
    height: 'auto', // Allow the header height to adjust automatically
    fontSize: 'smaller',
    fontWeight: 'bold',
    marginLeft: '50%'
  }
});

const HistoryView = ({ open, onClose = () => {}, id = null, ...props }) => {
  const { dynamicContent } = useDynamicContent(id);
  const { dynamicContentAd } = useDynamicContentAd(id);
  const { users } = useUsersForAutoComplete(['ADMIN']);
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const classes = useStyles();
  const isAd = id.startsWith('ad');
  const adNum = isAd ? id.split('d')[1] : '';

  const getTitle = cond([
    [equals('hero'), always('ארכיון הירו')],
    [equals('banner'), always('ארכיון באנר')],
    [equals('tag'), always('ארכיון תגית')],
    [equals('filter'), always('ארכיון סנן')],
    [startsWith('ad'), always('ארכיון מודעה פרסום')],
    [always(true), always('ארכיון קרוסלה')]
  ]);

  const renderHeader = (headerName, classes) => (
    <div className={classes.customHeader}>{headerName}</div>
  );

  const title = `${getTitle(id)} ${adNum}`;

  const columns = [
    {
      field: 'campaignName',
      headerName: 'שם הקמפיין',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    },
    {
      field: 'startDate',
      headerName: 'ת. התחלה',
      flex: 1,
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        if (!row.startDate) return '-';
        return new Date(row.startDate).toLocaleDateString('he-IL');
      }
    },
    {
      field: 'endDate',
      headerName: 'ת. סיום',
      flex: 1,
      type: 'date',
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        if (!row.endDate) return '-';
        return new Date(row.endDate).toLocaleDateString('he-IL');
      }
    },
    // {
    //   field: 'status',
    //   headerName: 'סטטוס',
    //   flex: 1,
    //   align: 'center',
    //   headerAlign: 'center',
    //   renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
    //   renderCell: ({ row }) => {
    //     const getStatus = cond([
    //       [equals('active'), always('פעיל')],
    //       [equals('inactive'), always('לא פעיל')],
    //       [always(true), always('לא הוגדר')]
    //     ]);

    //     return getStatus(row.status);
    //   }
    // },
    {
      field: 'creatingUid',
      headerName: 'משתמש מעדכן',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        if (!users || !users.length) {
          return <CircularProgress size={20} />;
        }

        const user = (users || []).find((u) => u.id === row.creatingUid);

        return user?.name || '-';
      }
    }
  ];

  if (id === 'hero' || id === 'carrousel') {
    columns.push({
      field: 'header',
      headerName: 'כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'headerColor',
      headerName: 'צבע כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.headerColor);
        return color?.name || '';
      }
    });

    columns.push({
      field: 'headerSize',
      headerName: 'גודל כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'headerWeight',
      headerName: 'משקל כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });
  }

  if (id === 'hero') {
    columns.push({
      field: 'subHeader',
      headerName: 'תת כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'subHeaderColor',
      headerName: 'צבע תת כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.subHeaderColor);
        return color?.name || '';
      }
    });

    columns.push({
      field: 'subHeaderSize',
      headerName: 'גודל תת כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'subHeaderWeight',
      headerName: 'משקל תת כותרת',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });
  }

  if (id !== 'carrousel') {
    columns.push({
      field: 'desktopImageUrl',
      headerName: 'תמונה ',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        return row.desktopImageUrl ? (
          <Avatar
            src={row.desktopImageUrl}
            alt="Desktop Image"
            variant="square" // Set variant to 'square'
            style={{
              cursor: 'pointer',
              height: '100px',
              width: '100px'
            }}
          />
        ) : (
          <span>-</span>
        );
      }
    });
  }

  if (id === 'filter') {
    columns.push({
      field: 'color',
      headerName: 'צבע טוגל',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.color);
        return color?.name || '';
      }
    });
  }

  if (['banner', 'tag', 'filter'].includes(id)) {
    columns.push({
      field: 'url',
      headerName: 'לינק לבחירת רכבים',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        if (!row.url) return null;

        const baseUrl = getUIDomain() + row.url;

        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {' '}
            {/* Add a container div for alignment */}
            <a href={baseUrl} target="_blank" rel="noopener noreferrer">
              קישור
            </a>
            <LinkIcon style={{ marginRight: 5, verticalAlign: 'middle' }} />{' '}
            {/* Add the Link icon */}
          </div>
        );
      }
    });

    columns.push({
      field: 'branches',
      headerName: 'סניפים',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        if (
          !agencyBranches ||
          !agencyBranches.length ||
          !row.branches ||
          !row.branches.length
        )
          return '';

        const branchNames = row.branches.map((branchId) => {
          const branch = agencyBranches.find((b) => b.id === branchId);
          return branch ? branch.nameNoId : '';
        });

        const concatenatedString = branchNames.join(', ');
        const isTooltipNeeded = concatenatedString.length > 25; // You can adjust this threshold

        return isTooltipNeeded ? (
          <Tooltip title={concatenatedString} arrow>
            <div>{concatenatedString.slice(0, 25)}...</div>
          </Tooltip>
        ) : (
          concatenatedString
        );
      }
    });
  }

  if (id === 'banner') {
    columns.push({
      field: 'timer',
      headerName: 'קאונטר',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        return row.timer ? <CheckIcon /> : <CloseIcon />;
      }
    });
  }

  if (id === 'filter' || id === 'tag') {
    columns.push({
      field: 'text',
      headerName: 'טקסט',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });
  }

  if (isAd) {
    columns.push({
      field: 'text',
      headerName: 'טקסט מודעה',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'textColor',
      headerName: 'צבע טקסט',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.textColor);
        return color?.name || '';
      }
    });

    columns.push({
      field: 'textSize',
      headerName: 'גודל טקסט',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'textWeight',
      headerName: 'משקל טקסט',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    //
    columns.push({
      field: 'buttonText',
      headerName: 'טקסט כפתור',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'buttonTextColor',
      headerName: 'צבע טקסט כפתור',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.buttonTextColor);
        return color?.name || '';
      }
    });

    columns.push({
      field: 'buttonTextSize',
      headerName: 'גודל טקסט כפתור',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'buttonTextWeight',
      headerName: 'משקל טקסט כפתור',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });

    columns.push({
      field: 'buttonBgColor',
      headerName: 'צבע רקע כפתור',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        const color = COLOR_CHOICES.find((c) => c.id === row.buttonBgColor);
        return color?.name || '';
      }
    });
    //

    columns.push({
      field: 'clickable',
      headerName: 'קליקביליות',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes),
      renderCell: ({ row }) => {
        return row.clickable ? <CheckIcon /> : <CloseIcon />;
      }
    });

    columns.push({
      field: 'url',
      headerName: 'לינק להפנייה',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName, classes)
    });
  }

  return (
    <Dialog open={open} onClose={onClose} fullScreen>
      <DialogTitle>
        {/* Align icon and title in the same line */}
        <Box className={classes.headerText}>
          <HistoryIcon color="primary" />
          <Typography
            variant="h3"
            style={{
              marginTop: 4,
              marginRight: 5,
              color: '#519FAB',
              fontWeight: 'bold'
            }}
          >
            {title}
          </Typography>
        </Box>
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Divider className={classes.divider} />
        {/* Wrap 
        Grid in a container to control width */}
        <div className={classes.dataGridContainer}>
          <DataGrid
            rows={(isAd ? dynamicContentAd : dynamicContent) || []}
            columns={columns}
            paginationMode={null}
            pagination
            rowsPerPageOptions={['10,25,50']}
            sx={{
              border: 0,
              '& .MuiDataGrid-main': {
                border: 'none !important'
              },
              '& .MuiDataGrid-row': {
                borderBottom: '1px solid rgba(224, 224, 224, 1) !important'
              },
              '& .MuiDataGrid-columnHeader': {
                borderBottom: '1px solid rgba(224, 224, 224, 1) !important'
              },
              '& .MuiDataGrid-virtualScroller': {
                overflow: 'hidden !important'
              }
            }}
            autoHeight
            localeText={{
              MuiTablePagination: {
                backIconButtonText: 'עמוד הקודם',
                labelRowsPerPage: 'שורות לעמוד:',
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}-${to} מתוך ${count !== -1 ? count : `יותר מ-${to}`}`,
                nextIconButtonText: 'עמוד הבא'
              }
            }}
          />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HistoryView;
