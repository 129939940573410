import { path } from 'ramda';
import React, { useEffect, useState } from 'react';
import { Create } from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import AgencyMutation from './AgencyMutation';
import { lastAgencyIdQuery } from './queries';

const AgencyCreate = (props) => {
  const { token } = useFirebaseToken();
  const [latestId, setLatestId] = useState(null);

  useEffect(() => {
    const fetchLatestId = async () => {
      const result = await graphqlServiceRequest(
        token,
        lastAgencyIdQuery,
        API_URL
      );

      const resultId = path(['data', 'agencies', 'nodes', 0, 'id'], result);
      if (resultId) {
        setLatestId(resultId + 1);
      }
    };

    fetchLatestId();
  }, [token]);

  return (
    <Create {...props}>
      <AgencyMutation initialValues={{ id: latestId }} {...props} />
    </Create>
  );
};
export default AgencyCreate;
