import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyBLGoolZ-LFoSL9lHmmRrKMZzYzui84q88',
  authDomain: 'carwiz-97e29.firebaseapp.com',
  projectId: 'carwiz-97e29'
};

const configure = () => {
  firebase.initializeApp(config);
};

export default configure;
