import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import DynamicContentView from './components/DynamicContentView';

export default {
  name: 'DynamicContentView',
  list: DynamicContentView,
  icon: ViewQuiltIcon,

  options: {
    label: 'ניהול רכיבי תוכן'
  }
};
