import axios from 'axios';
import { API_URL } from '../../constants/constants';

export const getShortLink = async (originalUrl, token) => {
  try {
    const response = await axios.post(
      `${API_URL}/data/generate-short-link`,
      JSON.stringify({
        originalUrl
      }),
      {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json'
        }
      }
    );

    if (response && response.status === 200) {
      return response.data;
    }

    throw new Error('problem fetching short link');
  } catch (error) {
    throw new Error(`Error while generating new short link. ${error}`);
  }
};

export const getTelShortLink = () => {};
