import React, { useCallback, useState, useEffect } from 'react';
import {
  required,
  regex,
  SimpleForm,
  TextInput,
  useTranslate,
  useRedirect
} from 'react-admin';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CreateIcon from '@material-ui/icons/Create';
import CheckIcon from '@material-ui/icons/Check';
import { getShortLink } from './api';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { URL_REGEX } from '../../utils/utils';

const ShortLinksMutation = ({ initialValues, ...props }) => {
  const [receivedShortLink, setReceivedShortLink] = useState('');
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isCopied, setIsCopied] = useState(false);
  const [isInputValid, setIsInputValid] = useState(true);
  const { token } = useFirebaseToken();
  const translate = useTranslate();
  const redirect = useRedirect();

  const inputValidations = [
    required(),
    regex(URL_REGEX, translate('ra.resources.short_links.invalid_link'))
  ];

  const handleSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      const res = await getShortLink(userInput, token);
      setReceivedShortLink(res);
    } catch (error) {
      console.error('Failed to generate new short link:', error);
    } finally {
      setIsLoading(false);
    }
  }, [userInput]);

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(receivedShortLink);
      setIsCopied(true);
      setTimeout(() => {
        redirect('/ShortLink');
      }, 2000);
    } catch (error) {
      console.error('Failed to copy link to clipboard:', error);
    }
  };

  useEffect(() => {
    const isValid = inputValidations.every(
      (validation) => !validation(userInput)
    );
    setIsInputValid(isValid);
  }, [userInput, inputValidations]);

  return (
    <SimpleForm {...props} initialValues={initialValues} toolbar={<></>}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: 'auto !important'
        }}
      >
        {receivedShortLink ? (
          <TextInput
            source="shortenedUrl"
            label={translate('ra.resources.short_links.short_link')}
            validate={inputValidations}
            defaultValue={receivedShortLink}
            style={{
              width: '750px'
            }}
            disabled
          />
        ) : (
          <TextInput
            source="originalUrl"
            label={translate('ra.resources.short_links.original_link')}
            validate={inputValidations}
            defaultValue={userInput}
            style={{
              width: '750px'
            }}
            disabled={isLoading}
            onChange={(e) => setUserInput(e.target.value)}
          />
        )}
        <Button
          variant="contained"
          onClick={receivedShortLink ? handleCopyClick : handleSubmit}
          disabled={isLoading || !isInputValid}
          style={{
            marginTop: '8px',
            marginBottom: '4px',
            marginRight: '15px',
            height: '48px',
            background: isCopied ? '#4caf50' : '#0286A4',
            color: 'white'
          }}
          classes={{
            disabled: 'Mui-disabled-custom'
          }}
          startIcon={
            !isLoading &&
            (receivedShortLink ? (
              <>{isCopied ? <CheckIcon /> : <FileCopyIcon />}</>
            ) : (
              <CreateIcon />
            ))
          }
        >
          {isLoading ? (
            <CircularProgress size={30} style={{ color: 'white' }} />
          ) : (
            <Typography variant="span">
              {receivedShortLink ? (
                <Typography variant="span">
                  {isCopied
                    ? translate('ra.action.link_copied')
                    : translate('ra.action.copy_link')}
                </Typography>
              ) : (
                translate('ra.action.create')
              )}
            </Typography>
          )}
        </Button>
      </Box>
    </SimpleForm>
  );
};

export default ShortLinksMutation;
