import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  BooleanInput,
  EditButton,
  Create,
  required
} from 'react-admin';
import OpenHours from '../AgencyBranches/OpenHours';

export const NotificationCategorysList = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    filters={<NotificationCategoryFilter />}
    sort={{ field: 'id', order: 'ASC' }}
    perPage={25}
  >
    <Datagrid>
      <TextField source="originalId" />
      <BooleanField source="default_mail" />
      <BooleanField source="default_whatsapp" />
      <EditButton />
    </Datagrid>
  </List>
);

const NotificationCategoryFilter = (props) => (
  <Filter {...props}>
    <TextInput source="id" />
  </Filter>
);

export const NotificationCategoryEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="originalId" disabled />
        <BooleanInput source="defaultMail" />
        <BooleanInput source="defaultWhatsapp" />
        <TextInput
          label="notify hours"
          source="notifyHours"
          disabled
          multiline
        />
        <OpenHours srcName="notifyHours" label="notify hours" />
      </SimpleForm>
    </Edit>
  );
};
export const NotificationCategoryCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="id" validate={required()} />
        <BooleanInput source="defaultMail" />
        <BooleanInput source="defaultWhatsapp" />
        <TextInput
          label="notify hours"
          source="notifyHours"
          disabled
          multiline
        />
        <OpenHours srcName="notifyHours" label="notify hours" />
      </SimpleForm>
    </Create>
  );
};
