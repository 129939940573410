import axios from 'axios';
import { path, prop } from 'ramda';
import { v4 as uuidv4 } from 'uuid';

export const imageServiceRequest = (serviceName, data) =>
  axios.post(`/api/images/${serviceName}`, data);

export const getBlob = (url) =>
  axios
    .get(url, {
      responseType: 'blob',
      crossdomain: true
    })
    .then((result) => path(['data'])(result));

export const graphqlServiceRequest = (token, query, urlPrefix = '/api') =>
  axios
    .post(
      `${urlPrefix}/data/graphql`,
      { query },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((result) => path(['data'])(result));

export const carDetailsRequest = (token, plateNumber, urlPrefix = '/api') =>
  axios
    .get(
      `${urlPrefix}/data/car-details?plateNumber=${plateNumber}`,
      token
        ? {
            headers: {
              Authorization: `Bearer ${token}`
            }
          }
        : null
    )
    .then((result) => path(['data'])(result));

export const fastCheckRequest = (
  token,
  idNumber,
  ccBoldNumber,
  userHebrewName,
  urlPrefix = '/api'
) =>
  axios
    .get(
      `${urlPrefix}/data/fast-check?idNumber=${idNumber}&ccBoldNumber=${
        ccBoldNumber ? 'Y' : 'N'
      }${userHebrewName ? `&&name=${userHebrewName}` : ''}`,
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then((result) => path(['data'])(result));

export const mergeAnonymousUserRequest = (
  token,
  sourceUser,
  urlPrefix = '/api'
) =>
  axios.post(
    `${urlPrefix}/data/merge-anonymous-user`,
    { sourceUser },
    {
      headers: {
        Authorization: `Bearer ${token}`
      }
    }
  );

export const updateUserPreferencesRequest = (
  preferences,
  token,
  urlPrefix = '/api'
) =>
  token
    ? axios.post(`${urlPrefix}/data/update-user-preferences`, preferences, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
    : axios.post(`${urlPrefix}/data/update-user-preferences`, preferences);

export const getImageSignedUrl = (extension, urlPrefix = '/api', destDir) =>
  axios
    .get(
      `${urlPrefix}/functions/signed-url?fileName=${uuidv4()}.${
        extension || 'jpg'
      }${destDir ? `&destDir=${destDir}` : ''}`
    )
    .then(prop('data'))
    // eslint-disable-next-line no-useless-escape
    .then((response) => response.replace(/\"/g, ''));

export const uploadFileToSignedURL = (signedUrl, file) => {
  const config = {
    headers: {
      'Content-Type': file.type
    }
  };

  return axios.put(signedUrl, file, config);
};

export const contactUsRequest = (request, urlPrefix = '/api') =>
  axios.post(`${urlPrefix}/data/contact-us`, { request });
