import React from 'react';
import {
  Create,
  Datagrid,
  Filter,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
  EditButton,
  Edit
} from 'react-admin';

export const UserPropTypeList = (props) => (
  <List
    {...props}
    filters={<UserPropTypeFilter />}
    sort={{ field: 'type', order: 'DESC' }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      <TextField source="type" label="type" />
      <TextField source="category" label="category" />
      <TextField source="label" label="label" />
      <EditButton />
    </Datagrid>
  </List>
);

const UserPropTypeFilter = (props) => (
  <Filter {...props}>
    <TextInput source="category" />
    <TextInput source="type" />
    <TextInput source="label" />
  </Filter>
);

export const UserPropTypeCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="category" source="category" validate={required()} />
      <TextInput label="type" source="type" validate={required()} />
      <TextInput label="label" source="label" validate={required()} />
    </SimpleForm>
  </Create>
);
export const UserPropTypeEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput label="label" source="label" validate={required()} />
    </SimpleForm>
  </Edit>
);
