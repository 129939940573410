const currentYear = new Date().getFullYear();
export const YEARS = [
  currentYear,
  `${currentYear - 1} - ${currentYear - 5}`,
  `${currentYear - 6} - 2000`
];

export const interestPlanInfo = {
  1: { title: 'שנתון נוכחי', yearRange: YEARS[0] },
  2: { title: 'גיל רכב 1-5', yearRange: YEARS[1] },
  3: { title: 'גיל רכב 5 ומעלה', yearRange: YEARS[2] }
};
