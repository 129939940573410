import React, { useCallback, useEffect, useState } from 'react';
import { Switch, FormControlLabel } from '@material-ui/core';
import useSiteSettings from '../../utils/hooks/useSiteSettings';

const CATALOG_JATO_KEY = 'catalog_include_jato';

const CatalogJatoManage = () => {
  const [isJatoEnabled, setIsJatoEnabled] = useState(false);
  const { getValue, saveValue } = useSiteSettings(CATALOG_JATO_KEY);
  const parseBoolean = (value) => value === '1';

  useEffect(() => {
    const fetchInitialValue = async () => {
      try {
        const status = await getValue(); // Fetch value using useSiteSettings
        const parsedValue = parseBoolean(status);

        setIsJatoEnabled(parsedValue);
      } catch (error) {
        console.error('Failed to fetch catalog JATO initial value', error);
      }
    };

    fetchInitialValue();
  }, [getValue]);

  const handleSwitchChange = useCallback(
    async (event) => {
      const newValue = event.target.checked;

      setIsJatoEnabled(newValue);

      try {
        await saveValue(newValue ? '1' : '0'); // Save value using useSiteSettings
      } catch (error) {
        console.error('Failed to update catalog JATO status', error);
      }
    },
    [saveValue]
  );

  return (
    <FormControlLabel
      control={
        <Switch
          checked={isJatoEnabled}
          onChange={handleSwitchChange}
          color="primary"
        />
      }
      label="תמונות Jato לקטלוגי פייסבוק וטיקטוק"
    />
  );
};

export default CatalogJatoManage;
