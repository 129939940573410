export const CAPTCHA_CONTAINER_ID = 're-captcha-container';

export const prefix = `app/LOGIN_FORM/`;
export const ENTER_LOGIN_STEP = `${prefix}ENTER_LOGIN_STEP`;

export function enterLoginStep(step) {
  return {
    type: ENTER_LOGIN_STEP,
    step
  };
}
