import CircularProgress from '@material-ui/core/CircularProgress';
import Paper from '@material-ui/core/Paper';
import React, { cloneElement, useEffect, useState } from 'react';
import {
  useDataProvider,
  useNotify,
  useRedirect,
  useVersion
} from 'react-admin';

const SpecificFieldsEdit = ({
  id,
  basePath,
  resource,
  specificFields,
  children
}) => {
  const version = useVersion();
  const dataProvider = useDataProvider();
  const redirect = useRedirect();
  const notify = useNotify();
  const [record, setRecord] = useState();
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [, setError] = useState();
  useEffect(() => {
    dataProvider
      .getOne(resource, { id, specificFields })
      .then(({ data }) => {
        setRecord(data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  }, [version]);

  const save = (data) => {
    if (data.areaName) {
      data.areaName = data.areaName.trim();
    }
    if (data.name) {
      data.name = data.name.trim();
    }
    setSaving(true);
    return dataProvider
      .update(resource, {
        id: record.id,
        previousData: record,
        data
      })
      .then((response) => {
        setSaving(false);
        redirect(basePath);
        notify('ra.notification.updated');
        return response;
      })
      .catch(() => {
        setSaving(false);
        notify('ra.notification.http_error');
      });
  };

  return loading ? (
    <CircularProgress />
  ) : (
    <Paper>
      {cloneElement(children, {
        basePath,
        record,
        redirect,
        resource,
        version,
        save,
        saving
      })}
    </Paper>
  );
};

export default SpecificFieldsEdit;
