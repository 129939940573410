import { path } from 'ramda';
import React, { useCallback, useState } from 'react';
import {
  SaveButton,
  useNotify,
  useRedirect,
  useRefresh,
  useTranslate
} from 'react-admin';
import { useFormState } from 'react-final-form';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';

const buildCreateVersionPageMappingQuery = (category, columnName, label) =>
  `mutation {
        createVersionPageMapping(input: {versionPageMapping: {
            category: "${category}"
            , columnName: "${columnName}"
            , label: "${label}"
        }}){
            versionPageMapping {
              nodeId
            }
        }
    }`;

const CreateVersionPageMappingButton = ({
  handleSubmitWithRedirect,
  ...props
}) => {
  const [loading, setLoading] = useState();
  const formState = useFormState();
  const refresh = useRefresh();
  const notify = useNotify();
  const redirect = useRedirect();
  const translate = useTranslate();
  const { token } = useFirebaseToken();

  const handleClick = useCallback(async () => {
    if (!formState.valid) {
      return;
    }
    try {
      const { category, columnName, label } = formState.values;

      setLoading(true);

      const result = await graphqlServiceRequest(
        token,
        buildCreateVersionPageMappingQuery(category, columnName, label),
        API_URL
      );

      const versionPageMappingId = path(
        ['data', 'createVersionPageMapping', 'versionPageMapping', 'nodeId'],
        result
      );

      if (versionPageMappingId) {
        setLoading(false);
        refresh();
        notify(translate('ra.notification.created'));
        redirect(`/VersionPageMapping/${versionPageMappingId}`);
      } else {
        notify(translate('ra.page.error'), 'warning');
      }
    } catch (e) {
      setLoading(false);
      notify(translate('ra.page.error'), 'warning');
    }
  }, [formState.values]);

  return (
    <SaveButton
      disabled={!formState.valid}
      handleSubmitWithRedirect={handleClick}
      saving={loading}
      {...props}
    />
  );
};

export default CreateVersionPageMappingButton;
