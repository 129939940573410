export default {
  ra: {
    general: {
      name: 'שם',
      role: 'תפקיד',
      phone: 'טלפון',
      district: 'מחוז',
      address: 'כתובת',
      agency_branch: 'סניף',
      agency: 'סוכנות',
      error: 'שגיאה',
      technical_error: 'קרתה תקלה בשמירת הנתונים, אנא נסה  שוב',
      id: 'מזהה',
      year: 'שנה',
      description: 'תיאור',
      active: 'פעיל',
      inactive: 'לא פעיל'
    },
    action: {
      add_filter: 'הוסף סינון',
      add: 'הוסף',
      back: 'חזור',
      bulk_actions: 'פריט אחד נבחר |||| %{smart_count} פריטים נבחרו',
      cancel: 'ביטול',
      clear_input_value: 'נקה ערך',
      clone: 'שכפל',
      confirm: 'אשר',
      create: 'צור',
      edit: 'ערוך',
      delete: 'מחק',
      export: 'יצא',
      list: 'רשימה',
      refresh: 'רענן',
      remove_filter: 'הסר סינון',
      remove: 'מחק',
      save: 'שמור',
      search: 'חפש',
      show: 'הצג',
      sort: 'מיין',
      undo: 'בטל',
      from_date: 'החל מתאריך',
      to_date: 'עד תאריך',
      copy_link: 'העתק קישור',
      link_copied: 'קישור הועתק'
    },
    boolean: {
      true: 'כן',
      false: 'לא'
    },
    resources: {
      userProps: {
        send_email: 'שליחת הודעות באמייל',
        send_whatsapp: 'שליחת הודעות בווטסאפ'
      },
      salesforce: {
        title: 'טיוב חשבונות Salesforce',
        account: 'חשבון',
        type: 'סוג ',
        carwizName: 'סוכנות / סניף קארוויז',
        doubleClick: 'לחיצה כפולה לבחירה',
        noAccount: 'ללא חשבון',
        noAccountsFound: 'לא נמצאו חשבונות'
      },
      interaction_secondary_status: {
        seconday_status: 'אינטרקציה משנית',
        type: 'סוג אינטרקציה'
      },
      interaction_type_group: {
        name: 'שם סל',
        types: 'סטטוסים מאוגדים',
        status: 'סטטוס סל',
        errors: {
          nameExists: 'שם הסל כבר קיים בטבלת סלי האינטראקציות',
          noStatus: 'חובה לבחור לפחות סטטוס אחד'
        }
      },
      users: {
        secondary_role: 'תפקיד משני',
        mailExists: 'מייל זה כבר קיים במערכת'
      },
      branches: {
        no_working_hours: 'עליך למלא לפחות יום אחד עם שעות פעילות'
      },
      short_links: {
        original_link: 'קישור מקורי',
        short_link: 'קישור מקוצר',
        id: 'מזהה',
        invalid_link:
          'כתובת אינה חוקית. כתובת חוקית הינה בפורמט: https://example.'
      },
      upload_attempts: {
        plate_number: 'מספר רישוי',
        make_name: 'יצרן',
        model_name: 'דגם',
        finish_level: 'רמת גימור',
        created_at: 'תאריך יצירת רשומה',
        created_by: 'משתמש',
        remark: 'הערות משתמש',
        error: 'הודעת שגיאה',
        parallel_import: 'יבוא מקביל',
        hand: 'יד',
        future_tradein: 'טרייד אין עתידי',
        kilometrage: 'ק״מ',
        original_owner: 'מקוריות רכב',
        price: 'מחיר',
        supply_days: 'ימי אספקה',
        warranty: 'אחריות',
        warranty_months: 'חודשי אחריות',
        parallel_import_model: 'שם דגם',
        color: 'צבע',
        is_allowed_tradein: 'התחייבות לטרייד אין',
        commitment_to_check: 'התחייבות לבדיקה',
        no_image: 'אין תמונה',
        images: 'תמונות',
        videos: 'ווידאו',
        license: 'רישיון רכב',
        no_license: 'ללא רישיון רכב'
      },
      teams: {
        add_user: 'הוסף משתמש',
        team_name: 'שם צוות',
        total_users: 'סה״כ משתמשים',
        total_branches: 'סה״כ סניפים',
        add_success: 'הצוות נוסף בהצלחה!',
        edit_success: 'הצוות עודכן בהצלחה!',
        delete_success: 'הצוות נמחק בהצלחה!',
        team_name_exists: 'שם הצוות כבר קיים',
        cant_delete_when_users_exists:
          'לא ניתן למחוק צוות עם משתמשים המקושרים אליו',
        user_on_other_team: 'המשתמש כבר נמצא בצוות %{team}',
        team: 'צוות'
      },
      campaigns: {
        description: 'תיאור קמפיין',
        startDate: 'תאריך התחלה',
        endDate: 'תאריך סיום',
        minimumDiscount: 'מינימום הנחה',
        minimumPercentDiscount: 'מינימום אחוז הנחה',
        banner: 'באנר קמפיין',
        bannerUrl: 'באנר קמפיין url',
        mobileBannerUrl: 'מובייל באנר קמפיין url',
        icon: 'אייקון לתגית קמפיין',
        textTag: 'טקסט תגית קמפיין',
        tagUrl: 'תגית קמפיין url',
        nameLogoTag: 'שם הקמפיין לתגית',
        deleteDate: 'ביטול קמפיין',
        filterUrl: 'סננים url',
        delete: 'מחק',
        create: 'צור',
        edit: 'ערוך',
        activeCampaigns: 'קמפיינים פעילים',
        futureCampaigns: 'קמפיינים עתידיים',
        inactiveCampaigns: 'קמפיינים שעברו',
        endDateAfterStartDate: 'תאריך הסיום קטן מתאריך ההתחלה',
        overlappingDates: 'כבר קיים קמפיים בתאריכים אלו'
      }
    },
    page: {
      create: 'צור %{name}',
      dashboard: 'לוח המחוונים',
      edit: '%{name} #%{id}',
      error: 'שגיאה התרחשה',
      list: 'רשימת %{name}',
      loading: 'טוען',
      not_found: 'לא קיים',
      show: '%{name} #%{id}'
    },
    input: {
      file: {
        upload_several: 'גרור מספר קבצים להעלאה או לחץ לבחור אחד.',
        upload_single: 'גרור קובץ להעלאה או לחץ לבחור אחד.'
      },
      image: {
        upload_several: 'גרור מספר תמונות להעלאה או לחץ לבחור אחת.',
        upload_single: 'גרור תמונה להעלאה או לחץ לבחור אחת.'
      },
      references: {
        all_missing: 'ישויות הקשר לא נמצאו.',
        many_missing: 'לפחות אחת מישויות הקשר אינה זמינה יותר.',
        single_missing: 'ישות הקשר אינה זמינה יותר.'
      }
    },
    message: {
      about: 'אודות',
      are_you_sure: 'האם את/ה בטוח/ה ?',
      bulk_delete_content:
        'האם אתה בטוח שברצונך למחוק %{name}? |||| האם אתה בטוח שברצונך למחוק %{smart_count} פריטים?',
      bulk_delete_title: 'מחק %{name} |||| מחק %{smart_count} %{name} פריטים',
      delete_content: 'האם אתה בטוח שברצונך למחוק את הפריט הזה?',
      delete_title: 'מחק %{name} #%{id}',
      details: 'פרטים',
      error: 'קרתה שגיאת תקשורת והבקשה שלך לא הסתיימה.',
      invalid_form: 'הטופס אינו תקין. אנא תקן את שגיאותיך',
      loading: 'העמוד טוען, אנא המתן',
      no: 'לא',
      yes: 'כן',
      not_found: 'הכנסת כתובת שגויה או לחצת על קישור לא תקין'
    },
    navigation: {
      no_results: 'לא נמצאו תוצאות',
      no_more_results: 'עמוד %{page} מחוץ לתחום. אנא נסה את העמוד הקודם.',
      page_out_of_boundaries: 'עמוד %{page} מחוץ לתחום',
      page_out_from_end: 'לא ניתן להמשיך מעבר לעמוד האחרון',
      page_out_from_begin: 'לא ניתן לגשת לפני העמוד הראשון',
      page_range_info: '%{offsetBegin}-%{offsetEnd} מתוך %{total}',
      page_rows_per_page: 'מספר שורות לעמוד:',
      next: 'הבא',
      prev: 'הקודם'
    },
    auth: {
      user_menu: 'פרופיל',
      username: 'שם משתמש',
      password: 'סיסמא',
      sign_in: 'כניסה',
      sign_in_subtitle: 'התחברות למערכת',
      sign_in_error: 'הכניסה נכשלה, אנא נסו שנית',
      logout: 'התנתקות'
    },
    notification: {
      updated: 'פריט עודכן בהצלחה',
      created: 'פריט נוצר בהצלחה',
      deleted: 'פריט נמחק בהצלחה',
      duplicate_item: 'פריט כבר קיים במערכת',
      bad_item: 'פריט לא תקין',
      item_doesnt_exist: 'פריט לא קיים',
      http_error: 'שגיאה בהתקשרות מול השרת',
      data_provider_error: 'שגיאת dataProvider. בדוק את הקונסול לפרטים נוספים.',
      canceled: 'הפעולה בוטלה',
      logged_out: 'הנך מנותק, אנא התחבר מחדש.'
    },
    validation: {
      required: 'חובה',
      minLength: 'חייב להכיל לפחות %{min} תווים',
      maxLength: 'יכול להכיל לכל היותר %{max} תווים',
      minValue: 'חייב להיות לפחות %{min}',
      maxValue: 'חייב להיות %{max} או פחות',
      number: 'חייב להיות מספר',
      email: 'כתובת דוא"ל לא תקנית',
      oneOf: 'חייב להיות מאחת האפשרויות הבאות: %{options}',
      regex: 'חייב להתאים לתבנית ספציפית (ביטוי רגולרי): %{pattern}'
    }
  }
};
