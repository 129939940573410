import React from 'react';

import SpecificFieldsEdit from '../../components/SpecificFieldsEdit';
import AgencyBranchMutation from './AgencyBranchMutation';

const AgenciesBranchEdit = (props) => (
  <SpecificFieldsEdit
    {...props}
    specificFields={[
      'nodeId',
      'id',
      'agencyId',
      'team',
      'implementer',
      'representative',
      'areaName',
      'location',
      'manualScoreBooster',
      'phone',
      'email',
      'calendarId',
      'potentailStockAmount',
      'about',
      'address',
      'areaId',
      'calendarId',
      'city',
      'compressedVideoUrl',
      'expose',
      'fundingAdvisor',
      'isMoked',
      'isTradein',
      'isCarlandBranch',
      'latitude',
      'lngArabic',
      'lngEnglish',
      'lngRussian',
      'longitude',
      'openingHours',
      'openHours',
      'recommend',
      'reverseCall',
      'score',
      'street',
      'indexPage',
      'allowNoPlate',
      'showInFbCatalog',
      'displayCustomerVirtualPhone',
      'district',
      'virtualPhone',
      'starwizWinner',
      'starwizMonth',
      'starwizYear',
      'starwizVideo',
      'starwizVideoMobile',
      'allowStatistics',
      'implementerUid',
      'representativeUid',
      'fundingAdvisorUid',
      'updatedAt',
      'createdAt',
      'updatedManualFactorAt',
      'updatedManualFactorUser',
      'areaManager',
      'areaManagerUid'
    ]}
  >
    <AgencyBranchMutation showRelatedUsers {...props} disableId />
  </SpecificFieldsEdit>
);

export default AgenciesBranchEdit;
