// eslint-disable-next-line import/prefer-default-export
export const lastAgencyIdQuery = `query {
    agencies(
      orderBy : ID_DESC
      first : 1
      filter: { id: { notEqualTo: 10000 } }
    ) {
      nodes {
        id
      }
    }
  }
`;
