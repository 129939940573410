import { useEffect, useState } from 'react';

const useWebOtp = () => {
  const [webOtpCode, setWebOtpCode] = useState();
  useEffect(() => {
    const abortController = new AbortController();

    if ('OTPCredential' in window) {
      startListen(abortController.signal).then((code) => {
        setWebOtpCode(code);
      });
    }

    return () => {
      abortController.abort();
    };
  }, []);

  return {
    webOtpCode
  };
};

async function startListen(signal) {
  try {
    const otp = await navigator.credentials.get({
      otp: { transport: ['sms'] },
      signal: signal
    });
    return otp.code;
  } catch (error) {
    console.error('Error obtaining OTP:', error);
  }
}

export default useWebOtp;
