import { filter, map, pipe, prop } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const UseSalesForceAccountsForAutoComplete = () => {
  const [salesForceAccounts, setSalesForceAccounts] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchSalesForceAccounts = async () => {
    const { data } = await dataProvider(GET_LIST, 'SalesforceIndentifier', {
      filter: {
        specificFields: ['nodeId', 'id', 'type', 'accountName', 'sfid', 'psid']
      },
      sort: { field: 'type', order: 'ASC' },
      pagination: { page: 1, perPage: 2000 }
    });

    const mappedAccounts = pipe(
      map((account) => ({
        accountName: `${prop('accountName', account)}`,
        type: `${prop('type', account)}`,
        sfid: `${prop('sfid', account)}`,
        psid: `${prop('psid', account)}`,
        id: prop('originalId', account)
      })),
      filter(prop('id'))
    )(data);

    setSalesForceAccounts([...mappedAccounts]);
  };

  useEffect(() => {
    fetchSalesForceAccounts();
  }, [version]);

  return {
    salesForceAccounts
  };
};

export default UseSalesForceAccountsForAutoComplete;
