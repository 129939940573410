import React, { useState } from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
  makeStyles
} from '@material-ui/core';
import { useNotify, useRefresh, useTranslate, useRedirect } from 'react-admin';
import DeleteIcon from '@material-ui/icons/Delete';
import softDeleteUser from './api';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const useStyles = makeStyles((theme) => ({
  deleteButton: {
    marginLeft: theme.spacing(2) // Adds margin to the left of the delete button
  }
}));

const DeleteUserButton = ({ record }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { token } = useFirebaseToken();
  const translate = useTranslate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const handleClick = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleDelete = async () => {
    setLoading(true);

    try {
      await softDeleteUser(record.uid, token);

      notify(translate('ra.notification.deleted', 'success'));
      redirect('/User');
      refresh();
      setOpen(false);
    } catch (e) {
      console.error(e);
      notify('ra.notification.http_error', 'error');
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button
        color="primary"
        variant="contained"
        onClick={handleClick}
        startIcon={<DeleteIcon />}
        disabled={loading}
        className={classes.deleteButton}
      >
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          translate('ra.action.delete')
        )}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        disableBackdropClick={loading}
        disableEscapeKeyDown={loading}
      >
        <DialogTitle>
          {translate('ra.message.delete_title', {
            name: record.name,
            id: record.uid
          })}
        </DialogTitle>
        <DialogContent>
          {loading ? (
            <CircularProgress />
          ) : (
            translate('ra.message.are_you_sure')
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" disabled={loading}>
            {translate('ra.action.cancel')}
          </Button>
          <Button onClick={handleDelete} color="secondary" disabled={loading}>
            {translate('ra.action.delete')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DeleteUserButton;
