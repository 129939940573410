import labels from '../../labels';
import FundingCalculatorUpdateList from './FundingCalculatorUpdateList';
import { FundingCalcVariableUpdateEditList } from './FundingCalcVariableUpdateEditList';

export default {
  name: 'FundingRate',
  list: FundingCalculatorUpdateList,
  edit: FundingCalcVariableUpdateEditList,

  options: {
    label: labels.fundingCalculatorUpdatePage,
    group: 'parameters'
  }
};
