import { TypeKind } from 'graphql';
import isNotGraphqlPrivateType from './helpers/isNotGraphqlPrivateType';

export const isSubObject = field => {
  return (
    field.type.kind === TypeKind.OBJECT ||
    (field.type.kind === TypeKind.LIST &&
      (field.type.ofType.kind === TypeKind.OBJECT ||
        field.type.ofType.kind === TypeKind.LIST)) ||
    (field.type.kind === TypeKind.NON_NULL &&
      (field.type.ofType.kind === TypeKind.OBJECT ||
        field.type.ofType.kind === TypeKind.LIST))
  );
};

// const isExcludedFieldFactory = (resource, verbType, excludeFields) => field => {
//   if (!excludeFields) {
//     return false;
//   }

//   if (Array.isArray(excludeFields)) {
//     return excludeFields.includes(field.name);
//   }

//   if (typeof excludeFields === 'function') {
//     return excludeFields(field, resource, verbType);
//   }
// };

const getType = field => {
  if (
    field.type.kind === TypeKind.LIST ||
    field.type.kind === TypeKind.NON_NULL
  ) {
    return field.type.ofType;
  }

  return field.type;
};

const isResource = (field, resources) => {
  const type = getType(field);

  return resources.some(({ name }) => name === type.name);
};

export const buildFieldListFromListWithMaxDepth = (
  verbType,
  resource,
  fields,
  resources,
  types,
  maxDepth,
) =>
  fields
    .filter(isNotGraphqlPrivateType)
    .filter(field => (maxDepth === 0 ? !isSubObject(field, types) : true))
    .map(field => {
      if (isSubObject(field, types)) {
        const typeToCheck = getType(field);

        if (!isResource(field, resources)) {
          const type = types.find(t => t.name === typeToCheck.name);

          if (type) {
            const subFields = buildFieldListFromListWithMaxDepth(
              verbType,
              resource,
              type.fields,
              resources,
              types,
              maxDepth - 1,
            );
            return `${field.name} { ${subFields} }`;
          }
        }

        return false;
      }

      return field.name;
    })
    .filter(f => f !== false)
    .join(' ');

export const buildFieldListFromList = (
  verbType,
  resource,
  fields,
  resources,
  types,
) =>
  buildFieldListFromListWithMaxDepth(
    verbType,
    resource,
    fields,
    resources,
    types,
    2,
  );
export default (verbType, resource, resources, types, options) =>
  buildFieldListFromList(
    verbType,
    resource,
    resource.fields,
    resources,
    types,
    options,
  );
