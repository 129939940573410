// eslint-disable-next-line import/no-extraneous-dependencies
import gql from 'graphql-tag';
import {
  assoc,
  dissocPath,
  equals,
  has,
  isNil,
  join,
  keys,
  length,
  map,
  not,
  path,
  pipe,
  prop,
  propOr
} from 'ramda';
import {
  CREATE,
  DELETE,
  DELETE_MANY,
  GET_LIST,
  GET_MANY,
  GET_MANY_REFERENCE,
  GET_ONE,
  UPDATE
} from 'react-admin';

import { buildFieldListFromListWithMaxDepth } from './buildFieldList';

const camelToSake = (camelString) =>
  camelString.replace(/[\w]([A-Z])/g, (m) => `${m[0]}_${m[1]}`).toUpperCase();

// const toStringify = (value, isString) =>
//   Array.isArray(value)
//     ? `[${value.map(v => (isString ? `"${v.toString()}"` : v.toString()))}]`
//     : value.toString();

const stringifyValue = (value) => `"${String(value)}"`;

const stringifyFieldValue = (key, value, fields) => {
  if (
    value === 'true' ||
    value === 'false' ||
    value === true ||
    value === false
  ) {
    return Boolean(value);
  }

  const field = fields.find((f) => f.name === key);
  const fieldType = field.type;
  switch (`${fieldType.kind}:${fieldType.name}`) {
    case 'SCALAR:Int':
      return equals('', value) ? null : Number(value);

    case 'SCALAR:String':
      if (typeof value === 'boolean') {
        return `${value}`;
      }

      return Array.isArray(value)
        ? `[${map(stringifyValue, value)}]`
        : stringifyValue(value);

    case 'SCALAR:Boolean':
      return Boolean(value);
    case 'SCALAR:Date':
    case 'SCALAR:Datetime':
      return value instanceof Date
        ? stringifyValue(value.toISOString())
        : stringifyValue(value);
    case 'NON_NULL:null':
      return Array.isArray(value)
        ? `[${pipe(
            map((innerVal) =>
              stringifyFieldValue(key, innerVal, [
                assoc('type', fieldType.ofType, field)
              ])
            ),
            join(',')
          )(value)}]`
        : stringifyFieldValue(key, value, [
            assoc('type', fieldType.ofType, field)
          ]);
    case 'SCALAR:JSON':
      return JSON.stringify(value).replace(/"(\w+)"\s*:/g, '$1:');

    default:
      return Array.isArray(value) ? `[${String(value)}]` : String(value);
  }
};

const stringifyFieldParameter = (key, value, fields) => {
  const field = fields.find((f) => f.name === key);
  const fieldType = field.type;

  switch (`${fieldType.kind}:${fieldType.name}`) {
    case 'SCALAR:Int':
      return 'in';

    case 'NON_NULL:null': {
      return Array.isArray(value) ? 'in' : 'equalTo';
    }

    case 'SCALAR:String':
      return 'includes';

    case 'SCALAR:Boolean':
      return 'equalTo';

    default:
      return 'equalTo';
  }
};
const getPaginationQuery = (params) =>
  params.pagination
    ? `first: ${params.pagination.perPage} offset: ${
        (params.pagination.page - 1) * params.pagination.perPage
      }`
    : '';

const getSortQuery = (params) =>
  params.sort
    ? `orderBy: ${camelToSake(params.sort.field)}_${params.sort.order}`
    : ' ';

const stringifyFilter = (key, value, fields, filterType) => {
  return `${key} : {
      ${filterType} : ${stringifyFieldValue(key, value, fields)}
  }`;
};
const stringifyArrayFilter = (key, value, fields) => {
  return `${key} : {
    ${map(
      (currFilter) => `
      ${prop('filter', currFilter)} : ${stringifyFieldValue(
        key,
        prop('value', currFilter),
        fields
      )}
    `,
      value
    )}
  }`;
};

const getFilterQuery = (params, fields) => {
  const filter = prop('filter', params);
  const overrideFilter = prop('overrideFilter', params);

  if (length(keys(filter)) === 0) {
    return '';
  }

  return `filter: { ${map((key) => {
    const filterValue = prop(key, filter);

    if (
      typeof filterValue === 'object' &&
      has('filter', filterValue) &&
      has('value', filterValue)
    ) {
      const filterType = prop('filter', filterValue);
      const value = prop('value', filterValue);
      if (filterType === 'array') {
        return stringifyArrayFilter(key, value, fields);
      }

      return stringifyFilter(key, value, fields, filterType);
    }

    return stringifyFilter(
      key,
      filterValue,
      fields,
      overrideFilter || stringifyFieldParameter(key, filterValue, fields)
    );
  }, keys(filter))} }`;
};

const buildPatchList = (params) => {
  const temp = {};
  Object.keys(params.data).forEach((key) => {
    if (
      key in params.previousData &&
      not(equals(params.previousData[key], params.data[key])) &&
      key !== 'originalId'
    ) {
      temp[key] = params.data[key];
    }
  });
  return temp;
};

const createReturnObject = (resultData, id) => {
  const originalId = propOr({}, 'id', resultData);
  return {
    data: Object.assign(
      { ...resultData },
      { id },
      resultData && has('originalId', resultData) ? null : { originalId }
    )
  };
};

const handleUpdateQuery = (queryName, params, resource) => {
  return {
    query: gql`mutation updateMutation($var:${queryName.replace(/^\w/, (c) =>
      c.toUpperCase()
    )}Input!)  {
      ${queryName} ( input: $var ) {
          ${resource[GET_ONE].name} {
            nodeId
            ${params.specificFields ? params.specificFields.join(' ') : ''}
          }
        }
      }
    `,
    variables: {
      var: {
        nodeId: path(['data', 'nodeId'], params),
        patch: buildPatchList(params, resource.type.fields)
      }
    },
    parseResponse: (response) => {
      const data = response.data[queryName];
      return createReturnObject(data, data[resource[GET_ONE].name].nodeId);
    }
  };
};

// const handleDeleteOneQuery = (queryName, params, resource) => {
//   return {
//     query: gql`mutation ${queryName}($input: ${this.deleteResourceInputName}!) {
//       ${this.deleteResourceName}(input: $input) {
//       ${this.queryTypeName} {
//       ${this.createQueryFromType(DELETE)}
//     }}}`,
//     variables: {input: {
//       id: this.idConverter(params.id),
//     }},
//     parseResponse: (response: Response) => {
//       return {
//         data: this.prepareForReactAdmin(
//           response.data[this.deleteResourceName][this.queryTypeName],
//         ),
//       }
//     },
//   }
// }

const handleDeleteQuery = (queryName, params, resource) => {
  return {
    query: gql`mutation {
      ${queryName}(
          input: { nodeId: "${params.id}" }
        ) {
          ${resource[GET_ONE].name} {
            nodeId
            ${params.specificFields ? params.specificFields.join(' ') : ''}
          }
        }
      }
    `,
    variables: params,
    parseResponse: (response) => {
      return { data: [response.data[queryName]] };
    }
  };
};

const handleCreateQuery = (queryName, params, resource) => {
  return {
    query: gql`mutation createMutation($var:${queryName.replace(/^\w/, (c) =>
      c.toUpperCase()
    )}Input!) {
    ${queryName}(
        input:$var
        )
          {
            ${resource[GET_ONE].name} {
              nodeId
            }
          }
    }
  `,
    variables: { var: { [resource[GET_ONE].name]: params.data } },
    parseResponse: (response) => {
      const data = response.data[queryName];
      return createReturnObject(data, data[resource[GET_ONE].name].nodeId);
    }
  };
};

const handleGetOneQuery = (
  queryName,
  params,
  resource,
  introspectionResults,
  raFetchType
) => {
  return {
    query: gql`query {
                ${queryName}(nodeId: "${params.id}") {
                ${buildFieldListFromListWithMaxDepth(
                  raFetchType,
                  resource,
                  prop('specificFields', params)
                    ? resource.type.fields.filter((r) =>
                        params.specificFields.includes(r.name)
                      )
                    : resource.type.fields,
                  introspectionResults.resources,
                  introspectionResults.types,
                  2
                )}
              }
            }`,
    variables: params,
    parseResponse: (response) => {
      const data = response.data[queryName];
      return createReturnObject(data, prop('nodeId', data));
    }
  };
};

const handleGetListQuery = (
  queryName,
  params,
  resource,
  introspectionResults,
  raFetchType,
  keepOriginalId
) => {
  return {
    query: gql`query {
              ${queryName} (
                ${getPaginationQuery(params)}
                ${getSortQuery(params)}
                ${
                  has('filter', params)
                    ? getFilterQuery(params, resource.type.fields)
                    : ''
                }
                )
                {
                edges {
                  node {
                    ${buildFieldListFromListWithMaxDepth(
                      raFetchType,
                      resource,
                      prop('specificFields', params)
                        ? resource.type.fields.filter((r) =>
                            params.specificFields.includes(r.name)
                          )
                        : resource.type.fields,
                      introspectionResults.resources,
                      introspectionResults.types,
                      1
                    )}
                  }
                }
                totalCount
              }
            }`,
    variables: params,
    parseResponse: (response) => {
      const data = response.data[queryName].edges.map((node) => {
        const body = prop('node', node);

        if (isNil(body)) {
          return null;
        }

        const originalId = prop('id', body);
        return keepOriginalId
          ? body
          : Object.assign(
              body || {},
              { id: prop('nodeId', body) },
              prop('originalId', body) ? null : { originalId }
            );
      });
      const total = response.data[queryName].totalCount;
      const parsedResponse = {
        data,
        total
      };
      return parsedResponse;
    }
  };
};

// eslint-disable-next-line import/prefer-default-export
export const buildQuery =
  (introspectionResults) => (raFetchType, resourceName, params) => {
    const resource = introspectionResults.resources.find(
      (r) => r.type.name === resourceName
    );
    const queryName = resource[raFetchType].name;

    switch (raFetchType) {
      case UPDATE:
        return handleUpdateQuery(
          queryName,
          params,
          resource,
          introspectionResults,
          raFetchType
        );
      case GET_ONE:
        return handleGetOneQuery(
          queryName,
          params,
          resource,
          introspectionResults,
          raFetchType
        );
      case GET_LIST:
        return handleGetListQuery(
          queryName,
          {
            specificFields: path(['filter', 'specificFields'], params),
            overrideFilter: path(['filter', 'overrideFilter'], params),
            ...pipe(
              dissocPath(['filter', 'specificFields']),
              dissocPath(['filter', 'overrideFilter'])
            )(params)
          },
          resource,
          introspectionResults,
          raFetchType
        );
      case GET_MANY_REFERENCE:
        return handleGetListQuery(
          queryName,
          {
            specificFields: params.filter.specificFields,
            ...params,
            filter: {
              [params.target]: params.id,
              ...pipe(
                dissocPath(['filter', 'specificFields']),
                prop('filter')
              )(params)
            }
          },
          resource,
          introspectionResults,
          raFetchType
        );
      case GET_MANY:
        return handleGetListQuery(
          queryName,
          {
            ...params,
            filter: {
              id: params.ids
            },
            overrideFilter: 'in'
          },
          resource,
          introspectionResults,
          raFetchType,
          true
        );
      case CREATE:
        return handleCreateQuery(
          queryName,
          params,
          resource,
          introspectionResults,
          raFetchType
        );
      case DELETE_MANY:
        return handleDeleteQuery(
          queryName,
          params,
          resource,
          introspectionResults,
          raFetchType
        );
      case DELETE:
        return handleDeleteQuery(
          queryName,
          params,
          resource,
          introspectionResults,
          raFetchType
        );
      default:
        throw new Error(
          `${raFetchType} is not implemented for type "${resourceName}"`
        );
    }
  };
