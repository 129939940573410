import DistrictsIcon from '@material-ui/icons/LocationOn';
import DistrictsList from './DistrictsList';
// import DistrictsEdit from './DistrictsEdit';
// import DistrictsCreate from './DistrictsCreate';

export default {
  list: DistrictsList,
  icon: DistrictsIcon
  // edit: DistrictsEdit,
  // create: DistrictsCreate
};
