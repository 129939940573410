const CAR_COLUMNS = [
  {
    field: 'id',
    headerName: 'id',
    flex: 0.5,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    hide: true,
    renderCell: ({ row }) => row.id || 'N/A'
  },
  {
    field: 'dmv_make',
    headerName: 'DMV Make',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => row.dmv_make || 'N/A'
  },
  {
    field: 'dmv_model',
    headerName: 'DMV Model',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => row.dmv_model || 'N/A'
  },
  {
    field: 'dmv_finish_level',
    headerName: 'DMV Finish Level',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    renderCell: ({ row }) => row.dmv_finish_level || 'N/A'
  },
  {
    field: 'carwiz_make',
    headerName: 'Carwiz Make',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    editable: true,
    renderCell: ({ row }) => row.carwiz_make || 'N/A'
  },
  {
    field: 'carwiz_model',
    headerName: 'Carwiz Model',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    editable: true,
    renderCell: ({ row }) => row.carwiz_model || 'N/A'
  },
  {
    field: 'carwiz_submodel',
    headerName: 'Carwiz Submodel',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    editable: true,
    renderCell: ({ row }) => row.carwiz_submodel || 'N/A'
  },
  {
    field: 'carwiz_finish_level',
    headerName: 'Carwiz Finish Level',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    editable: true,
    renderCell: ({ row }) => row.carwiz_finish_level || 'N/A'
  },
  {
    field: 'carwiz_segment',
    headerName: 'Carwiz Segment',
    flex: 1,
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    editable: true,
    renderCell: ({ row }) => row.carwiz_segment || 'N/A'
  }
];

export default CAR_COLUMNS;
