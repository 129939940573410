export const TABLE_CHOICES = [
  { id: 'users', name: 'משתמשים' },
  { id: 'car', name: 'רכבים' },
  { id: 'teams', name: 'צוותים' },
  { id: 'agency', name: 'סוכניות' },
  { id: 'agency_branch', name: 'סניפים' },
  // { id: 'campaign', name: 'קמפיינים' },
  { id: 'sale', name: 'מבצעים' },
  { id: 'cars_in_sale', name: 'רכבים במבצע' },
  { id: 'moked_working_hours', name: 'שעות מוקד' },
  { id: 'funding_rates', name: 'אחוזי מימון' },
  { id: 'interaction_type', name: 'סטטוסים' }
];

export const OPERATIONS = {
  update: 'עדכון',
  insert: 'הוספה',
  delete: 'מחיקה'
};
