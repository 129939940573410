import AgencyIcon from '@material-ui/icons/Work';
import AgencyCreate from './AgencyCreate';
import AgencyEdit from './AgencyEdit';
import AgenciesList from './AgencyList';

export default {
  list: AgenciesList,
  icon: AgencyIcon,
  edit: AgencyEdit,
  create: AgencyCreate
};
