import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import { Document, Page, pdfjs } from 'react-pdf';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const PDFViewer = ({
  pdfFile,
  withModal = true,
  buttonText = 'Open PDF',
  ...props
}) => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [open, setOpen] = useState(false);

  function onDocumentLoadSuccess({ numPages: pages }) {
    setNumPages(pages);
  }

  const PDFDocument = ({ pdfFile: file }) => {
    return (
      <>
        <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
          <Page pageNumber={pageNumber} />
        </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
      </>
    );
  };

  return (
    <div>
      {withModal ? (
        <>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<PictureAsPdfIcon />}
            onClick={() => setOpen(true)}
          >
            {buttonText}
          </Button>
          <Dialog open={open} onClose={() => setOpen(false)} maxWidth="lg">
            <PDFDocument pdfFile={pdfFile} />
          </Dialog>
        </>
      ) : (
        <PDFDocument pdfFile={pdfFile} />
      )}
    </div>
  );
};

export default PDFViewer;
