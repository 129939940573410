// eslint-disable-next-line import/prefer-default-export
export const districtsQuery = `query {
    districts{
      nodes{
        id: district
				name:district
      }
    }
  }
`;
