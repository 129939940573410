import React from 'react';
import {
  Datagrid,
  // EditButton,
  Filter,
  List,
  TextField,
  TextInput
} from 'react-admin';

const DistrictsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="district" label="מחוז" />
  </Filter>
);

const DistrictsList = (props) => (
  <List
    {...props}
    sort={{ field: 'district', order: 'ASC' }}
    bulkActionButtons={false}
    filters={<DistrictsFilter />}
    perPage={25}
  >
    <Datagrid>
      <TextField source="district" label="מחוז" />
      {/* <EditButton /> */}
    </Datagrid>
  </List>
);

export default DistrictsList;
