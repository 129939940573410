import React from 'react';
import { Edit } from 'react-admin';
import CitiesMutation from './CitiesMutation';

const CitiesEdit = (props) => (
  <Edit {...props}>
    <CitiesMutation {...props} />
  </Edit>
);

export default CitiesEdit;
