/* eslint-disable no-else-return */
import {
  AUTH_LOGIN,
  AUTH_LOGOUT,
  AUTH_CHECK,
  AUTH_GET_PERMISSIONS
} from 'react-admin';
import firebase from 'firebase';
import { mergeDeepRight, includes, prop, not, isNil, path } from 'ramda';
import axios from 'axios';
import configureFirebase from './configureFirebase';
import { API_URL } from '../constants/constants';

configureFirebase();

const request = (token, query) =>
  axios
    .post(
      API_URL,
      { query },
      {
        headers: {
          Authorization: `Bearer ${token}`
        }
      }
    )
    .then(prop('data'));

const baseConfig = {
  admin: {
    getProfile: async (token) => {
      const profile = await request(
        token,
        `{
        profile {
          name
          email
          role
          phone
          uid
        }
      }`
      ).then(path(['data', 'profile']));
      return profile;
    },
    validate: (profile) => includes(prop('role', profile), ['ADMIN'])
  },
  keys: {
    permissions: 'user',
    token: 'token'
  },
  // eslint-disable-next-line consistent-return
  handleAuthStateChange: async (auth, config) => {
    if (auth) {
      const userAuth = auth.user || auth;
      const token = await userAuth.getIdToken(true);
      const profile = await config.admin.getProfile(token);

      if (not(isNil(profile)) && config.admin.validate(profile)) {
        localStorage.setItem(config.keys.permissions, JSON.stringify(profile));
        return profile;
      } else {
        firebase.auth().signOut();
        return Promise.reject(
          new Error("Oops! You don't seem to be a authorized user")
        );
      }
    }
  }
};

export default (config = {}) => {
  // eslint-disable-next-line no-param-reassign
  config = mergeDeepRight(baseConfig, config);

  const firebaseLoaded = () =>
    new Promise((resolve) => {
      firebase.auth().onAuthStateChanged(resolve);
    }).then((res) => {
      if (!res) {
        localStorage.removeItem(config.keys.permissions);
      }
      return res;
    });

  const provider = async (type, params) => {
    if (type === AUTH_LOGOUT) {
      await config.handleAuthStateChange(null, config);
      return firebase.auth().signOut();
    }

    if (firebase.auth().currentUser) {
      await firebase.auth().currentUser.reload();
    }

    if (type === AUTH_CHECK) {
      await firebaseLoaded();

      if (!firebase.auth().currentUser) {
        return Promise.reject(
          new Error("Oops! You don't seem to be signed in.")
        );
      }

      return true;
    }

    if (type === AUTH_LOGIN) {
      const { user } = params;

      return config.handleAuthStateChange(user, config);
    }

    if (type === AUTH_GET_PERMISSIONS) {
      const data = localStorage.getItem(config.keys.permissions);
      return data
        ? Promise.resolve(JSON.parse(data))
        : Promise.reject(new Error('Could not get permissions'));
    }

    return false;
  };

  return {
    provider,
    firebaseLoaded
  };
};
