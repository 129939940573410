import { AUDIT_URL } from '../../constants/constants';

export default async function fetchAuditLogs(token, filter) {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`
  });

  const response = await fetch(`${AUDIT_URL}/get-audit-logs`, {
    method: 'POST',
    headers,
    body: JSON.stringify(filter)
  });

  if (!response.ok) {
    throw new Error(`get-audit-logs http error! Status: ${response.status}`);
  } else {
    const responseData = await response.json();

    return {
      logs: responseData?.logs || [],
      total: responseData?.total || 0
    };
  }
}
