import React from 'react';
import { Typography, Grid, Button } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ErrorIcon from '@material-ui/icons/Error';
import messages from './messages';

const styles = (theme) => ({
  root: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1)
  },
  title: {
    fontSize: 18,
    fontWeight: 'bold'
  },
  tips: {
    fontSize: 14
  },
  containerRoot: {
    padding: 0
  },
  errorIcon: {
    fontSize: 30,
    color: 'red'
  },
  tipsSection: {
    marginTop: theme.spacing(1.5)
  }
});

const UnauthorizedLogin = ({ classes, title, tipsTitle, resetPhone }) => (
  <Grid container className={classes.containerRoot} alignItems="center">
    <Grid item md={1} xs={2}>
      <ErrorIcon className={classes.errorIcon} />
    </Grid>
    <Grid item md={11} xs={10}>
      <Typography className={classes.title} variant="title">
        {title || messages.title}
      </Typography>
    </Grid>
    <Grid item md={1} xs />
    <Grid item md={11} xs={12} className={classes.tipsSection}>
      <Typography className={classes.tips}>
        {tipsTitle || messages.tipsTitle}
      </Typography>
      <Typography className={classes.tips}>
        <ul>
          <li>
            <Button color="primary" onClick={resetPhone}>
              {messages.tips[0]}
            </Button>
          </li>
          <li>{messages.tips[1]}</li>
        </ul>
      </Typography>
    </Grid>
  </Grid>
);

UnauthorizedLogin.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(UnauthorizedLogin);
