import React from 'react';
import {
  BooleanField,
  Datagrid,
  DateField,
  List,
  ShowButton,
  TextField,
  useTranslate
} from 'react-admin';

import UploadAttemptsFilter from './UploadAttemptsFilter';

const UploadAttemptsList = (props) => {
  const translate = useTranslate();

  return (
    <List
      {...props}
      sort={{ field: 'createdAt', order: 'DESC' }}
      filters={<UploadAttemptsFilter />}
      bulkActionButtons={false}
      perPage={25}
    >
      <Datagrid size="small">
        <TextField source="uid" label={translate('ra.general.id')} />
        <TextField
          source="plateNumber"
          label={translate('ra.resources.upload_attempts.plate_number')}
        />
        <TextField
          source="makeName"
          label={translate('ra.resources.upload_attempts.make_name')}
        />
        <TextField
          source="modelName"
          label={translate('ra.resources.upload_attempts.model_name')}
        />
        <TextField source="year" label={translate('ra.general.year')} />
        <TextField
          source="finishLevel"
          label={translate('ra.resources.upload_attempts.finish_level')}
        />
        <DateField
          source="createdAt"
          locales="he-IL"
          label={translate('ra.resources.upload_attempts.created_at')}
        />
        <TextField
          source="error"
          label={translate('ra.resources.upload_attempts.error')}
        />
        <TextField
          source="createdBy"
          label={translate('ra.resources.upload_attempts.created_by')}
        />
        <TextField
          source="branchNameFull"
          label={translate('ra.general.agency_branch')}
        />
        <TextField source="agencyName" label={translate('ra.general.agency')} />
        <TextField
          source="userRemark"
          label={translate('ra.resources.upload_attempts.remark')}
        />
        <BooleanField
          source="parallelImport"
          label={translate('ra.resources.upload_attempts.parallel_import')}
        />
        <ShowButton />
        {/* <EditButton /> */}
      </Datagrid>
    </List>
  );
};

export default UploadAttemptsList;
