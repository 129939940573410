import { Box, Toolbar } from '@material-ui/core';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import LoadingButton from './LoadingButton';
import OrderBySection from './OrderBySection';
import FilterSection from './FilterSection';

const CustomToolbar = ({
  onSync,
  isSyncLoading,
  fieldOptions,
  handleToolbarChange
}) => {
  const [orderData, setOrderData] = useState({});
  const [filters, setFilters] = useState([]);

  const fieldsNames = useMemo(
    () => fieldOptions.map((field) => field.id),
    [fieldOptions]
  );

  useEffect(() => {
    const { orderByField, orderDesc } = orderData;

    handleToolbarChange(orderByField, orderDesc, filters);
  }, [orderData, filters, handleToolbarChange]);

  const onSubmitOrder = useCallback((orderByField, orderDesc) => {
    setOrderData({ orderByField, orderDesc });
  }, []);

  const onSubmitFilter = useCallback((selectedFilters) => {
    setFilters(selectedFilters);
  }, []);

  return (
    <Toolbar style={{ padding: '0 5px 5px 5px' }}>
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
      >
        <LoadingButton
          isLoading={isSyncLoading}
          onSync={onSync}
          type="button"
          color="secondary"
          variant="contained"
        >
          סנכרן לאתר
        </LoadingButton>
        <OrderBySection fieldsOptions={fieldOptions} onSubmit={onSubmitOrder} />
        <FilterSection fieldsOptions={fieldsNames} onSubmit={onSubmitFilter} />
      </Box>
    </Toolbar>
  );
};

export default CustomToolbar;
