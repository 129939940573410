import React from 'react';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';
import { path } from 'ramda';
import messages from './messages';

const styles = (theme) => ({
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    display: 'flex',
    justifyContent: 'center'
  },

  buttonReset: {
    width: '100%'
  }
});

const StepperActions = ({
  classes,
  stepIndex,
  steps,
  disableNext,
  disableBack,
  handleBack,
  handleNext,
  handleReset,
  withoutReset,
  children
}) => (
  <div className={classes.actionsContainer}>
    {steps.length === stepIndex ? (
      <>
        {!withoutReset && (
          <Button
            color="default"
            variant="contained"
            onClick={handleReset}
            className={classes.buttonReset}
          >
            {messages.reset}
          </Button>
        )}
      </>
    ) : (
      !path([stepIndex, 'withoutActions'], steps) && (
        <>
          <Button
            disabled={disableBack || stepIndex === 0}
            onClick={handleBack}
            className={classes.button}
          >
            {messages.back}
          </Button>
          <Button
            disabled={disableNext}
            variant="contained"
            color="primary"
            onClick={handleNext}
            className={classes.button}
            type="submit"
          >
            {stepIndex === steps.length - 1 ? messages.finish : messages.next}
          </Button>
        </>
      )
    )}
    {children}
  </div>
);

StepperActions.defaultProps = {
  stepIndex: 0,
  steps: []
};

export default withStyles(styles)(StepperActions);
