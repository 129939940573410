/* eslint-disable import/prefer-default-export */

/**
 * @param {ResourceDefinition} resource
 */
export function isReourceVisible(resource) {
  return (
    resource.hasCreate ||
    resource.hasEdit ||
    resource.hasList ||
    resource.hasShow
  );
}

/**
 * @param {ResourceDefinition[]} resources
 * @returns {MenuItem[]}
 */
export function getMenuItems(resources) {
  /** @type {MenuItem[]} */
  const menuItems = [];
  /** @type {{[key:string]: GroupedMenuItem}} */
  const groups = {};

  for (let i = 0; i < resources.length; i += 1) {
    const resource = resources[i];

    if (isReourceVisible(resource)) {
      const groupName = resource?.options?.group;
      if (!groupName) {
        menuItems.push({ type: 'single', resource });
      } else {
        const group = groups[groupName];
        if (!group) {
          /** @type {GroupedMenuItem} */
          const newGroup = {
            type: 'group',
            resources: [resource],
            name: groupName
          };
          menuItems.push(newGroup);
          groups[groupName] = newGroup;
        } else {
          group.resources.push(resource);
        }
      }
    }
  }

  return menuItems;
}

/**
 * @typedef SingleMenuItem
 * @property {"single"} type
 * @property {ResourceDefinition} resource
 *
 * @typedef GroupedMenuItem
 * @property {"group"} type
 * @property {string} name
 * @property {ResourceDefinition[]} resources
 *
 * @typedef { SingleMenuItem | GroupedMenuItem } MenuItem
 */
