import React, { useCallback, useState } from 'react';
import { Button, CircularProgress, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const NoTransition = (props) => props.children;

const LoadingButton = ({ isLoading, onSync, children, ...props }) => {
  const [open, setOpen] = useState(false);
  const [warningOpen, setWarningOpen] = useState(false);
  const [cooldown, setCooldown] = useState(false);

  const handleClick = useCallback(async () => {
    if (cooldown) {
      setOpen(false);
      setWarningOpen(true);
      return;
    }

    try {
      await onSync();
      setOpen(true);
      setCooldown(true); // Set cooldown to true after successful sync
      setTimeout(() => setCooldown(false), 300000); // Reset cooldown after 5 minutes (300000 ms)
    } catch (error) {
      console.error('Error during sync:', error);
    }
  }, [onSync]);

  const handleClose = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  }, []);

  const handleCloseWarning = useCallback((event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setWarningOpen(false);
  }, []);

  return (
    <>
      <Button
        {...props}
        disabled={isLoading}
        onClick={handleClick}
        style={{ width: '110px' }}
      >
        {isLoading ? <CircularProgress size={24} /> : children}
      </Button>
      <Snackbar
        open={open}
        autoHideDuration={6000}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={NoTransition} // Specifying Grow transition explicitly
      >
        <Alert onClose={handleClose} severity="success">
          הסנכרון בוצע, תהליך זה עשוי להימשך כמה דקות
        </Alert>
      </Snackbar>
      <Snackbar
        open={warningOpen}
        autoHideDuration={6000}
        onClose={handleCloseWarning}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        TransitionComponent={NoTransition} // Specifying Grow transition explicitly
      >
        <Alert onClose={handleCloseWarning} severity="info">
          אנא נסה שנית בעוד מספר דקות
        </Alert>
      </Snackbar>
    </>
  );
};

export default LoadingButton;
