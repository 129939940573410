import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  DeleteButton,
  Edit,
  SimpleForm,
  TextInput,
  Filter,
  EditButton,
  Create,
  ImageField
} from 'react-admin';

export const FeaturedModelsList = (props) => (
  <List
    bulkActionButtons={false}
    {...props}
    filters={<FeaturedModelsFilter />}
    sort={{ field: 'makeName', order: 'ASC' }}
    perPage={25}
  >
    <Datagrid>
      <TextField source="makeName" />
      <TextField source="modelName" />
      <TextField source="startYear" />
      <TextField source="endYear" />
      <ImageField source="imageUrl" />
      <EditButton />
      <DeleteButton />
    </Datagrid>
  </List>
);

const FeaturedModelsFilter = (props) => (
  <Filter {...props}>
    <TextInput source="makeName" />
    <TextInput source="modelName" />
    <TextInput source="startYear" />
    <TextInput source="endYear" />
  </Filter>
);

export const FeaturedModelsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="originalId" disabled />
        <TextInput source="makeName" />
        <TextInput source="modelName" />
        <TextInput source="startYear" />
        <TextInput source="endYear" />
        <TextInput source="imageUrl" />
      </SimpleForm>
    </Edit>
  );
};
export const FeaturedModelsCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm>
        <TextInput source="makeName" />
        <TextInput source="modelName" />
        <TextInput source="startYear" />
        <TextInput source="endYear" />
        <TextInput source="imageUrl" />
      </SimpleForm>
    </Create>
  );
};
