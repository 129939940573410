import labels from '../../labels';
import CatalogJatoManage from './CatalogJatoManage';

export default {
  name: 'CatalogJato',
  list: CatalogJatoManage,

  options: {
    label: labels.catalogJato,
    group: 'parameters'
  }
};
