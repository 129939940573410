import { useCallback, useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import useApi from './useApi';

const useEditor = (feature) => {
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const notify = useNotify();
  /** @type {ReturnType<typeof useState<FormValue>>} */
  const [formValue, setFormValue] = useState({ hours: [], active: true });
  const api = useApi(feature);

  useEffect(() => {
    const init = async () => {
      try {
        setLoading(true);
        const value = await api.getFormValue();
        setFormValue(value);
      } catch (error) {
        notify('ra.notification.http_error', { type: 'error' });
      } finally {
        setLoading(false);
      }
    };
    init();
  }, []);

  /** @type {(value:FormValue) => Promise} */
  const save = useCallback(
    async (updated) => {
      setSaving(true);
      try {
        const newValue = await api.updateFormValue(formValue, updated);
        setFormValue(newValue);
        notify('ra.notification.updated', { type: 'success' });
      } catch (error) {
        notify('ra.notification.http_error', { type: 'error' });
      }
      setSaving(false);
    },
    [api]
  );

  return {
    formValue,
    loading,
    save,
    saving
  };
};

export default useEditor;
