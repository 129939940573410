import React, { useRef } from 'react';
import { useField } from 'react-final-form';
import { pipe, split, remove, adjust, join, prop } from 'ramda';
import { FilepondWrapper } from '../../bitComponents/multiple-files-upload/MultipleFilesUpload';
import { getFilesUrls } from '../../bitComponents/multiple-files-upload/MultipleFilesUpload/utils';
import { API_URL, CDN_PREFIX } from '../../constants/constants';

const BoundedFilepondWrapper = ({ name, label }) => {
  const pond = useRef(null);
  const {
    input: { onChange, value }
  } = useField(name);

  const onProcessFiles = () => {
    const fileUrls = getFilesUrls(pond.current.getFiles());
    if (fileUrls.length === 1 && fileUrls[0] === value) {
      return;
    }
    const cdnUrl =
      fileUrls.length > 0
        ? pipe(
            prop(0),
            split('/'),
            adjust(2, () => CDN_PREFIX), // replace prefix with the cdn
            remove(3, 1), // remove the name of teh bucket
            join('/')
          )(fileUrls)
        : prop(0, fileUrls);
    onChange(cdnUrl);
  };

  const onUpdateFile = () => {
    const files = pond.current.getFiles();
    if (files.length === 0) {
      onChange(null);
    }
  };

  return (
    <div style={{ width: 250 }}>
      <FilepondWrapper
        pond={pond}
        uploadText={label}
        maxFiles={1}
        onUpdateFile={onUpdateFile}
        onProcessFiles={onProcessFiles}
        acceptedTypes={['video/webm', 'video/mp4', 'image/ogg']}
        maxFileSize="70MB"
        apiUrlPrefix={API_URL}
        initialFiles={value ? [value] : []}
      />
    </div>
  );
};

const StarwizVideoInput = () => {
  return (
    <>
      <h4>העלאת סרטון סניף זוכה</h4>
      <BoundedFilepondWrapper name="starwizVideo" label="Upload video" />
      <br />
      <h4>העלאת סרטון סניף זוכה - מובייל</h4>
      <BoundedFilepondWrapper name="starwizVideoMobile" label="Upload video" />
      <br />
    </>
  );
};

export default StarwizVideoInput;
