import React, { useEffect, useRef, useState } from 'react';
import {
  Filter,
  SelectInput,
  TextInput,
  AutocompleteInput,
  BooleanInput
} from 'react-admin';
import useAgenciesForAutoComplete from '../../utils/hooks/useAgenciesForAutoComplete';
import useUsersForAutoComplete from '../../utils/hooks/useUsersForAutoComplete';
import useTeamsForAutoComplete from '../../utils/hooks/useTeamsForAutoComplete';
import { fetchDistricts } from '../Districts/api';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';

const CustomActiveFilter = ({ parse, ...props }) => {
  const handleParse = (value) => {
    return value ? { expose: true } : {};
  };

  useEffect(() => {
    // make sure the filter is active
    const element = document.querySelector(`input[name="expose"]`);

    if (element && !element.checked) element.click();
    // document.querySelector('#active').click();
  }, []);

  return (
    <BooleanInput
      defaultValue
      id="activeBranches"
      label="סוכנויות פעילות"
      source="expose"
      parse={handleParse}
      {...props}
    />
  );
};

const AgencyBranchFilter = (props) => {
  const { agencies } = useAgenciesForAutoComplete();
  const { teams } = useTeamsForAutoComplete(true);
  const { users } = useUsersForAutoComplete(
    ['ADMIN', 'CARWIZ_AGENT'],
    null,
    true
  );
  const { token } = useFirebaseToken();
  const [districts, setDistricts] = useState([]);

  useEffect(() => {
    fetchDistricts(token).then(setDistricts);
  }, [token]);

  return (
    <Filter {...props}>
      <TextInput source="id" />
      <AutocompleteInput choices={agencies} source="agencyId" />
      <AutocompleteInput choices={teams} source="team" />
      <AutocompleteInput choices={users} source="implementer" />
      <AutocompleteInput choices={users} source="representative" />

      <TextInput source="areaName" />
      <SelectInput source="district" label="District" choices={districts} />
      <TextInput source="phone" />
      <TextInput source="email" />
      <TextInput source="calendarId" />
      <BooleanInput source="isTradein" />
      <BooleanInput source="isCarlandBranch" />
      <CustomActiveFilter alwaysOn />
    </Filter>
  );
};

export default AgencyBranchFilter;
