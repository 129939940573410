import { withStyles } from '@material-ui/core/styles';
import VideoIcon from '@material-ui/icons/Videocam';
import React from 'react';

const styles = {
  iconRoot: {
    verticalAlign: 'middle'
  }
};

/* eslint-disable react/prefer-stateless-function */
const VideoIndicator = ({ classes, color = 'black', size = 'small' }) => (
  <VideoIcon
    classes={{ root: classes.iconRoot }}
    color={color}
    fontSize={size}
  />
);

export default withStyles(styles)(VideoIndicator);
