import React, { useState, useEffect } from 'react';
import { Tooltip, IconButton } from '@material-ui/core';
import { Button, useRefresh, useNotify, usePermissions } from 'react-admin';
import { cond, equals, always, startsWith } from 'ramda';
import { DataGrid } from '@material-ui/data-grid';
import Drawer from '@material-ui/core/Drawer';
import Chip from '@material-ui/core/Chip';
import EditIcon from '@material-ui/icons/Edit';
import HistoryIcon from '@material-ui/icons/History';
import CircularProgress from '@material-ui/core/CircularProgress';
import VisibilityIcon from '@material-ui/icons/Visibility';
// import AddCircleIcon from '@material-ui/icons/AddCircle';
import useUsersForAutoComplete from '../../../utils/hooks/useUsersForAutoComplete';
import useFirebaseToken from '../../../bitComponents/utils/hooks/useFirebaseToken';
import useAgencyBranchesForAutoComplete from '../../../utils/hooks/useAgencyBranchesForAutoComplete';
import HeroDrawer from './HeroDrawer';
import { createDynamicContent, createDynamicContentAd } from '../api';
import CarouselDrawer from './CarouselDrawer';
import { isMobile } from '../../../utils/utils';
import BannerDrawer from './BannerDrawer';
import TagDrawer from './TagDrawer';
import FilterDrawer from './FilterDrawer';
import BulkActionToolbar from './BulkActionToolbar';
import CancelDialog from './CancelDialog';
import { clearCache } from '../../UICache/api';
import { LOCATIONS } from '../constants';
import HistoryView from './HistoryView';
import AdDrawer from './AdDrawer';

const DynamicContentList = ({ content = {}, contentAd = {} }) => {
  const refresh = useRefresh();
  const notify = useNotify();
  const { permissions, loaded: permissionsLoaded } = usePermissions();
  const { token } = useFirebaseToken();
  const { users } = useUsersForAutoComplete(['ADMIN']);
  const { agencyBranches } = useAgencyBranchesForAutoComplete();
  const [loading, setLoading] = useState(false);
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [isHistoryOpen, setHistoryOpen] = useState(false);
  const [drawerContent, setDrawerContent] = useState(null);
  const [historyContent, setHistoryContent] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isDialogOpen, setDialogOpen] = useState(false);

  const hero = content.find((c) => c.sourceTable.includes('hero'));
  const carrousel = content.find((c) => c.sourceTable.includes('carrousel'));
  const banner = content.find((c) => c.sourceTable.includes('banner'));
  const tag = content.find((c) => c.sourceTable.includes('tag'));
  const filter = content.find((c) => c.sourceTable.includes('filter'));
  const ad3 = contentAd.find((c) => c.adNum === 3);
  const ad14 = contentAd.find((c) => c.adNum === 14);
  const ad27 = contentAd.find((c) => c.adNum === 27);
  const ad31 = contentAd.find((c) => c.adNum === 31);

  useEffect(() => {
    if (!permissionsLoaded) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [permissionsLoaded, permissions]);

  const getInitialBranches = (branchesIds) => {
    const branches = [{ id: 0, name: 'בחר הכל' }, ...(agencyBranches || [])];
    return (branches || []).filter(
      (b) => branchesIds && branchesIds.includes(b.id)
    );
  };

  const openDrawer = (drawer) => {
    if (!drawer) return;

    setDrawerContent(drawer);
    setDrawerOpen(true);
  };

  const handleConfirmClose = () => {
    setDrawerOpen(false);
    setDialogOpen(false);
  };

  const handleCancelClose = () => {
    setDialogOpen(false);
  };

  const closeDrawer = (dirty) => {
    if (!dirty) handleConfirmClose(); // no need to show dialog if not dirty
    else setDialogOpen(true);
  };

  const clearPagesCache = async () => {
    await clearCache(token, {
      pages: ['home', 'used-cars']
    });
  };

  const handleBulkSave = async (selected, updateData) => {
    try {
      setLoading(true);

      const invalidEndDateComponents = [];
      const invalidStartDateComponents = [];
      const invalidActiveStatus = [];
      const updatedComponents = [];
      const getTitle = cond([
        [equals('hero'), always('הירו')],
        [equals('banner'), always('באנר')],
        [equals('tag'), always('תגית')],
        [equals('filter'), always('סנן')],
        [equals('ad3'), always('מודעה 3')],
        [equals('ad14'), always('מודעה 14')],
        [equals('ad27'), always('מודעה 27')],
        [equals('ad31'), always('מודעה 31')],
        [always(true), always('קרוסלה')]
      ]);

      await Promise.all(
        selected.map(async (item) => {
          const isAd = item.id.startsWith('ad');
          let error = false;
          let contentToUpdate;

          if (isAd) {
            contentToUpdate = contentAd.find(
              (c) => c.adNum === parseInt(item.id.split('d')[1], 10)
            );
          } else {
            contentToUpdate = content.find((c) =>
              c.sourceTable.includes(item.id)
            );

            contentToUpdate.sourceTable =
              item.id.charAt(0).toUpperCase() + item.id.slice(1);
          }

          contentToUpdate.creatingUid = permissions.uid;

          if (updateData.status) {
            contentToUpdate.status = updateData.status;
          }

          const { startDate, endDate, status } = updateData;

          if (status === 'active') {
            const now = new Date();

            // if status is active but the current date is not between the startDate and endDate
            if (
              new Date(contentToUpdate.endDate) < now ||
              new Date(contentToUpdate.startDate) > now
            ) {
              error = true;
              invalidActiveStatus.push(getTitle(item.id));
            }
          }

          if (startDate && endDate) {
            // Update both startDate and endDate
            contentToUpdate.startDate = startDate;
            contentToUpdate.endDate = endDate;
          } else if (startDate) {
            // Update startDate if it's not after the current endDate
            if (
              !contentToUpdate.endDate ||
              new Date(startDate) <= new Date(contentToUpdate.endDate)
            ) {
              contentToUpdate.startDate = startDate;
            } else {
              error = true;
              invalidStartDateComponents.push(getTitle(item.id));
            }
          } else if (endDate) {
            // Update endDate if it's not before the current startDate
            if (
              !contentToUpdate.startDate ||
              new Date(endDate) >= new Date(contentToUpdate.startDate)
            ) {
              contentToUpdate.endDate = endDate;
            } else {
              error = true;
              invalidEndDateComponents.push(getTitle(item.id));
            }
          }

          if (!error) {
            const createFunc = isAd // get the func to add new dynamic content
              ? createDynamicContentAd
              : createDynamicContent;

            await createFunc(contentToUpdate, token);

            updatedComponents.push(getTitle(item.id));
          }
        })
      );

      setLoading(false);
      setDrawerOpen(false);
      refresh();

      if (invalidEndDateComponents.length > 0) {
        notify(
          'לא היה ניתן לעדכן את רכיבים {{comp}}  .לא ניתן להגדיר תאריך סיום שקטן מתאריך ההתחלה'.replace(
            '{{comp}}',
            invalidEndDateComponents.join(',')
          ),
          'error'
        );
      }

      if (invalidStartDateComponents.length > 0) {
        notify(
          'לא היה ניתן לעדכן את רכיבים {{comp}}  .לא ניתן להגדיר תאריך התחלה שגדול מתאריך הסיום'.replace(
            '{{comp}}',
            invalidStartDateComponents.join(',')
          ),
          'error'
        );
      }

      if (invalidActiveStatus.length > 0) {
        notify(
          'לא היה ניתן לעדכן את רכיבים {{comp}}  .לא ניתן להגדיר סטטוס פעיל כי התאריך הנוכחי זה אינו בין תאריכים התחלה וסיום'.replace(
            '{{comp}}',
            invalidActiveStatus.join(',')
          ),
          'error'
        );
      }

      if (updatedComponents.length > 0) {
        await clearPagesCache();
        notify(
          'הרכיבים {{comp}} עודכנו בהצלחה'.replace(
            '{{comp}}',
            updatedComponents.join(',')
          ),
          'success'
        );
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      notify('התרחשה בעיה בשמירת הנתונים, אנא נסה שנית', 'error');
    }
  };

  const handleSave = async (data) => {
    try {
      setLoading(true);

      const createFunc = data.adNum // get the func to add new dynamic content
        ? createDynamicContentAd
        : createDynamicContent;

      await createFunc(data, token); // to keep history, we need to add create new record
      await clearPagesCache();

      setLoading(false);
      setDrawerOpen(false);
      notify('ra.notification.updated');
      refresh();
    } catch (err) {
      setLoading(false);
      notify('התרחשה בעיה בשמירת הנתונים, אנא נסה שנית', 'error');
    }
  };

  const closeHistory = () => {
    setHistoryContent(null);
    setHistoryOpen(false);
  };

  const openHistory = (id) => {
    if (!id) return;

    setHistoryContent(id);
    setHistoryOpen(true);
  };

  const handleHistoryButtonClick = (id) => {
    openHistory(id);
  };

  const handleEditButtonClick = (id) => {
    const getAdContent = cond([
      [equals('ad3'), always(ad3)],
      [equals('ad14'), always(ad14)],
      [equals('ad27'), always(ad27)],
      [equals('ad31'), always(ad31)]
    ]);

    const getDrawer = cond([
      [
        equals('hero'),
        always(
          <HeroDrawer
            onClose={closeDrawer}
            data={hero}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [
        equals('carrousel'),
        always(
          <CarouselDrawer
            onClose={closeDrawer}
            data={carrousel}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [
        equals('banner'),
        always(
          <BannerDrawer
            onClose={closeDrawer}
            data={banner}
            initialBranches={getInitialBranches(banner?.branches || [])}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [
        equals('tag'),
        always(
          <TagDrawer
            onClose={closeDrawer}
            data={tag}
            initialBranches={getInitialBranches(tag?.branches || [])}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [
        equals('filter'),
        always(
          <FilterDrawer
            onClose={closeDrawer}
            data={filter}
            initialBranches={getInitialBranches(filter?.branches || [])}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [
        startsWith('ad'),
        always(
          <AdDrawer
            onClose={closeDrawer}
            data={getAdContent(id)}
            onSave={handleSave}
            uid={permissions.uid}
          />
        )
      ],
      [always(true), always(null)]
    ]);

    openDrawer(getDrawer(id));
  };

  const renderHeader = (headerName, classes) => (
    <div
      style={{
        fontWeight: 'bold',
        marginRight: '30%'
      }}
    >
      {headerName}
    </div>
  );

  const columns = [
    //  { field: 'id', headerName: 'ID', flex: 1 },
    {
      field: 'name',
      headerName: 'שם רכיב',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName)
    },
    {
      field: 'campaignName',
      headerName: 'שם הקמפיין',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName)
    },
    {
      field: 'location',
      headerName: 'מיקום',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName),
      renderCell: ({ row }) => {
        if (!row.location) return '-';
        return LOCATIONS[row.location.toLocaleLowerCase()];
      }
    },
    {
      field: 'startDate',
      headerName: 'ת. התחלה',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName),
      renderCell: ({ row }) => {
        if (!row.startDate) return '-';
        return new Date(row.startDate).toLocaleDateString('he-IL');
      }
    },
    {
      field: 'endDate',
      headerName: 'ת. סיום',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName),
      renderCell: ({ row }) => {
        if (!row.endDate) return '-';
        return new Date(row.endDate).toLocaleDateString('he-IL');
      }
    },
    {
      field: 'creatingUser',
      headerName: 'משתמש מעדכן',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName),
      renderCell: ({ row }) => {
        if (!users || !users.length) {
          return <CircularProgress size={20} />;
        }

        const user = (users || []).find((u) => u.id === row.creatingUser);

        return user?.name || '-';
      }
    },
    {
      field: 'status',
      headerName: 'סטטוס',
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderHeader: (params) => renderHeader(params.colDef.headerName),
      renderCell: ({ row }) => {
        const getColor = cond([
          [equals('active'), always('primary')],
          [equals('inactive'), always('default')],
          [always(true), always('secondary')]
        ]);

        const getStatus = cond([
          [equals('active'), always('פעיל')],
          [equals('inactive'), always('לא פעיל')],
          [always(true), always('לא הוגדר')]
        ]);

        let color;
        let status;
        const { isActive } = row;

        color = isActive ? 'primary' : getColor(row.status);
        status = isActive ? 'פעיל' : getStatus(row.status);

        if (
          (!isActive && row.status === 'active') ||
          (isActive && row.status === 'inactive')
        ) {
          color = 'default';
          status = 'לא פעיל';
        }

        if (status === 'לא הוגדר') {
          return (
            <Chip
              label={status}
              size="small"
              style={{
                width: '80px',
                color: 'black',
                background: 'lightcoral'
              }}
            />
          );
        }

        return (
          <Chip
            label={status}
            color={color}
            size="small"
            style={{ width: '80px' }}
          />
        );
      }
    },
    {
      field: 'history',
      headerName: ' ',
      sortable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Button
            label=""
            disabled={loading}
            onClick={() => handleHistoryButtonClick(row.id)}
            startIcon={<HistoryIcon />}
            variant="text"
            style={{ width: '100%', color: 'black' }}
          />
        );
      }
    },
    {
      field: 'preview',
      headerName: ' ',
      sortable: false,
      flex: 0.5,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        const handleClick = (id) => {
          const suffix = ['hero', 'carrousel'].includes(id)
            ? 'hp'
            : 'used-cars';
          const url = `https://staging.carwiz.co.il/preview/${suffix}`;

          window.open(url, '_blank');
        };

        return (
          <Tooltip title="תצוגה מקדימה" arrow>
            <IconButton
              onClick={() => handleClick(row.id)}
              disabled={loading}
              style={{ width: '100%', color: 'black' }}
            >
              <VisibilityIcon />
            </IconButton>
          </Tooltip>
        );
      }
    },
    {
      field: 'actions',
      headerName: ' ',
      sortable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      renderCell: ({ row }) => {
        return (
          <Button
            label="עריכה"
            disabled={loading}
            onClick={() => handleEditButtonClick(row.id)}
            startIcon={<EditIcon />}
            variant="text"
            style={{ width: '100%', color: 'black' }}
          />
        );
      }
    }
  ];

  const rows = [
    {
      id: 'hero',
      name: ' הירו',
      location: hero?.location || 'דף הבית',
      startDate: hero?.startDate,
      endDate: hero?.endDate,
      creatingUser: hero?.creatingUid,
      status: hero?.status,
      campaignName: hero?.campaignName,
      isActive: hero?.isActive
    },
    {
      id: 'carrousel',
      name: 'קרוסלה',
      location: carrousel?.location || 'דף הבית',
      startDate: carrousel?.startDate,
      endDate: carrousel?.endDate,
      creatingUser: carrousel?.creatingUid,
      status: carrousel?.status,
      campaignName: carrousel?.campaignName,
      isActive: carrousel?.isActive
    },
    {
      id: 'banner',
      name: 'באנר',
      location: banner?.location || 'דף הבית, דף חיפוש',
      startDate: banner?.startDate,
      endDate: banner?.endDate,
      creatingUser: banner?.creatingUid,
      status: banner?.status,
      campaignName: banner?.campaignName,
      isActive: banner?.isActive
    },
    {
      id: 'tag',
      name: 'תגית ',
      location: tag?.location || 'דף חיפוש',
      startDate: tag?.startDate,
      endDate: tag?.endDate,
      creatingUser: tag?.creatingUid,
      status: tag?.status,
      campaignName: tag?.campaignName,
      isActive: tag?.isActive
    },
    {
      id: 'filter',
      name: 'סנן',
      location: filter?.location || 'דף חיפוש',
      startDate: filter?.startDate,
      endDate: filter?.endDate,
      creatingUser: filter?.creatingUid,
      status: filter?.status,
      campaignName: filter?.campaignName,
      isActive: filter?.isActive
    },
    {
      id: 'ad3',
      name: 'מודעה 3',
      location: ad3?.location || 'דף חיפוש',
      startDate: ad3?.startDate,
      endDate: ad3?.endDate,
      creatingUser: ad3?.creatingUid,
      status: ad3?.status,
      campaignName: ad3?.campaignName,
      isActive: ad3?.isActive
    },
    {
      id: 'ad14',
      name: 'מודעה 14',
      location: ad14?.location || 'דף חיפוש',
      startDate: ad14?.startDate,
      endDate: ad14?.endDate,
      creatingUser: ad14?.creatingUid,
      status: ad14?.status,
      campaignName: ad14?.campaignName,
      isActive: ad14?.isActive
    },
    // {
    //   id: 'ad27',
    //   name: 'מודעה 27',
    //   location: ad27?.location|| 'דף חיפוש',
    //   startDate: ad27?.startDate,
    //   endDate: ad27?.endDate,
    //   creatingUser: ad27?.creatingUid,
    //   status: ad27?.status,
    //   campaignName: ad27?.campaignName,
    //   isActive: ad27?.isActive
    // },
    {
      id: 'ad31',
      name: 'מודעה 31',
      location: ad31?.location || 'דף חיפוש',
      startDate: ad31?.startDate,
      endDate: ad31?.endDate,
      creatingUser: ad31?.creatingUid,
      status: ad31?.status,
      campaignName: ad31?.campaignName,
      isActive: ad31?.isActive
    }
  ];

  const handleRowSelectionChange = (selection) => {
    const selectedData = selection.map((id) =>
      rows.find((row) => row.id === id)
    );

    setSelectedRows(selectedData);
  };

  // Function to handle row selection change
  // const handleRowSelectionChange = (selection) => {
  //   const filteredSelection = selection.filter((id) => {
  //     const row = rows.find((item) => item.id === id);
  //     return row && row.status;
  //   });

  //   setSelectedRows(filteredSelection);
  // };

  const isRowSelectable = (params) => {
    const row = rows.find((item) => item.id === params.row.id);
    return row && row.status;
  };

  if (!permissionsLoaded || !permissions || !permissions.uid)
    return (
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }}
      >
        <CircularProgress size={80} />
      </div>
    );

  return (
    <div style={{ height: '100%', width: '100%', overflowX: 'auto' }}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ maxWidth: '100%', width: '100%', background: ' white' }}>
          <DataGrid
            loading={loading}
            rows={rows}
            columns={columns}
            checkboxSelection
            paginationMode={null}
            pagination={null}
            autoHeight
            disableSelectionOnClick
            onSelectionModelChange={handleRowSelectionChange}
            selectionModel={selectedRows.map((row) => row.id)}
            isRowSelectable={isRowSelectable}
            components={{
              Toolbar: () => {
                // return null;
                if (!selectedRows?.length) return null;
                return (
                  <BulkActionToolbar
                    loading={loading}
                    onBulkAction={handleBulkSave}
                    selected={selectedRows || []}
                    uid={permissions.uid}
                  />
                );
              }
            }}
          />
        </div>
      </div>

      {historyContent && (
        <HistoryView
          open={isHistoryOpen && !!historyContent}
          onClose={closeHistory}
          id={historyContent}
        />
      )}

      {/* Cancel Dialog component */}
      <CancelDialog
        open={isDialogOpen}
        onConfirm={handleConfirmClose}
        onCancel={handleCancelClose}
      />

      {/* Drawer component */}
      <Drawer
        anchor={isMobile() ? 'bottom' : 'right'}
        open={isDrawerOpen}
        onClose={closeDrawer}
        ModalProps={{
          BackdropProps: {
            style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' },
            invisible: true,
            onClick: (event) => event.stopPropagation()
          }
        }}
      >
        {drawerContent}
      </Drawer>
    </div>
  );
};

export default DynamicContentList;
