import React, { useState, useEffect } from 'react';
import {
  IconButton,
  Typography,
  Divider,
  TextField,
  Grid,
  Button,
  FormControl,
  InputLabel,
  Select,
  FormControlLabel,
  Switch,
  MenuItem
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import DesktopMacIcon from '@material-ui/icons/DesktopMac';
import { COLOR_CHOICES, SIZE_CHOICES, WEIGHT_CHOICES } from '../constants';
import ImageKitFileUpload from '../../../components/ImageKitFileUpload';
import RemoveDialog from './RemoveDialog';

const HeroDrawer = ({
  onClose = () => {},
  onSave = () => {},
  data = {},
  uid,
  title = 'הירו'
}) => {
  const [desktopImage, setDesktopImage] = useState(
    data.desktopImageUrl || null
  );
  const [mobileImage, setMobileImage] = useState(data.mobileImageUrl || null);
  const [startDate, setStartDate] = useState(data.startDate || '');
  const [endDate, setEndDate] = useState(data.endDate || '');
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [status, setStatus] = useState(data.status || null);
  const [header, setHeader] = useState(data.header || null);
  const [campaignName, setCampaignName] = useState(data.campaignName || null);
  const [headerWeight, setHeaderWeight] = useState(data.headerWeight || null);
  const [headerSize, setHeaderSize] = useState(data.headerSize || null);
  const [subHeader, setSubheader] = useState(data.subHeader || null);
  const [subHeaderWeight, setSubHeaderWeight] = useState(
    data.subHeaderWeight || null
  );
  const [subHeaderSize, setSubHeaderSize] = useState(
    data.subHeaderSize || null
  );
  const [headerColor, setHeaderColor] = useState(data.headerColor || null);
  const [subHeaderColor, setSubHeaderColor] = useState(
    data.subHeaderColor || null
  );
  const [isStatusDisabled, setIsStatusDisabled] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [errors, setErrors] = useState({});
  const modified = { ...data };
  const keys = [
    'startDate',
    'endDate',
    'creatingUid',
    'desktopImageUrl',
    'mobileImageUrl',
    'status',
    'header',
    'subHeader',
    'headerColor'
  ];

  useEffect(() => {
    const today = new Date().toISOString().split('T')[0];

    if (!startDate || !endDate || startDate > today || today > endDate) {
      setStatus('inactive');
      setIsStatusDisabled(true);
    } else {
      setIsStatusDisabled(false);
    }
  }, [startDate, endDate]);

  const areRequiredFieldsFilled = () => {
    return (
      !!startDate &&
      !!endDate &&
      !!campaignName &&
      !!desktopImage &&
      !!mobileImage &&
      ((!!header && !!headerColor && !!headerWeight && !!headerSize) ||
        (!!subHeader &&
          !!subHeaderColor &&
          !!subHeaderSize &&
          !!subHeaderWeight))
    );
  };

  const handleSave = (clear = false) => {
    if (clear) {
      Object.keys(modified).forEach((key) => {
        modified[key] = null;
      });

      modified.creatingUid = uid;
    } else {
      modified.startDate = startDate;
      modified.endDate = endDate;
      modified.creatingUid = uid;
      modified.desktopImageUrl = desktopImage;
      modified.mobileImageUrl = mobileImage;
      modified.status = status;
      modified.header = header;
      modified.headerColor = header ? headerColor : null; // save header props only if header is present
      modified.headerSize = header ? headerSize : null;
      modified.headerWeight = header ? headerWeight : null;
      modified.subHeader = subHeader;
      modified.subHeaderColor = subHeader ? subHeaderColor : null; // save sub header props only if sub header is present
      modified.subHeaderSize = subHeader ? subHeaderSize : null;
      modified.subHeaderWeight = subHeader ? subHeaderWeight : null;
      modified.campaignName = campaignName;
    }

    modified.location = 'HOME';
    modified.sourceTable = 'Hero';

    onSave(modified);
  };

  const handleStartDateChange = (val) => {
    setStartDate(val);
    setDirty(true);
  };

  const handleEndDateChange = (val) => {
    setEndDate(val);
    setDirty(true);
  };

  const hasSomeData = () => {
    // Check if any value in the data object, excluding some keys that always have a value
    return Object.entries(data).some(([key, value]) => {
      if (
        !keys.includes(key) ||
        key === 'id' ||
        key === 'nodeId' ||
        key === 'location'
      ) {
        return false;
      }

      return !!value; // Convert value to boolean and check if it's truthy
    });
  };

  const helpText = `ניתן להעלות קבצים בפורמטים: JPG, PNG, GIF. גודל קובץ מקסימלי 10mb`;

  return (
    <div style={{ width: 700, padding: 16 }}>
      {/* Close button */}
      <IconButton
        style={{ position: 'absolute', top: 8, left: 8 }}
        onClick={() => onClose(dirty)}
      >
        <CloseIcon />
      </IconButton>

      {/* Title */}
      <Typography
        variant="h6"
        style={{ marginTop: 4, marginBottom: 8, color: '#519FAB' }}
      >
        {title}
      </Typography>

      {/* Divider */}
      <Divider style={{ marginBottom: 16 }} />

      <Grid
        container
        spacing={2}
        style={{ marginBottom: 16, display: 'flex', alignItems: 'center' }}
      >
        {/* Switch input */}
        <Grid item style={{ flex: 1 }}>
          <Typography
            variant="body1"
            style={{ marginLeft: 16, fontWeight: 'bold' }}
          >
            סטטוס
          </Typography>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Switch
                defaultChecked={data.status === 'active'}
                checked={status === 'active'}
                name="status"
                color="primary"
                disabled={isStatusDisabled}
                onChange={(ev) => {
                  setStatus(ev.currentTarget.checked ? 'active' : 'inactive');
                  setDirty(true);
                }}
              />
            }
            label="פעיל"
          />
        </Grid>
        {/* Status label */}
      </Grid>

      {/* Date fields */}
      <Grid container spacing={2} style={{ marginBottom: 16 }}>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך התחלה"
            type="date"
            defaultValue={data.startDate || ''}
            onChange={(ev) => {
              handleStartDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: { min: new Date().toISOString().split('T')[0] }
            }}
            error={errors.startDate}
            helperText={errors.startDate && 'שדה זה חובה'}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            variant="outlined"
            label="תאריך סיום"
            type="date"
            defaultValue={data.endDate || ''}
            onChange={(ev) => {
              handleEndDateChange(ev.currentTarget.value);
            }}
            InputLabelProps={{
              shrink: true
            }}
            fullWidth
            InputProps={{
              inputProps: {
                min: startDate || new Date().toISOString().split('T')[0]
              }
            }}
            error={errors.endDate}
            helperText={errors.endDate && 'שדה זה חובה'}
          />
        </Grid>
      </Grid>

      {endDate && startDate && endDate < startDate && (
        <p style={{ color: 'red' }}>
          לא ניתן להגדיר תאריך סיום שקטן מתאריך ההתחלה
        </p>
      )}

      {/* Text fields */}
      <TextField
        label="שם קמפיין"
        onBlur={(e) => setErrors({ campaignName: !e.target.value })}
        defaultValue={data.campaignName || ''}
        onChange={(e) => {
          setCampaignName(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        error={errors.campaignName}
        helperText={errors.campaignName && 'שדה זה חובה'}
        required
      />
      <TextField
        label="כותרת"
        onBlur={(e) => setErrors({ header: !e.target.value })}
        defaultValue={data.header || ''}
        onChange={(e) => {
          setHeader(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
        error={errors.header}
        helperText={errors.header && 'שדה זה חובה'}
        required={!subHeader}
      />

      {/* Select input */}
      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}> צבע כותרת *</InputLabel>
        <Select
          defaultValue={data.headerColor || ''}
          onBlur={(e) => setErrors({ headerColor: header && !e.target.value })}
          required={!!header}
          error={errors.headerColor}
          helperText={errors.headerColor && 'שדה זה חובה'}
          onChange={(e) => {
            setHeaderColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>משקל כותרת *</InputLabel>
        <Select
          onBlur={(e) => setErrors({ headerWeight: header && !e.target.value })}
          error={errors.headerWeight}
          helperText={errors.headerWeight && 'שדה זה חובה'}
          required={!!header}
          defaultValue={data.headerWeight || ''}
          onChange={(e) => {
            setHeaderWeight(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {WEIGHT_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>גודל כותרת * </InputLabel>
        <Select
          onBlur={(e) => setErrors({ headerSize: header && !e.target.value })}
          required={!!header}
          error={errors.headerSize}
          helperText={errors.headerSize && 'שדה זה חובה'}
          defaultValue={data.headerSize || ''}
          onChange={(e) => {
            setHeaderSize(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {SIZE_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <TextField
        label="תת כותרת"
        onBlur={(e) => setErrors({ subHeader: !header && !e.target.value })}
        required={!header}
        error={errors.subHeader}
        helperText={errors.subHeader && 'שדה זה חובה'}
        defaultValue={data.subHeader || ''}
        onChange={(e) => {
          setSubheader(e.target.value);
          setDirty(true);
        }}
        fullWidth
        variant="outlined"
        style={{ marginBottom: 16 }}
      />

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>צבע תת כותרת *</InputLabel>
        <Select
          onBlur={(e) =>
            setErrors({ subHeaderColor: subHeader && !e.target.value })
          }
          required={!!subHeader}
          error={errors.subHeaderColor}
          helperText={errors.subHeaderColor && 'שדה זה חובה'}
          defaultValue={data.subHeaderColor || ''}
          onChange={(e) => {
            setSubHeaderColor(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {COLOR_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>משקל תת כותרת *</InputLabel>
        <Select
          onBlur={(e) =>
            setErrors({ subHeaderWeight: subHeader && !e.target.value })
          }
          required={!!subHeader}
          error={errors.subHeaderWeight}
          helperText={errors.subHeaderWeight && 'שדה זה חובה'}
          defaultValue={data.subHeaderWeight || ''}
          onChange={(e) => {
            setSubHeaderWeight(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {WEIGHT_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl fullWidth style={{ marginBottom: 16 }}>
        <InputLabel style={{ right: '10px' }}>גודל תת כותרת *</InputLabel>
        <Select
          required={!!subHeader}
          onBlur={(e) =>
            setErrors({ subHeaderSize: subHeader && !e.target.value })
          }
          error={errors.subHeaderSize}
          helperText={errors.subHeaderSize && 'שדה זה חובה'}
          defaultValue={data.subHeaderSize || ''}
          onChange={(e) => {
            setSubHeaderSize(e.target.value);
            setDirty(true);
          }}
          variant="outlined"
        >
          {SIZE_CHOICES.map((option) => (
            <MenuItem key={option.id} value={option.id}>
              {option.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {errors.headerOrSubHeader && (
        <p style={{ color: 'red' }}>נדרשת כותרת או תת כותרת</p>
      )}

      <ImageKitFileUpload
        maxSizeMB={10}
        helpText={helpText}
        onUploadSuccess={setDesktopImage}
        onImageChange={() => setDirty(true)}
        text="לחץ כאן כדי להעלות מדיה לדקסטופ"
        initialImage={desktopImage}
        folder="hero-content"
        required={errors.desktopImage}
        icon={<DesktopMacIcon fontSize="large" />}
      />

      <br />

      <ImageKitFileUpload
        maxSizeMB={10}
        helpText={helpText}
        onUploadSuccess={setMobileImage}
        onImageChange={() => setDirty(true)}
        folder="hero-content"
        text="לחץ כאן כדי להעלות מדיה למובייל"
        initialImage={mobileImage}
        required={errors.mobileImage}
        icon={<PhoneAndroidIcon fontSize="large" />}
      />

      {/* Save and Cancel buttons */}
      <div style={{ textAlign: 'center', marginTop: '100px' }}>
        <Button
          disabled={!hasSomeData()}
          variant="outlined"
          color="secondary"
          onClick={() => setDialogOpen(true)}
          style={{
            marginLeft: 16,
            width: '40%',
            color: 'red',
            border: '1px solid red'
          }}
        >
          הסרה
        </Button>

        <Button
          disabled={!dirty || !areRequiredFieldsFilled()}
          variant="contained"
          color="primary"
          onClick={() => handleSave(false)}
          style={{ width: '40%' }}
        >
          שמירה
        </Button>
      </div>

      <RemoveDialog
        onConfirm={() => handleSave(true)}
        open={isDialogOpen}
        onCancel={() => setDialogOpen(false)}
      />
    </div>
  );
};

export default HeroDrawer;
