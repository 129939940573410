import React from 'react';
import {
  Create,
  Datagrid,
  Filter,
  List,
  required,
  SimpleForm,
  TextField,
  TextInput,
  DeleteButton
} from 'react-admin';

export const HiddenPostList = (props) => (
  <List
    {...props}
    filters={<HiddenPostFilter />}
    sort={{ field: 'carId', order: 'DESC' }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      <TextField source="carId" label="carId" />
      <DeleteButton />
    </Datagrid>
  </List>
);

const HiddenPostFilter = (props) => (
  <Filter {...props}>
    <TextInput source="carId" />
  </Filter>
);

export const HiddenPostCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput label="carId" source="carId" validate={required()} />
    </SimpleForm>
  </Create>
);
