import {
  addIndex,
  assoc,
  clone,
  curry,
  filter,
  isNil,
  lensIndex,
  map,
  not,
  path,
  pipe,
  prop,
  replace,
  set,
} from 'ramda';

export const mapIndexed = addIndex(map);

export const validURL = url => {
  const pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i',
  );
  return !!pattern.test(url);
};
export const stringifyValue = value => `"${String(value)}"`;

export const stringifyArray = value => `[${map(stringifyValue, value)}]`;

export const filterNulls = filter(
  pipe(
    isNil,
    not,
  ),
);

export const setLocalStorageWithExpiry = (key, value, days) => {
  try {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
      value,
      expiry: now.getTime() + 1000 * 60 * 60 * 24 * days,
    };
    localStorage.setItem(key, JSON.stringify(item));
  } catch {
    // do nothing
  }
};

export const getLocalStorageKeyWithExpiry = key => {
  try {
    const itemStr = localStorage.getItem(key);
    // if the item doesn't exist, return null
    if (!itemStr) {
      return null;
    }
    const item = JSON.parse(itemStr);
    const now = new Date();
    // compare the expiry time of the item with the current time
    if (now.getTime() > prop('expiry', item)) {
      // If the item is expired, delete the item from storage
      // and return null
      localStorage.removeItem(key);
      return null;
    }
    return prop('value', item);
  } catch (e) {
    return undefined;
  }
};

export const stringifyJsonWithoutQuotes = jsonObject => {
  const jsonString = JSON.stringify(jsonObject);

  return jsonString.replace(/"([^"]+)":/g, '$1:');
};

// To calculate the time difference of two dates
export const calculateDaysFromToday = date => {
  if (isNil(date)) {
    return null;
  }
  try {
    const timeDiff = new Date().getTime() - date.getTime();

    return Math.round(timeDiff / (1000 * 3600 * 24));
  } catch (e) {
    return null;
  }
};

export const isNumeric = value => /^-{0,1}\d+$/.test(value);

export const flattenResourceFiles = resource =>
  pipe(
    assoc('images', path(['images', 'nodes'], resource)),
    assoc('videos', path(['videos', 'nodes'], resource)),
    assoc('checks', path(['checks', 'nodes'], resource)),
  )(resource);

export const flattenResourceListFiles = map(flattenResourceFiles);

export const swap = curry((index1, index2, list) => {
  if (
    index1 < 0 ||
    index2 < 0 ||
    index1 > list.length - 1 ||
    index2 > list.length - 1
  ) {
    return list; // index out of bound
  }
  const value1 = list[index1];
  const value2 = list[index2];
  return pipe(
    set(lensIndex(index1), value2),
    set(lensIndex(index2), value1),
  )(list);
});

export const getNumberAsPrice = value =>
  new Intl.NumberFormat('he-IL', {
    style: 'currency',
    currency: 'ILS',
    minimumFractionDigits: 0,
  }).format(value);

export const replaceWhiteSpaces = replace(/\s/g, '-');

export const shuffle = arr => {
  const shuffledArr = clone(arr);
  let j;
  let x;
  let i;

  for (i = shuffledArr.length - 1; i > 0; ) {
    j = Math.floor(Math.random() * (i + 1));
    x = shuffledArr[i];
    shuffledArr[i] = shuffledArr[j];
    shuffledArr[j] = x;
    i -= 1;
  }
  return shuffledArr;
};
export const isIOSdevice = () =>
  [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod',
  ].includes(navigator.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes('Mac') && 'ontouchend' in document);
