/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable camelcase */
import React, { useMemo, useState } from 'react';
import { DataGrid } from '@material-ui/data-grid';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Grid,
  Typography
} from '@material-ui/core';
import ReactJson from 'react-json-view';
import useUsersForAutoComplete from '../../../utils/hooks/useUsersForAutoComplete';
import { OPERATIONS, TABLE_CHOICES } from '../constants';
import JsonPreview from './JsonPreview';

function prepareRow({
  id,
  table,
  created_at,
  operation,
  new_data,
  old_data,
  performer_uid
}) {
  return {
    id,
    table,
    createdAt: created_at.value,
    operation,
    newData: new_data,
    oldData: old_data,
    performerUid: performer_uid
  };
}

const findModifiedData = (oldData, newData) => {
  const mandatoryKeys = [
    'id',
    'car_id',
    'agency_id',
    'agency_branch_id',
    'uid',
    'customer_uid',
    'user_uid',
    'user_id'
  ];
  const ignoredKeys = ['created_at'];
  const modifiedData = {
    oldData: {},
    newData: {}
  };

  Object.keys(newData).forEach((key) => {
    if (
      key &&
      !ignoredKeys.includes(key) &&
      (mandatoryKeys.includes(key) || oldData[key] !== newData[key])
    ) {
      modifiedData.oldData[key] = oldData[key];
      modifiedData.newData[key] = newData[key];
    }
  });

  return modifiedData;
};

const AuditLogsList = ({
  logs,
  loading,
  currentPage,
  total,
  pageSize,
  onPageChange
}) => {
  const { users } = useUsersForAutoComplete([
    'ADMIN',
    'CARWIZ_AGENT',
    'BUSINESS_MANAGER',
    'BUSINESS_LOCAL'
  ]);
  const [openDialog, setOpenDialog] = useState(false);
  const [jsonData, setJsonData] = useState({});

  const handleCellClick = (params) => {
    if (params.field === 'oldData' || params.field === 'newData') {
      const { oldData, newData, operation } = params.row;

      const modifiedData = findModifiedData(
        JSON.parse(oldData),
        JSON.parse(newData)
      );

      if (operation === 'insert') modifiedData.oldData = {};
      if (operation === 'delete') modifiedData.newData = {};

      setJsonData({
        operation,
        oldData: modifiedData.oldData,
        newData: modifiedData.newData
      });

      setOpenDialog(true);
    }
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const columns = [
    {
      field: 'table',
      headerName: 'טבלה',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        const table = TABLE_CHOICES.find((c) => c.id === value);
        return table?.name || value;
      }
    },
    {
      field: 'createdAt',
      headerName: 'זמן פעולה',
      flex: 1,
      sortable: true,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return new Date(value).toISOString();
      }
    },
    {
      field: 'operation',
      headerName: 'פעולה',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return OPERATIONS[value];
      }
    },
    {
      field: 'newData',
      headerName: 'מידע חדש',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return <JsonPreview value={value} />;
      }
    },
    {
      field: 'oldData',
      headerName: 'מידע קודם',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: ({ value }) => {
        return <JsonPreview value={value} />;
      }
    },
    {
      field: 'performerUid',
      headerName: 'בוצע ע״י',
      flex: 1,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (row) => {
        const user = (users || []).find((u) => u.id === row.row.performerUid);

        return user?.name || row.row.performerUid || 'בוצע ע״י תהליך אוטומטי';
      }
    }
  ];

  const rows = useMemo(() => {
    const sortedLogs = logs.sort(
      (a, b) => new Date(b.created_at.value) - new Date(a.created_at.value)
    );

    return sortedLogs.map((log) => prepareRow(log));
  }, [logs]);

  return (
    <div style={{ width: '100%' }}>
      <h2>לוג שינויים</h2>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={pageSize}
        paginationMode="server"
        onPageChange={onPageChange}
        rowsPerPageOptions={['25']}
        checkboxSelection={false}
        disableSelectionOnClick
        autoHeight
        pagination
        page={currentPage}
        rowCount={total}
        loading={loading}
        onCellClick={handleCellClick}
        localeText={{
          noRowsLabel: 'אין לוגים להציג',
          MuiTablePagination: {
            backIconButtonText: 'עמוד הקודם',
            labelRowsPerPage: 'שורות לעמוד:',
            labelDisplayedRows: ({ from, to, count }) =>
              `${from}-${to} מתוך ${count !== -1 ? count : `יותר מ-${to}`}`,
            nextIconButtonText: 'עמוד הבא'
          }
        }}
      />

      <Dialog
        open={openDialog}
        onClose={handleCloseDialog}
        fullWidth
        maxWidth="lg"
      >
        <DialogTitle style={{ textAlign: 'center' }}>
          {' '}
          לוג שינוי - {OPERATIONS[jsonData.operation]}
        </DialogTitle>
        <DialogContent
          style={{
            direction: 'ltr'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography variant="h6">מידע קודם</Typography>
              <ReactJson src={jsonData.oldData} displayDataTypes={false} />
            </Grid>
            <Grid item xs={6}>
              <Typography variant="h6">מידע חדש</Typography>
              <ReactJson src={jsonData.newData} displayDataTypes={false} />
            </Grid>
          </Grid>
        </DialogContent>
        <Button onClick={handleCloseDialog} color="primary">
          סגור
        </Button>
      </Dialog>
    </div>
  );
};

export default AuditLogsList;
