import CitiesIcon from '@material-ui/icons/HomeWork';
import CitiesCreate from './CitiesCreate';
import CitiesEdit from './CitiesEdit';
import CitiesList from './CitiesList';

export default {
  list: CitiesList,
  icon: CitiesIcon,
  edit: CitiesEdit,
  create: CitiesCreate
};
