import { addIndex, chain, map, pipe, prop, split } from 'ramda';
import { API_URL } from '../constants/constants';

export const mapIndexed = addIndex(map);
export const chainIndexed = addIndex(chain);
// eslint-disable-next-line no-underscore-dangle

export const currentDate = new Intl.DateTimeFormat('he-IL', {
  timeZone: 'Asia/Jerusalem',
  timeStyle: 'short',
  dateStyle: 'short'
}).format(new Date());

export const formatDate = (date) => {
  const temp = new Date(date);
  const dd = String(temp.getDate()).padStart(2, '0');
  const mm = String(temp.getMonth() + 1).padStart(2, '0'); // January is 0!
  const yyyy = temp.getFullYear();
  const HH = temp.getHours();
  const MM = temp.getMinutes();

  return `${dd}/${mm}/${yyyy} ${HH}:${MM}`;
};

// format the dateTime to YYYY-MM-DD
export const getDateString = (date) => {
  return pipe(split('T'), prop(0))(date.toISOString());
};

export const mapError = (errors) => map((el) => el.message, errors).join('\n');

export const URL_REGEX = /^(ftp|http|https):\/\/[^ "]+$/;

export const isMobile = () => {
  return /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
};

export const getUIDomain = () => {
  let baseUrl = `https://carwiz.co.il/`;
  const isStage = /api\.(staging|apple)/.test(API_URL);
  const isDev = /api\.(dev|banana)/.test(API_URL);
  const isLocal = /localhost/.test(API_URL);

  if (isStage || isLocal) {
    baseUrl = `https://staging.carwiz.co.il/`;
  } else if (isDev) {
    baseUrl = `https://dev.carwiz.co.il/`;
  }

  return baseUrl;
};
