import React from 'react';
import { Tooltip } from '@material-ui/core';
import ReactJson from 'react-json-view';

const truncateJson = (json, maxLength) => {
  if (json.length <= maxLength) {
    return json;
  }
  return `${json.substr(0, maxLength)}...`;
};

const JsonPreview = ({ value }) => {
  const truncatedJson = truncateJson(JSON.stringify(value, null, 2), 100); // Adjust the maxLength as needed
  return (
    <Tooltip
      title={
        <ReactJson
          displayDataTypes={false}
          src={JSON.parse(value)}
          collapsed={2}
          theme="ocean"
          style={{ direction: 'ltr' }}
        />
      }
      arrow
      placement="right"
    >
      <pre style={{ cursor: 'pointer' }}>{truncatedJson}</pre>
    </Tooltip>
  );
};

export default JsonPreview;
