function getUIUrl(apiUrl) {
  const uiUrl = process.env.REACT_APP_UI_URL;
  if (uiUrl) return uiUrl;
  if (!apiUrl) return '';

  const isDev =
    apiUrl.indexOf('api.banana') > -1 || apiUrl.indexOf('api.dev') > -1;
  if (isDev) {
    return 'https://dev.carwiz.co.il';
  }

  const isStaging =
    apiUrl.indexOf('api.staging') > -1 || apiUrl.indexOf('api.apple') > -1;
  if (isStaging) {
    return 'https://staging.carwiz.co.il';
  }
  const isPreProd = apiUrl.indexOf('api.preprod') > -1;
  if (isPreProd) {
    return 'https://preprod.carwiz.co.il';
  }

  const isProd =
    apiUrl.indexOf('api.carwiz') > -1 || apiUrl.indexOf('api.date') > -1;

  if (isProd) {
    return 'https://carwiz.co.il';
  }
  return 'http://localhost:3000';
}

export const API_URL = process.env.REACT_APP_API_URL;
export const CDN_PREFIX = process.env.REACT_APP_CDN_PREFIX;
export const UI_URL = getUIUrl(API_URL);
export const AUDIT_URL = process.env.REACT_APP_AUDIT_URL;
export const FILTERS_VALIDATE_URL = process.env.REACT_APP_FILTERS_VALIDATE_URL;
export const BQ_TO_PG_TOPIC = process.env.REACT_APP_BQ_TO_PG_TOPIC;
export const CAR_NAMES_MANAGEMENT_URL =
  process.env.REACT_APP_CAR_NAMES_MANAGEMENT_URL;

export const CAR_NAME_TABLE = process.env.REACT_APP_CAR_NAME_TABLE;
