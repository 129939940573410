import React, { useState } from 'react';
import {
  TextField,
  IconButton,
  InputAdornment,
  Snackbar,
  Tooltip
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';

const CopyLinkInput = ({ link }) => {
  const [openSnackbar, setOpenSnackbar] = useState(false);

  const handleCopyClick = () => {
    navigator.clipboard.writeText(link).then(() => {
      setOpenSnackbar(true);
    });
  };

  const handleSnackbarClose = () => {
    setOpenSnackbar(false);
  };

  return (
    <div>
      <TextField
        label="URL מאוחד"
        value={link}
        disabled
        fullWidth
        variant="outlined"
        InputProps={{
          style: { direction: 'ltr' },
          endAdornment: (
            <InputAdornment position="end">
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleCopyClick}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>
            </InputAdornment>
          )
        }}
        style={{ marginBottom: 16 }}
      />
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        open={openSnackbar}
        autoHideDuration={3000}
        onClose={handleSnackbarClose}
        message="URL הועתק בהצלחה"
      />
    </div>
  );
};

export default CopyLinkInput;
