import labels from '../../labels';
import Manage from './Manage';

export default {
  name: 'UICache',
  list: Manage,

  options: {
    label: labels.uiCache,
    group: 'parameters'
  }
};
