import axios from 'axios';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL, FILTERS_VALIDATE_URL } from '../../constants/constants';

const getGqlsForContent = (data) => {
  const isHero = data.sourceTable.includes('Hero');
  const isCarrousel = data.sourceTable.includes('Carrousel');
  const isBanner = data.sourceTable.includes('Banner');
  const isTag = data.sourceTable.includes('Tag');
  const isFilter = data.sourceTable.includes('Filter');

  const header =
    isHero || isCarrousel
      ? `header: ${data.header ? `"${data.header}"` : null}
        headerColor: ${data.headerColor ? `"${data.headerColor}"` : null}
        headerSize: ${data.headerSize ? `"${data.headerSize}"` : null}
        headerWeight: ${data.headerWeight ? `"${data.headerWeight}"` : null}`
      : ``;
  const subHeader = isHero
    ? `subHeader: ${data.subHeader ? `"${data.subHeader}"` : null}
        subHeaderColor: ${
          data.subHeaderColor ? `"${data.subHeaderColor}"` : null
        }
        subHeaderWeight: ${
          data.subHeaderWeight ? `"${data.subHeaderWeight}"` : null
        }
        subHeaderSize: ${data.subHeaderSize ? `"${data.subHeaderSize}"` : null}`
    : ``;
  const desktopImage = !isCarrousel
    ? `desktopImageUrl: ${
        data.desktopImageUrl ? `"${data.desktopImageUrl}"` : null
      }`
    : ``;
  const mobileImage =
    isHero || isBanner
      ? `mobileImageUrl: ${
          data.mobileImageUrl ? `"${data.mobileImageUrl}"` : null
        }`
      : ``;
  const branches =
    isTag || isFilter || isBanner
      ? `branches: ${data.branches ? `[${data.branches}]` : null}`
      : ``;
  const timer = isBanner ? `timer: ${data.timer || false}` : ``;
  const url =
    isTag || isFilter || isBanner
      ? `url: ${data.url ? `"${data.url}"` : null}`
      : ``;
  const iconUrl = isFilter
    ? `iconUrl: ${data.iconUrl ? `"${data.iconUrl}"` : null}`
    : ``;
  const color = isFilter
    ? `color: ${data.color ? `"${data.color}"` : null}`
    : ``;
  const text =
    isFilter || isTag ? `text: ${data.text ? `"${data.text}"` : null}` : ``;

  return {
    header,
    subHeader,
    branches,
    timer,
    url,
    color,
    iconUrl,
    text,
    desktopImage,
    mobileImage
  };
};

export const validateLink = async (data) => {
  try {
    const response = await axios.post(FILTERS_VALIDATE_URL, data, {
      timeout: 15000,
      headers: {
        'Content-Type': 'application/json'
      }
    });

    return response.data;
  } catch (error) {
    console.error('validateLink Error:', error);
    throw error; // rethrow the error so it can be handled by the caller
  }
};

export const createDynamicContentAd = async (data, token) => {
  if (!token || !data || !data.adNum) return;

  const now = new Date().toISOString();
  const q = `mutation {
  createDynamicContentAd(
    input: {
      dynamicContentAd: {
        adNum: ${data.adNum}
        startDate: ${data.startDate ? `"${data.startDate}"` : null}
        endDate: ${data.endDate ? `"${data.endDate}"` : null}
        status: ${data.status ? `"${data.status}"` : null}
        campaignName: ${data.campaignName ? `"${data.campaignName}"` : null}
        clickable: ${data.clickable || false}
        legalStatus: ${data.legalStatus || false}
        desktopImageUrl: ${
          data.desktopImageUrl ? `"${data.desktopImageUrl}"` : null
        }
        text:  ${data.text ? `"${data.text}"` : null}
        textColor: ${data.textColor ? `"${data.textColor}"` : null}
        textSize: ${data.textSize ? `"${data.textSize}"` : null}
        textWeight: ${data.textWeight ? `"${data.textWeight}"` : null}
        buttonBgColor: ${data.buttonBgColor ? `"${data.buttonBgColor}"` : null}
        buttonText: ${data.buttonText ? `"${data.buttonText}"` : null}
        buttonTextColor: ${
          data.buttonTextColor ? `"${data.buttonTextColor}"` : null
        }
        buttonTextSize: ${
          data.buttonTextSize ? `"${data.buttonTextSize}"` : null
        }
        buttonTextWeight: ${
          data.buttonTextWeight ? `"${data.buttonTextWeight}"` : null
        }
        url: ${data.url ? `"${data.url}"` : null}
        legalColor: ${data.legalColor ? `"${data.legalColor}"` : null}
        creatingUid: ${data.creatingUid ? `"${data.creatingUid}"` : null}
        deletedAt: null
        createdAt: "${now}"
        updatedAt: "${now}"
      }
    }
  ) {
    clientMutationId
  }
}`;

  await graphqlServiceRequest(token, q, API_URL);
};

export const createBulkDynamicContent = async (selected, data, token) => {
  if (!token || !data || !selected || !selected.length) return;

  const now = new Date().toISOString();

  await Promise.all(
    selected.map(async (sourceTable) => {
      const q = `mutation {
      createDynamicContent${sourceTable}(
        input: {
          dynamicContent${sourceTable}: {
            status: ${data.status ? `"${data.status}"` : null}
            startDate: ${data.startDate ? `"${data.startDate}"` : null}
            endDate: ${data.endDate ? `"${data.endDate}"` : null}
            creatingUid: ${data.creatingUid ? `"${data.creatingUid}"` : null}
            createdAt: "${now}"
            updatedAt: "${now}"
            deletedAt: null
          }
        }
      ){
        clientMutationId
      }
    }`;

      await graphqlServiceRequest(token, q, API_URL);
    })
  );
};

export const createDynamicContent = async (data, token) => {
  if (!token || !data || !data.sourceTable) return;

  const now = new Date().toISOString();

  const {
    header,
    subHeader,
    branches,
    timer,
    url,
    color,
    iconUrl,
    text,
    desktopImage,
    mobileImage
  } = getGqlsForContent(data);

  const q = `mutation {
  createDynamicContent${data.sourceTable}(
    input: {
      dynamicContent${data.sourceTable}: {
        campaignName: ${data.campaignName ? `"${data.campaignName}"` : null}
        status: ${data.status ? `"${data.status}"` : null}
        startDate: ${data.startDate ? `"${data.startDate}"` : null}
        endDate: ${data.endDate ? `"${data.endDate}"` : null}
        location: ${data.location ? `"${data.location}"` : null}
        ${desktopImage}
        ${mobileImage}
        ${header}
        ${subHeader}
        ${branches}
        ${timer}
        ${url}
        ${color}
        ${iconUrl}
        ${text}
        creatingUid: ${data.creatingUid ? `"${data.creatingUid}"` : null}
        createdAt: "${now}"
        updatedAt: "${now}"
        deletedAt: null
       
      }
    }
  ){
    clientMutationId
  }
}`;

  await graphqlServiceRequest(token, q, API_URL);
};

export const updateDynamicContent = async (data, token) => {
  if (!token || !data || !data.sourceTable || !data.id) return;

  const now = new Date().toISOString();

  const {
    header,
    subHeader,
    branches,
    timer,
    url,
    color,
    iconUrl,
    text,
    desktopImage,
    mobileImage
  } = getGqlsForContent(data);

  const q = `mutation {
  updateDynamicContent${data.sourceTable}ById(
    input: {
      patch: {
        campaignName: ${data.campaignName ? `"${data.campaignName}"` : null}
        status: ${data.status ? `"${data.status}"` : null}
        startDate: ${data.startDate ? `"${data.startDate}"` : null}
        endDate: ${data.endDate ? `"${data.endDate}"` : null}
        location: ${data.location ? `"${data.location}"` : null}
        ${desktopImage}
        ${mobileImage}
        ${header}
        ${subHeader}
        ${branches}
        ${timer}
        ${url}
        ${color}
        ${iconUrl}
        ${text}
        creatingUid:  ${data.creatingUid ? `"${data.creatingUid}"` : null}
        updatedAt: "${now}"
      }
      id: "${data.id}"
    }
  ) {
    clientMutationId
  }
}`;

  await graphqlServiceRequest(token, q, API_URL);
};
