import React, { useCallback, useState } from 'react';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Box, Button, IconButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  selectEmpty: {
    marginTop: theme.spacing(2)
  }
}));

const OrderBySection = ({ fieldsOptions, onSubmit }) => {
  const classes = useStyles();

  const [isArrowUp, setIsArrowUp] = useState(true);
  const [selectedField, setSelectedField] = useState('');

  const onDirectionChange = () => setIsArrowUp(!isArrowUp);
  const handleChange = (event) => {
    setSelectedField(event.target.value);
  };

  const onSubmitOrder = useCallback(
    () => onSubmit(selectedField, !isArrowUp),
    [selectedField, isArrowUp]
  );
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center'
      }}
    >
      <FormControl className={classes.formControl}>
        <InputLabel>מיין לפי</InputLabel>
        <Select
          value={selectedField}
          onChange={handleChange}
          style={{ width: '150px' }}
        >
          <MenuItem value="">ללא</MenuItem>
          {fieldsOptions.map((option) => (
            <MenuItem value={option.id}>{option.name}</MenuItem>
          ))}
        </Select>
      </FormControl>
      <IconButton onClick={onDirectionChange}>
        {isArrowUp ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
      </IconButton>
      <Button variant="contained" color="primary" onClick={onSubmitOrder}>
        החל
      </Button>
    </Box>
  );
};

export default OrderBySection;
