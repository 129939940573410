import React from 'react';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';
import messages from './messages';

const useStyles = makeStyles(() => ({
  root: {
    textAlign: 'center'
  },
  titleTypography: {
    fontSize: 32
  },
  subtitleTypography: {
    fontSize: 22
  }
}));

const LoginSuccess = ({ title, subtitle }) => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Typography variant="title" className={classes.titleTypography}>
        {title || messages.welcome1}
      </Typography>
      <Typography variant="subtitle1" className={classes.subtitleTypography}>
        {subtitle || messages.welcome2}
      </Typography>
      <CircularProgress size={32} />
    </div>
  );
};

export default LoginSuccess;
