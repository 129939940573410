import { FileStatus } from 'react-filepond';
import { map, prop, pipe, length, filter } from 'ramda';

export const isUploadPending = (files) =>
  pipe(
    filter(
      (file) =>
        prop('status', file) !== FileStatus.PROCESSING_COMPLETE &&
        prop('status', file) !== FileStatus.IDLE &&
        prop('status', file) !== FileStatus.LOAD_ERROR
    ),
    length
  )(files) > 0;

export const getFilesUrls = pipe(
  filter(prop('serverId')),
  map(prop('serverId'))
);
