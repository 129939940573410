import React from 'react';
import {
  BooleanInput,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  TextInput
} from 'react-admin';

import LogoInput from './LogoInput';

const agencyNameValidation = (value) => {
  const agencyReg = value.includes('/') || value.includes('\\');
  if (agencyReg) {
    return 'Agency name cannot contain any slashes';
  }

  return undefined;
};

const validateAgnecyName = [required(), agencyNameValidation];

const AgencyMutation = ({ initialValues, ...props }) => {
  const handleSubmit = (data, redirect) => {
    props.save(data, redirect); // save the object
  };

  return (
    <SimpleForm
      {...props}
      initialValues={{ ...initialValues }}
      save={handleSubmit} // Override the form submission behavior
    >
      {props.disableId ? (
        <TextInput disabled label="ID" source="originalId" />
      ) : (
        <TextInput label="ID" source="id" validate={required()} />
      )}
      <TextInput label="Name" source="name" validate={validateAgnecyName} />
      <TextInput
        label="Display Name"
        source="displayName"
        validate={validateAgnecyName}
      />
      <TextInput label="Website" source="website" />
      <LogoInput />
      <div style={{ display: 'none' }}>
        <TextInput label="Compressed Logo" source="compressedLogo" />
      </div>

      <SelectInput
        source="proPackage"
        label="Pro Package"
        choices={[
          { id: 'NONE', name: 'None' },
          { id: 'BASIC', name: 'Basic' },
          { id: 'PREMIUM1', name: 'Premium-1' }
        ]}
      />
      <TextInput multiline label="About" source="about" />
      <TextInput label="companyNumber" source="companyNumber" />
      <TextInput label="providerCode" source="providerCode" />
      <NumberInput label="fee" source="fee" />
      <TextInput
        label="warrantyDescriptionSlug"
        source="warrantyDescriptionSlug"
      />
      <BooleanInput label="שחרור נעילה" source="unlock" />
    </SimpleForm>
  );
};

export default AgencyMutation;
