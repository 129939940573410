import { filter, map, path, pipe, prop, reverse, sortBy } from 'ramda';
import { useEffect, useState } from 'react';
import { GET_LIST, useDataProvider, useVersion } from 'react-admin';

const showDisplayNameNoId = (record) =>
  `${path(['agency', 'displayName'], record)} ${path(['areaName'], record)} `;

const showDisplayName = (record) =>
  `${path(['agency', 'displayName'], record)} ${path(
    ['areaName'],
    record
  )} (${path(['originalId'], record)})`;

const useAgencyBranchesForAutoComplete = () => {
  const [agencyBranches, setAgencyBranches] = useState();
  const version = useVersion();
  const dataProvider = useDataProvider();

  const fetchAgencyBranches = async () => {
    const { data } = await dataProvider(GET_LIST, 'AgencyBranch', {
      filter: {
        specificFields: [
          'agency',
          'areaName',
          'city',
          'nodeId',
          'id',
          'openingHours',
          'psid',
          'sfid',
          'isCarlandBranch'
        ],
        expose: true
      }
    });

    const mappedBranches = pipe(
      map((branch) => ({
        id: prop('originalId', branch),
        name: showDisplayName(branch),
        nameNoId: showDisplayNameNoId(branch),
        agencyName: path(['agency', 'displayName'], branch),
        areaName: prop('areaName', branch),
        openingHours: prop('openingHours', branch),
        city: prop('city', branch),
        psid: prop('psid', branch),
        sfid: prop('sfid', branch),
        isCarlandBranch: prop('isCarlandBranch', branch)
      })),
      filter(prop('id')),
      reverse()
    )(data);

    setAgencyBranches([
      // {}, // this empty object causes for failure.
      // if the idea is to add empty branch, we can use allowEmpty property instead
      {
        id: 10000,
        name: 'CARWIZ',
        areaName: 'CARWIZ',
        openingHours: null,
        city: 'X',
        psid: 0,
        sfid: 0,
        isCarlandBranch: false
      },
      ...mappedBranches
    ]);
  };

  useEffect(() => {
    fetchAgencyBranches();
  }, [version]);

  return {
    agencyBranches
  };
};

export default useAgencyBranchesForAutoComplete;
