import { Dialog, DialogContent, makeStyles } from '@material-ui/core';
import React from 'react';
import WorkingHours from '../../bitComponents/working-hours/WorkingHours';

const useStyles = makeStyles(() => ({
  openingHours: {
    width: '30%'
  },
  dialog: {
    '& > form > div:first-child': {
      display: 'none'
    },
    '& > form > div > div > div:first-child': {
      visibility: 'hidden'
    }
  }
}));

const HoursEditDialog = ({ value, open, onSubmit, onClose }) => {
  const classes = useStyles();
  return (
    <Dialog open={open} maxWidth="md" fullWidth onClose={() => onClose(false)}>
      <DialogContent>
        <div className={classes.dialog}>
          <WorkingHours days={value} onSubmit={onSubmit} />
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default HoursEditDialog;
