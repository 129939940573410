import React from 'react';
import {
  List,
  Datagrid,
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  EditButton,
  Filter,
  Create,
  required,
  Toolbar
} from 'react-admin';
import CreateVersionPageMappingButton from './CreateVersionPageMappingButton';

// const showDisplayName = (record) =>
//   `${path(['agency', 'displayName'], record)} ${path(
//     ['areaName'],
//     record
//   )} (${path(['originalId'], record)}`;

export const VersionPageMappingsList = (props) => (
  <List
    {...props}
    filters={<VersionPageMappingsFilter />}
    sort={{ field: 'columnName', order: 'ASC' }}
    bulkActionButtons={false}
    perPage={25}
  >
    <Datagrid>
      <TextField source="category" />
      <TextField source="columnName" />
      <TextField source="label" />
      <EditButton />
    </Datagrid>
  </List>
);

const VersionPageMappingsFilter = (props) => (
  <Filter {...props}>
    <TextField source="category" />
    <TextField source="columnName" />
    <TextField source="label" />
  </Filter>
);

export const VersionPageMappingsEdit = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm>
        <TextInput source="category" validate={required()} />
        <TextInput source="columnName" validate={required()} />
        <TextInput source="label" validate={required()} />
      </SimpleForm>
    </Edit>
  );
};

export const VersionPageMappingCreate = (props) => (
  <Create {...props}>
    <SimpleForm
      toolbar={
        <Toolbar>
          <CreateVersionPageMappingButton />
        </Toolbar>
      }
    >
      <TextInput source="category" validate={required()} />
      <TextInput source="columnName" validate={required()} />
      <TextInput source="label" validate={required()} />
    </SimpleForm>
  </Create>
);
