import CarNamesIcon from '@material-ui/icons/TimeToLeave';
import labels from '../../labels';
import Manage from './Manage';

export default {
  name: 'CarNameManagement',
  list: Manage,
  icon: CarNamesIcon,

  options: {
    label: labels.carNameManagement
  }
};
