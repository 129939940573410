import React from 'react';
import { Resource } from 'react-admin';
import BallotIcon from '@material-ui/icons/Ballot';
import SalesForceIdsTable from './SalesForceIdsTable';

const SalesForceIdentifiersResource = (
  <Resource
    name="SalesforceIndentifiersView"
    icon={BallotIcon}
    list={() => <SalesForceIdsTable />}
    options={{
      label: 'טיוב חשבונות Salesforce'
    }}
  />
);

export default SalesForceIdentifiersResource;
