import { Typography } from '@material-ui/core';
import { map } from 'ramda';
import React from 'react';
import { BooleanInput, DateInput, NumberInput, Query, SimpleForm, TextInput, useTranslate } from 'react-admin';

import ImageSectionV2 from '../../components/ImageSectionV2';
import PDFViewer from '../../components/PDFViewer';

const commitmentToCheckOpts = {
  NO_CHECK: 'ללא התחייבות לבדיקה',
  CHASSIS: 'התחייבות לבדיקת שילדה',
  GEAR_ENGINE_CHASSIS: 'התחייבות לבדיקת גיר, מנוע ושילדה'
};

const UploadAttemptsMutation = ({ initialValues, ...props }) => {
  const translate = useTranslate();

  return (
    <SimpleForm
      {...props}
      initialValues={initialValues}
      disabled
      toolbar={
        <div>
          {/* <SaveButton />  for now we will disable the save and delete buttons */}
        </div>
      }
    >
      <TextInput disabled label={translate('ra.general.id')} source="uid" />
      <TextInput
        disabled
        source="createdBy"
        label={translate('ra.resources.upload_attempts.created_by')}
      />
      <DateInput
        disabled
        source="createdAt"
        locales="he-IL"
        label={translate('ra.resources.upload_attempts.created_at')}
      />
      <TextInput
        disabled
        source="plateNumber"
        label={translate('ra.resources.upload_attempts.plate_number')}
      />
      <TextInput
        disabled
        source="makeName"
        label={translate('ra.resources.upload_attempts.make_name')}
      />
      <TextInput
        disabled
        source="modelName"
        label={translate('ra.resources.upload_attempts.model_name')}
      />
      <NumberInput
        source="year"
        label={translate('ra.general.year')}
        disabled
        min={2000}
        max={new Date().getFullYear()}
      />
      <TextInput
        disabled
        source="finishLevel"
        label={translate('ra.resources.upload_attempts.finish_level')}
      />
      <TextInput
        source="parallelImportModel"
        label={translate('ra.resources.upload_attempts.parallel_import_model')}
        disabled
      />
      <TextInput
        source="color"
        label={translate('ra.resources.upload_attempts.color')}
        disabled
      />
      <NumberInput
        source="price"
        label={translate('ra.resources.upload_attempts.price')}
        disabled
        min={100000}
      />
      <NumberInput
        source="hand"
        label={translate('ra.resources.upload_attempts.hand')}
        disabled
        min={0}
      />
      <NumberInput
        source="kilometrage"
        label={translate('ra.resources.upload_attempts.kilometrage')}
        disabled
        min={0}
      />
      <TextInput
        source="originalOwner"
        label={translate('ra.resources.upload_attempts.original_owner')}
        disabled
      />
      <NumberInput
        source="supplyDays"
        label={translate('ra.resources.upload_attempts.supply_days')}
        disabled
        min={0}
      />
      <TextInput
        source="commitmentToCheck"
        label={translate('ra.resources.upload_attempts.commitment_to_check')}
        disabled
        format={(val) => commitmentToCheckOpts[val]}
      />
      <TextInput
        source="warranty"
        label={translate('ra.resources.upload_attempts.warranty')}
        disabled
      />
      <NumberInput
        source="warrantyMonths"
        label={translate('ra.resources.upload_attempts.warranty_months')}
        disabled
        min={0}
      />
      <TextInput
        source="error"
        label={translate('ra.resources.upload_attempts.error')}
        disabled
      />
      <TextInput
        disabled
        source="userRemark"
        label={translate('ra.resources.upload_attempts.remark')}
      />
      <BooleanInput
        disabled
        source="parallelImport"
        label={translate('ra.resources.upload_attempts.parallel_import')}
      />
      <BooleanInput
        disabled
        source="isAllowedTrading"
        label={translate('ra.resources.upload_attempts.is_allowed_tradein')}
      />
      <BooleanInput
        disabled
        source="futureTradein"
        label={translate('ra.resources.upload_attempts.future_tradein')}
      />
      <Query
        type="GET_LIST"
        resource="UploadAttemptsView"
        payload={{
          filter: {
            uid: props.record.uid
          },
          specificFields: ['images', 'videos', 'license']
        }}
      >
        {({ data, loading, error }) => {
          if (!data) return null;

          const { images, videos, license } = data[0];
          const imagesArray = map((img) => {
            return { originalUrl: img };
          }, images);

          const videosArray = map((video) => {
            return { originalUrl: video };
          }, videos);

          const isPDFLicense = license && license.trim().endsWith('pdf');
          const isImageLicense =
            license &&
            ['png', 'jpg', 'jpeg'].some((ext) => license.trim().endsWith(ext));

          // const pdf =
          //   'https://storage.googleapis.com/carwiz_staging/users/c2827138-26d0-468b-9e5b-93ac577cb471.pdf';

          return (
            <div>
              <Typography style={{ textDecoration: 'underline' }}>
                {translate('ra.resources.upload_attempts.images')}
              </Typography>
              <ImageSectionV2
                loading={loading}
                error={error}
                noImageLabel={translate(
                  'ra.resources.upload_attempts.no_image'
                )}
                images={imagesArray || []}
                videos={videosArray || []}
              />

              <br />

              <Typography style={{ textDecoration: 'underline' }}>
                {translate('ra.resources.upload_attempts.license')}
              </Typography>
              {!license && (
                <Typography>
                  {translate('ra.resources.upload_attempts.no_license')}
                </Typography>
              )}
              {/* <PDFViewer
                pdfFile={pdf}
                buttonText={translate('ra.resources.upload_attempts.license')}
              /> */}
              {isPDFLicense && (
                <PDFViewer
                  pdfFile={license}
                  buttonText={translate('ra.resources.upload_attempts.license')}
                />
              )}
              {isImageLicense && (
                <ImageSectionV2
                  loading={loading}
                  error={error}
                  noImageLabel={translate(
                    'ra.resources.upload_attempts.no_image'
                  )}
                  images={[{ originalUrl: license }]}
                />
              )}
            </div>
          );
        }}
      </Query>
    </SimpleForm>
  );
};

export default UploadAttemptsMutation;
