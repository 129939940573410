import React, { useState } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { path } from 'ramda';
import { useNotify, useTranslate, useRefresh, Confirm } from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';
import { API_URL } from '../../constants/constants';
import { buildUpdateUserTeamId } from './queries';

const UserDeleteConfirmDialog = ({ ...props }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();
  const { token } = useFirebaseToken();
  const handleClick = () => setOpen(true);
  const handleDialogClose = () => setOpen(false);
  const name = path(['record', 'name'], props);

  const remove = async () => {
    const uid = path(['record', 'uid'], props);

    if (!uid) return;

    setLoading(true);

    try {
      const user = (props.users || []).find((u) => u.node.uid === uid);
      const result = await graphqlServiceRequest(
        token,
        buildUpdateUserTeamId(user.node, -1),
        API_URL
      );

      const nodeId = path(['data', 'updateUser', 'user', 'nodeId'], result);

      if (nodeId) {
        if (props.callback) {
          const fn = props.callback;
          fn(uid);
        }

        notify(translate('ra.resources.teams.edit_success'), 'success');
        refresh();
      }

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      notify(translate('ra.page.error'), 'error');
      setLoading(false);
    }
  };

  const handleConfirm = async () => {
    await remove();
    setOpen(false);
  };

  return (
    <>
      <IconButton aria-label="delete" color="primary" onClick={handleClick}>
        <DeleteIcon />
      </IconButton>
      <Confirm
        isOpen={open}
        loading={loading}
        title={`?האם להוציא את ${name}`}
        content="שים לב! פעולה זו תשמור את המידע במיידי"
        onConfirm={handleConfirm}
        onClose={handleDialogClose}
      />
    </>
  );
};

export default UserDeleteConfirmDialog;
