import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import { map, path } from 'ramda';
import React, { useState } from 'react';
import {
  useNotify,
  useRecordContext,
  useRefresh,
  useTranslate
} from 'react-admin';
import useFirebaseToken from '../../bitComponents/utils/hooks/useFirebaseToken';
import { graphqlServiceRequest } from '../../bitComponents/utils/requests';

import { API_URL } from '../../constants/constants';
import { buildDeleteTeamQuery } from './queries';

const TeamsDeleteButton = () => {
  const record = useRecordContext();
  const [loading, setLoading] = useState(false);
  const { token } = useFirebaseToken();
  const notify = useNotify();
  const translate = useTranslate();
  const refresh = useRefresh();

  const mapError = (errors) => map((el) => el.message, errors).join('\n');

  const remove = async () => {
    if (!token || !record || !record.teamId) return;

    setLoading(true);

    try {
      const result = await graphqlServiceRequest(
        token,
        buildDeleteTeamQuery(record.teamId),
        API_URL
      );

      const nodeId = path(['data', 'deleteTeamById', 'team', 'nodeId'], result);
      const errors = path(['errors'], result);

      if (nodeId) {
        notify(translate('ra.resources.teams.delete_success'), 'success');
        refresh();
      }

      if (errors) {
        const errMessage = mapError(errors);

        if (
          errMessage.includes(
            'violates foreign key constraint "users_team_id_fkey"'
          )
        ) {
          notify(
            translate('ra.resources.teams.cant_delete_when_users_exists'),
            'error'
          );
        } else {
          notify(errMessage, 'error');
        }
      }

      setLoading(false);
    } catch (err) {
      console.log(err.message);
      notify(translate('ra.page.error'), 'error');
      setLoading(false);
    }
  };

  return (
    <IconButton aria-label="delete" color="primary" onClick={remove}>
      <DeleteIcon disabled={loading} style={{ color: 'red' }} />
    </IconButton>
  );
};

export default TeamsDeleteButton;
